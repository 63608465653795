import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import SignOutButton from '../SignOut';

import * as ROUTES from '../../constants/routes';
import UserImageCrop from '../ImageCrop/userImage';
import './banner-inner.scss';

function InnerBanner({ user, className, listing }) {
  const location = useLocation();
  return (
    <>
      {/* Inner Banner -----------------------------------------------------------*/}
      {user && (
        <>
          {ROUTES.ADDLISTING !== location.pathname && (
            <div className="banner-inner dashboard step-process">
              <div className="container">
                <div className="row">
                  <div className="col-sm-12">
                    <UserImageCrop user={user} />
                    <div className="dash-top-wrap">
                      <h1>My dashboard</h1>
                      <p>Logged in as {user.username}</p>
                    </div>
                    <SignOutButton classes="btn btn-white" />
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
      {ROUTES.EDITLISTING === location.pathname && (
        <div className={`banner-inner ${className}`}>
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-lg-8 offset-lg-2 text-center">
                <h1>Edit a listing</h1>
                <p>Editing {listing.postTitle}</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default InnerBanner;
