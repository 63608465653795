/**
 * Split Full name to First and Last name.
 * splitName('Vagelis Papaioannou', 0) will return Vagelis.
 * you can read more about .trim() here:
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/Trim
 */
export function splitName(fullname, part) {
  let thename = fullname.trim().split(' ');
  return thename[part];
}

/**
 * Get current position from browser
 * you can read more about .getCurrentPosition() here:
 * https://developer.mozilla.org/en-US/docs/Web/API/Geolocation/getCurrentPosition
 * TODO: use this instead of an arrow function
 */
export function userCurrentPotision() {
	navigator.geolocation.getCurrentPosition(function (position) {
		let lat = position.coords.latitude;
		let lng = position.coords.longitude;
		return [ lat, lng ];
    });
}