import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import './forgot-pw.scss';

const PasswordForgetPage = () => (
  <div className="fw sect-pad-top sect-pad-bot forgotPassword">
  <div className="container">
  <div className="row">
  <div className="col-sm-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3 text-center">
    <h2>Forgotten your Togodo password?</h2>
    <p>Add the email address associated with your account below and we'll send you details on how to reset your password.</p>
    <PasswordForgetForm />
  </div>
  </div>
  </div>
  </div>
);

const INITIAL_STATE = {
  email: '',
  error: null,
};

class PasswordForgetFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { email } = this.state;

    this.props.firebase
      .doPasswordReset(email)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, error } = this.state;

    const isInvalid = email === '';

    return (
      <form onSubmit={this.onSubmit}>
        <input
          name="email"
          value={this.state.email}
          onChange={this.onChange}
          type="text"
          placeholder="Email Address"
          className="form-control"
        />
        <button disabled={isInvalid} type="submit" className="btn">
          Reset my password
        </button>

        {error && <p>{error.message}</p>}
      </form>
    );
  }
}

const PasswordForgetLink = () => (
  <p>
    <a href={ROUTES.PASSWORD_FORGET}>Forgot Password?</a>
  </p>
);

export default PasswordForgetPage;

const PasswordForgetForm = withFirebase(PasswordForgetFormBase);

export { PasswordForgetForm, PasswordForgetLink };
