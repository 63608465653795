import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import queryString from 'query-string';
import { Link } from 'react-router-dom';
import firebase from 'firebase';
import 'firebase/firestore';
import * as geofirestore from 'geofirestore';
import { formatDate } from '../../helpers/ui';
import { AuthUserContext } from '../Session';
import { withFirebase } from '../Firebase';
import ClaimListingSignup from '../SignUp/claimListingSignup';

import images from '../../constants/images';
import * as ROUTES from '../../constants/routes';

import './banner-inner.scss';

class ClaimPage extends Component {
  //   static contextType = AuthUserContext;
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      listing: null,
    };
  }
  componentDidMount() {
    const listingToBeClaimed = queryString.parse(
      this.props.location.search,
	);

	  this.onGetListing(listingToBeClaimed.claim);
	  localStorage.setItem('listingToBeClaimed', listingToBeClaimed.claim);
    // if (localStorage.getItem('newUser')) {
    //   const newUser = localStorage.getItem('newUser');
    //   localStorage.setItem('newUser', '');
    //   this.onClaimListing(listingToBeClaimed.claim, newUser);
    // }
  }

  visitorRegistered = (status) => {
    alert(status);
  };

  onClaimListing = (listing, user) => {
    const firestore = firebase.firestore();
    const GeoFirestore = geofirestore.initializeApp(firestore);
    const geocollection =
      GeoFirestore.collection('listings').doc(listing);

    geocollection.update({
      owner: newUser,
    });
  };

  onGetListing = (listingToBeClaimed) => {
    this.setState({
      loading: true,
      listing: null,
    });
    this.unsubscribe = this.props.firebase
      .listings()
      .doc(listingToBeClaimed)
      .get()
      .then((doc) => {
        this.setState({
          listing: doc.data(),
          loading: false,
          listingToBeClaimedID: listingToBeClaimed,
        });
        console.log(doc.data());
      });
  };

  render = () => {
    const { listing, listingToBeClaimedID } = this.state;
    return (
      <>
        <div className="page-template-dashboard ClaimPage">
          <div className="fw banner-inner">
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  {listing && <h1>Claim {listing.postTitle}</h1>}
                  {!listing && <h1>Looking to claim a listing ?</h1>}
                </div>
              </div>
            </div>
          </div>
          <AuthUserContext.Consumer>
            {(authUser) =>
              authUser && listing ? (
                <>
                  <div className="fw white-bg sect-pad-top sect-pad-bot">
                    <div className="container">
                      <div className="row">
                        <div className="col-sm-12">
                          The user is allowed to claim the listing
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-12 col-md-4 eblock-wrap">
                    <Link
                      to={`/${listing.postType}/?listing=${listingToBeClaimedID}`}
                      target="_blank"
                      className="eblock"
                    >
                      <div className="eblock-img">
                        {/* <div className="featured-tag">Featured</div> */}
                        {/* <Distance
                              listing={listing}
                              type="blockListing"
                            /> */}
                        {listing.postThumbnails[0] && (
                          <img
                            src={listing.postThumbnails[0]}
                            className="resp-img"
                          />
                        )}
                        {/* <img
                                    src={listing.postThumbnails[0]}
                                    className="resp-img"
                                  /> */}
                      </div>
                      <div className="eblock-dets">
                        <ul>
                          <li className="date">
                            {formatDate(listing.postStartDate).slice(
                              0,
                              -5,
                            )}
                          </li>
                          {/* {listing.postTime && ( */}
                          <li className="time">14:00 - 15:00</li>
                          {/* )} */}
                        </ul>
                        <h3>{listing.postTitle}</h3>
                        <p>
                          {listing.postAddressTownCity}
                          {`, ${listing.postAddressCounty}`}
                        </p>
                      </div>
                    </Link>
                  </div>
                </>
              ) : (
                <>
                  <div className="fw white-bg sect-pad-top sect-pad-bot">
                    <div className="container">
                      <div className="row">
                        <div className="col-sm-12">
                          Register an account to continue
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                  >
                      <ClaimListingSignup/>
                  </div>
                </>
              )
            }
          </AuthUserContext.Consumer>
        </div>
      </>
    );
  };
}

export default withFirebase(ClaimPage);
