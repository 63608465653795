import React, { Component, PropTypes } from 'react';
import { Link, withRouter } from 'react-router-dom';
import * as geofirestore from 'geofirestore';
import { withFirebase } from '../Firebase';
import queryString from 'query-string';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';
import { NotifyUser } from '../UI/theNotification';
import './signup.scss';

class SignUpPage extends Component {
  constructor(props) {
    super(props);
  }
  // const SignUpPage = () => (
  render() {
    return (
      <div className="userRegister">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <h2>Sign up to continue</h2>
            </div>
            <SignUpForm />
          </div>
        </div>
      </div>
    );
  }
}

const INITIAL_STATE = {
  username: '',
  email: '',
  passwordOne: '',
  passwordTwo: '',
  isAdmin: false,
  error: null,
  registered: false,
};

const ERROR_CODE_ACCOUNT_EXISTS = 'auth/email-already-in-use';

const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with this email address already exists. Please login with this account above. If you have forgotten your password use the 'Forgotten password?' link below the login form`;

const ERROR_CODE_INVALID_PASSWORD = `auth/wrong-password`;
const ERROR_MSG_INVALID_PASSWORD = `The password is invalid or the user doesn't have an account.`;

class SignUpFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  componentDidMount() {}

	claim(userid) {
	// Transfer listing ownership to user
	const searchTerms = queryString.parse(this.props.location.search);
    this.props.firebase.listings().doc(searchTerms.listing).update({
      owner: userid,
    });
  }

  onSubmit = (event) => {
    const { username, email, passwordOne, isAdmin } = this.state;
    const roles = {};

    if (isAdmin) {
      roles[ROLES.ADMIN] = ROLES.ADMIN;
    }
    this.props.firebase
      .doCreateUserWithEmailAndPassword(email, passwordOne)
      .then((authUser) => {
        // Create a user in your Firebase realtime database
        this.setState({
          ...INITIAL_STATE,
          newUser: authUser.user.uid,
		});
		  
		// Claim listing ownership to user
		this.claim(authUser.user.uid, this.props.claimID);

        return this.props.firebase.user(authUser.user.uid).set(
          {
            username,
            email,
            roles,
          },
          { merge: true },
        );
      })
      .then(() => {
        return this.props.firebase.doSendEmailVerification();
      })
      .then(() => {
        this.props.history.push(`${ROUTES.DASHBOARD}`);
        // location.reload();
      })
      .then((authUser) => {
        console.log(authUser);
      })
      .catch((error) => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }
        if (error.code === ERROR_CODE_INVALID_PASSWORD) {
          error.message = ERROR_MSG_INVALID_PASSWORD;
        }

        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onChangeCheckbox = (event) => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  render() {
    const {
      username,
      email,
      passwordOne,
      passwordTwo,
      isAdmin,
      error,
      registered,
    } = this.state;

    const isInvalid =
      passwordOne !== passwordTwo ||
      passwordOne === '' ||
      email === '' ||
      username === '';

    return (
      <form onSubmit={this.onSubmit} className="loginForm col-sm-12">
        <div className="row">
          <div className="col-sm-12 col-md-6">
            <input
              name="username"
              value={username}
              onChange={this.onChange}
              type="text"
              placeholder="Full Name"
              className="form-control"
            />
          </div>
          <div className="col-sm-12 col-md-6">
            <input
              name="email"
              value={email}
              onChange={this.onChange}
              type="text"
              placeholder="Email Address"
              className="form-control"
            />
          </div>
          <div className="col-sm-12 col-md-6">
            <input
              name="passwordOne"
              value={passwordOne}
              onChange={this.onChange}
              type="password"
              placeholder="Password"
              className="form-control"
            />
          </div>
          <div className="col-sm-12 col-md-6">
            <input
              name="passwordTwo"
              value={passwordTwo}
              onChange={this.onChange}
              type="password"
              placeholder="Confirm Password"
              className="form-control"
            />
          </div>
          {/* <label>
          Admin:
          <input
            name="isAdmin"
            type="checkbox"
            checked={isAdmin}
            onChange={this.onChangeCheckbox}
          />
        </label> */}
          <div className="col-sm-12">
            <button
              disabled={isInvalid}
              type="submit"
              className="btn"
            >
              Sign Up
            </button>
          </div>
        </div>

        {error && <p>{error.message}</p>}
        {registered && (
          <NotifyUser
            title="Success"
            message="Almost ready, please verify your email. CLAIM"
            status="success"
          />
        )}
      </form>
    );
  }
}

const SignUpLink = () => (
  <p>
    Don't have an account? <Link to={ROUTES.SIGN_UP}>Sign Up</Link>
  </p>
);

const SignUpForm = withRouter(withFirebase(SignUpFormBase));

export default SignUpPage;

export { SignUpForm, SignUpLink };
