import React, { PureComponent } from 'react';
import { withFirebase } from '../Firebase';
import ReactCrop from 'react-image-crop';
import md5 from 'md5';

import firebase from 'firebase';
import 'firebase/firestore';

import 'react-image-crop/dist/ReactCrop.css';

class ImageCrop extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      src: null,
		imageURL: null,
      crop: {
        unit: 'px',
        width: 550,
        aspect: 6.8 / 4,
      },
    };
  }

  onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        this.setState({ src: reader.result }),
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  // If you setState the crop in here you should return false.
  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        'newFile.jpeg',
      );
      this.setState({ croppedImageUrl });
    }
  }

  uploadToStorage = () => {
    let randomName = md5(+new Date() + Math.random()).substr(2, 12);
    this.setState({ imageName: randomName });
    let blob = this.state.imageBlob;
    console.log('uploading to firebase');
    return new Promise((resolve, reject) => {
      let uploadTask = firebase.storage().ref();
      uploadTask
        .child(`listings/${this.props.currentUser}/${randomName}.jpg`)
        .put(blob, {
          contentType: 'image/jpeg',
        })
        .then((snapshot) => {
          //   resolve(snapshot);
          console.log(snapshot);
          console.log('upload completed');

          firebase
            .storage()
            .ref()
            .child(
              `listings/${this.props.currentUser}/${randomName}.jpg`,
            )
            .getDownloadURL()
            .then((url) => {
				this.setState({ imageURL: url });
				this.setState({ deleteSRC: false });
              this.props.functionCallFromParent(
                url,
                this.props.number,
              );
              this.setState({ src: null });
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

	deleteFromStorage = () => {
	this.setState({ deleteSRC: 'delete' });
    return new Promise((resolve, reject) => {
      let uploadTask = firebase.storage().ref();
      uploadTask
        .child(
          `listings//${this.props.currentUser}/${this.state.imageName}.jpg`,
        )
        .delete()
        .then(() => {
          // File deleted successfully
          this.props.functionCallFromParent(
            null,
            this.props.number,
            'delete',
          );
          this.setState({ imageURL: '' });
          this.setState({ src: '' });
          console.log('file removed');
          //   this.props.functionCallFromParent(null);
        })
        .catch(function (error) {
          console.log(error);
        });
	});
	  this.props.functionCallFromParent(
      null,
      this.props.number,
      'delete',
    );

  };

	deleteFromStorageByURL = () => {
		this.setState({ deleteSRC: 'delete' });
	// 	this.props.firebase.listing(this.props.currentListing).update({
    //   editedAt: this.props.firebase.fieldValue.serverTimestamp(),
    // });
		return new Promise((resolve, reject) => {
			// Create a reference to the file to delete
			var desertRef = firebase
				.storage()
				.refFromURL(this.props.listingImageURL);
			// Delete the file
			desertRef
				.delete()
				.then(function () {
					this.setState({ imageURL: ''});
					this.setState({ src: '' });
					this.props.firebase.listing(this.props.currentListing).update({
						editedAt: this.props.firebase.fieldValue.serverTimestamp(),
					});

				})
				.catch(function (error) {
					// Uh-oh, an error occurred!
				});
			this.props.functionCallFromParent(
        null,
        this.props.number,
        'delete',
      );
		});

		// return new Promise((resolve, reject) => {
		// 	let uploadTask = firebase.storage().ref();
		// 	uploadTask
		// 		.child(
		// 			`listings//${this.props.currentUser}/${this.state.imageName}.jpg`,
		// 		)
		// 		.delete()
		// 		.then(() => {
		// 			// File deleted successfully
		// 			this.props.functionCallFromParent(
		// 				null,
		// 				this.props.number,
		// 				'delete',
		// 			);
		// 			this.setState({ imageURL: '' });
		// 			this.setState({ src: '' });
		// 			console.log('file removed');
		// 			//   this.props.functionCallFromParent(null);
		// 		})
		// 		.catch(function (error) {
		// 			console.log(error);
		// 		});

		// };

		this.props.functionCallFromParent(
      url,
      this.props.number,
      'delete',
    );
	}


  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          return;
        }
        this.setState({ imageBlob: blob });
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, 'image/jpeg');
    });
  }

  render() {
    const { crop, croppedImageUrl, src, imageURL, deleteSRC } =
      this.state;

    return (
      <div className={`Cropper n-${this.props.number}`}>
        <div>
          <input
            type="file"
            accept="image/*"
            onChange={this.onSelectFile}
          />
        </div>
        {src && (
          <ReactCrop
            src={src}
            crop={crop}
            ruleOfThirds
            onImageLoaded={this.onImageLoaded}
            onComplete={this.onCropComplete}
            onChange={this.onCropChange}
          />
        )}
        {croppedImageUrl && !imageURL && (
          <button onClick={this.uploadToStorage}>Crop Image</button>
        )}
        {!deleteSRC && croppedImageUrl && (
          <button
            className="deleteImage"
            onClick={this.deleteFromStorage}
          >
            Delete file
          </button>
        )}
        {!deleteSRC && imageURL && (
          <img
            id={this.props.number}
            alt="Crop"
            style={{ maxWidth: '100%' }}
            src={imageURL}
          />
        )}
        {!deleteSRC && this.props.listingImageURL && !imageURL && (
          <>
            <button
              className="deleteImage"
              onClick={this.deleteFromStorageByURL}
            >
              Delete file
            </button>
            <img
              id={this.props.number}
              alt="Crop"
              style={{ maxWidth: '100%' }}
              src={!deleteSRC ? this.props.listingImageURL : ''}
            />
          </>
        )}
      </div>
    );
  }
}

export default withFirebase(ImageCrop);
