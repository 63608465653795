import React from 'react';
import ContentLoader from 'react-content-loader';

const CardsLoader = (props) => (
  <ContentLoader
    // width={450}
    height={400}
    viewBox="0 0 450 400"
    backgroundColor="#f0f0f0"
    foregroundColor="#dedede"
		style={{ width: '100%', height: '100%' }}
    {...props}
  >
    <rect x="0" y="0" ry="10" width="100%" height="260" />
    <rect x="0" y="290" ry="7" width="90%" height="14" />
    <rect x="0" y="322" ry="4" width="60%" height="8" />
  </ContentLoader>
);
export default CardsLoader;
