import React, { PureComponent } from 'react';
import { withFirebase } from '../Firebase';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import md5 from 'md5';
import images from '../../constants/images';

import firebase from 'firebase';
import 'firebase/firestore';

import './crop.scss';
class UserImageCrop extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      src: null,
      crop: {
        unit: '%',
        width: 30,
        aspect: 1 / 1, //square
      },
    };
	  this.fileUploadRef = React.createRef();
	//   console.log(this.fileUploadRef);
  }

  componentDidMount() {
	  this.getProfilePicture();
  }

	getProfilePicture() {
	//TODO: update user record on the DB to store the image URL
    firebase
      .storage()
      .ref()
      .child(`users/${this.props.user.uid}/profile.jpg`)
      .getDownloadURL()
      .then((url) => {
        this.setState({ imageURL: url });
		  this.setState({ src: null });
		  	this.props.firebase.user(this.props.user.uid).update({
          profileImage: url,
        });
      })
      .catch((error) => {
		//   this.setState({ imageURL: `https://www.gravatar.com/avatar/${md5(this.props.user.email)}` });
		  this.setState({ imageURL: images.noprofileimage });
		//   	this.props.firebase.user(this.props.user.uid).update({
        //   profileImage: `https://www.gravatar.com/avatar/${md5(this.props.user.email)}`,
		  	this.props.firebase.user(this.props.user.uid).update({
          profileImage: images.noprofileimage
        });
	  });



  }
  onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        this.setState({ src: reader.result }),
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  // If you setState the crop in here you should return false.
  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        'newFile.jpeg',
      );
      this.setState({ croppedImageUrl });
    }
  }

	triggerCrop = () => {
		// alert();
    	this.fileUploadRef.current.click();
  }

  uploadToFirebase = () => {
    let blob = this.state.imageBlob;
    // console.log('uploading to firebase');
    return new Promise((resolve, reject) => {
      let uploadTask = firebase.storage().ref();
      uploadTask
        .child(`users/${this.props.user.uid}/profile.jpg`)
        .put(blob, {
          contentType: 'image/jpeg',
        })
        .then((snapshot) => {
          firebase
            .storage()
            .ref()
            .child(`users/${this.props.user.uid}/profile.jpg`)
            .getDownloadURL()
            .then((url) => {
              this.setState({ imageURL: url });
              //   this.props.functionCallFromParent(url);
              this.setState({ src: null });
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          return;
        }
        this.setState({ imageBlob: blob });
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, 'image/jpeg');
    });
  }

  render() {
    const { crop, src, imageURL } = this.state;

    return (
      <>
          <div
            className="profile-img"
            onClick={this.triggerCrop}
            style={{
              backgroundImage: `url(${imageURL})`,
            }}
          >
          <div className="edit-profile-pic">Edit</div>
          </div>
			<input
			className="d-none"
            type="file"
            accept="image/*"
            onChange={this.onSelectFile}
            ref={this.fileUploadRef}
          />
        {src && (
          <div className="cropControls">
            <ReactCrop
              src={src}
              crop={crop}
              ruleOfThirds
              onImageLoaded={this.onImageLoaded}
              onComplete={this.onCropComplete}
              onChange={this.onCropChange}
            />
            <button onClick={this.uploadToFirebase} className="btn">
              Crop
            </button>
          </div>
        )}
      </>
    );
  }
}

export default withFirebase(UserImageCrop);
