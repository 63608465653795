

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Accordion, Card } from 'react-bootstrap';
import './faq-accordion.scss';

class FaqPage extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {}

  render = () => {
    return (
      <>

      <div className="fw banner-inner">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <h1>FAQs</h1>
            </div>
          </div>
        </div>
      </div>

      <div className="fw white-bg">
        <div class="container">
        <div className="row">
          <div className="col-sm-12 sect-pad-top sect-pad-bot">

          <h3 className="pink">Searching Togodo</h3>

            <div class="accordion" id="accordion-faqs">
              <Accordion defaultActiveKey="0">

              <Card className="card">
                <Accordion.Toggle as={Card.Header} eventKey="0">
                  Can I book tickets to the selected event/attraction through Togodo?
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    {' '}
                    <p>Tickets can only currently be purchased directly through the events or attraction organiser.</p>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card className="card">
                <Accordion.Toggle as={Card.Header} eventKey="1">
                  Can I save events/attractions I am interested in?
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="1">
                  <Card.Body>
                    {' '}
                    <p>Yes, if you have a Togodo account (it's free!) you can save events and attractions you're interested in. Simply click the 'interested in' or 'like' icons on the listing page. Your ‘liked’ or ‘interested in’ listings will then be saved to your dashboard under the relevant tab.</p>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card className="card">
                <Accordion.Toggle as={Card.Header} eventKey="2">
                  I want to subscribe/unsubscribe from email alerts, how do I do this?
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="2">
                  <Card.Body>
                    {' '}
                    <p>To manage your email preferences, login to your Togodo account and navigate to your dashboard. You can update your email marketing preferences under the account settings tab.</p>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              </Accordion>
            </div>

            <h3 className="pink sect-pad-top-sm">Adding a listing to Togodo</h3>

            <div class="accordion" id="accordion-faqs2">
              <Accordion defaultActiveKey="0">

              <Card className="card">
                <Accordion.Toggle as={Card.Header} eventKey="0">
                  How much does it cost to add a listing on Togodo?
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    {' '}
                    <p>The cost of adding a listing to Togodo varies depending on the type of listing you are posting. To find out the cost of each listing and what's included click the 'Add listing' link in the top right menu.</p>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card className="card">
                <Accordion.Toggle as={Card.Header} eventKey="1">
                  How long until my event / attraction listing is visible to the public?
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="1">
                  <Card.Body>
                    {' '}
                    <p>Your listing will be immediatly live once you hit the publish button.</p>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card className="card">
                <Accordion.Toggle as={Card.Header} eventKey="2">
                  How long will my event / attraction listing be visible
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="2">
                  <Card.Body>
                    {' '}
                    <p>Attraction listings remain on Togodo until they are deleted or the subscription is cancelled. Event listings will automatically be removed from Togodo once the event end date passes.</p>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card className="card">
                <Accordion.Toggle as={Card.Header} eventKey="3">
                  How far in advance can I post an event listing?
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="3">
                  <Card.Body>
                    {' '}
                    <p>Depending on the listing type you you select your listing will either start 14, 30 or 60 days before your event start date.</p>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card className="card">
                <Accordion.Toggle as={Card.Header} eventKey="4">
                  Can I link my attraction/event to my website?
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="4">
                  <Card.Body>
                    {' '}
                    <p>Yes! You can add links to your website and social media accounts when creating a listing.</p>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card className="card">
                <Accordion.Toggle as={Card.Header} eventKey="5">
                  I want to make amendments to my event/attraction how do I do this?
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="5">
                  <Card.Body>
                    <p>To make an amendment to your event or attraction listing first login to your Togodo account, then click on your name (top right) to go to your dashboard. Under the 'My listings' tab you will see all of your listings. Hover over a listing and click the edit button. Once you have finished editing your listing, make sure to click the 'Publish' button.</p>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              </Accordion>
            </div>

          </div>

          </div>
          </div>
          </div>
      </>
    );
  };
}

export default FaqPage;
