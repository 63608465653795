/**
 * Format dates
 * formatDate('2021-06-29') will return: 'Tue, 29 Jun 21'
 * tip: change year: 'numeric' to '2-digit' for a shorter version of year
 * you can read more about .toLocaleDateString() here:
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleDateString
 */
export function formatDate(dateString) {
	let formattedDate = new Date(dateString).toLocaleDateString('en-gb',
		{ weekday: 'short', month: 'short', day: 'numeric', year: 'numeric' });
	return formattedDate;
}

/**
 * Split Day Open | Close.
 * you can read more about .trim() here:
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/Trim
 */
export function splitDay(day, part) {
  let daypart = day.trim().split(' - ');
  return daypart[part];
}

/**
 * Reset an input field.
 * you can read more about .treset() here:
 * https://developer.mozilla.org/en-US/docs/Web/API/HTMLFormElement/reset
 */
export function resetField(name){
	document.getElementById(name).value = null;
}

/**
 * Get the difference ( in days ) between two dates.
 * you can read more about Date() here:
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date
 */
export function getNumberOfDays(start, end) {
  const date1 = new Date(end);
  const date2 = new Date(start);

  // One day in milliseconds
  const oneDay = 1000 * 60 * 60 * 24;

  // Calculating the time difference between two dates
  const diffInTime = date2.getTime() - date1.getTime();

  // Calculating the no. of days between two dates
  const diffInDays = Math.round(diffInTime / oneDay);

	// if (!diffInDays > 0) {
	// 	return diffInDays;
	// } else {
	// 	return 'not-expiring'
	// }
	return diffInDays;
}