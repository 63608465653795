export const InterestedSvg = () => {
  return (
    <svg
      width="35px"
      height="25px"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <path
          className="heart-stroke"
          d="M10,1c2.5,0,4.7,2.2,4.7,4.7s-2.2,4.7-4.7,4.7S5.3,8.2,5.3,5.7S7.5,1,10,1 M10,0C6.9,0,4.3,2.6,4.3,5.7
					s2.6,5.7,5.7,5.7s5.7-2.6,5.7-5.7S13.1,0,10,0L10,0z"
          fill="#FFFFFF"
        />
        <path
          className="heart-stroke"
          d="M5.7,12.8c0,0,0.1,0,0.2,0.1c1.3,1,2.7,1.4,4.1,1.4c1.6,0,3.1-0.5,4.2-1.4c0,0,0,0,0,0
					c1.7,0.3,3.2,1.2,4.1,2.6l0.1,0.1l0.1,0.1c0.1,0.1,0.2,0.2,0.2,0.2c-0.1,0.2-0.2,0.3-0.3,0.5c-0.1,0.1-0.2,0.3-0.3,0.4
					c-0.2,0.4-0.6,0.7-0.9,1.1L17,18l0,0.1c-0.2,0.3-0.5,0.5-0.9,0.8c-1.9,1.3-4,2-6.2,2c-2.3,0-4.4-0.7-6.2-2
					c-0.3-0.3-0.7-0.6-1-0.9c-0.4-0.4-0.7-0.7-0.9-1.1l-0.1-0.1l-0.1-0.1c-0.1-0.1-0.3-0.3-0.4-0.5c0-0.1,0.1-0.2,0.1-0.3
					c0.1-0.2,0.2-0.3,0.3-0.5C2.5,14.1,4,13.1,5.7,12.8 M5.7,11.8c-2,0.3-3.8,1.4-4.9,3.1c-0.2,0.3-0.4,0.7-0.6,1
					c-0.1,0.1,0,0.3,0,0.5c0.2,0.3,0.4,0.7,0.7,1c0.3,0.5,0.7,0.9,1.1,1.3c0.3,0.3,0.7,0.7,1.1,1c2,1.5,4.3,2.2,6.8,2.2
					s4.8-0.8,6.8-2.2c0.4-0.3,0.8-0.6,1.1-1c0.4-0.4,0.8-0.8,1.1-1.3c0.2-0.3,0.5-0.6,0.7-1c0.3-0.1,0.3-0.3,0.2-0.4
					c-0.1-0.4-0.3-0.7-0.6-1c-1.1-1.7-2.9-2.8-4.9-3.1c-0.2,0-0.5,0-0.7,0.2c-1,0.8-2.3,1.2-3.6,1.2S7.5,12.8,6.4,12
					C6.2,11.9,5.9,11.8,5.7,11.8L5.7,11.8z"
          fill="#FFFFFF"
        />
        <path
          className="heart-full"
          d="M10,0C6.9,0,4.3,2.6,4.3,5.7s2.6,5.7,5.7,5.7s5.7-2.6,5.7-5.7S13.1,0,10,0L10,0z"
        />
        <path
          className="heart-full"
          d="M5.7,11.8c-2,0.3-3.8,1.4-4.9,3.1c-0.2,0.3-0.4,0.7-0.6,1c-0.1,0.1,0,0.3,0,0.5c0.2,0.3,0.4,0.7,0.7,1
					c0.3,0.5,0.7,0.9,1.1,1.3c0.3,0.3,0.7,0.7,1.1,1c2,1.5,4.3,2.2,6.8,2.2s4.8-0.8,6.8-2.2c0.4-0.3,0.8-0.6,1.1-1
					c0.4-0.4,0.8-0.8,1.1-1.3c0.2-0.3,0.5-0.6,0.7-1c0.3-0.1,0.3-0.3,0.2-0.4c-0.1-0.4-0.3-0.7-0.6-1c-1.1-1.7-2.9-2.8-4.9-3.1
					c-0.2,0-0.5,0-0.7,0.2c-1,0.8-2.3,1.2-3.6,1.2S7.5,12.8,6.4,12C6.2,11.9,5.9,11.8,5.7,11.8L5.7,11.8z"
          fill="#FFFFFF"
        />
      </g>
    </svg>
  );
};
