import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Geocode from 'react-geocode';

import firebase from 'firebase';
import 'firebase/firestore';
import * as geofirestore from 'geofirestore';

import { AuthUserContext } from '../Session';
import { withFirebase } from '../Firebase';
import { formatDate } from '../../helpers/ui';
import images from '../../constants/images';
import * as ROUTES from '../../constants/routes';

import InnerBanner from '../Dashboard/innerBanner';
import PostEditor from '../TextEditor';

import ListingMap from '../Maps/addListingMap';
import PostImages from '../ImageCrop';
import RingCounter from '../UI/ringCounter';

import AddressAutoComplete from '../UI/AddressAutoComplete';

import { NotifyUser } from '../UI/theNotification';
import AddListingSignUp from '../SignUp/addListingSignup';
import AddListingSignIn from '../SignIn/addListingSignin';
import emailjs from 'emailjs-com';
// import { resetField } from '../../helpers/ui';
import './add-listing-page.scss';

import usePlacesAutocomplete, {
  getGeocode,
  getDetails,
  getZipCode,
  getLatLng,
} from 'use-places-autocomplete';
import useOnclickOutside from 'react-cool-onclickoutside';

import md5 from 'md5';

class AddListing extends Component {
  static contextType = AuthUserContext;
  constructor(props) {
    super(props);

    this.state = {
      text: '',
      loading: false,
      listings: [],
      thumbs: [],
      activePostType: false,
      postTitle: '',
      pageDescription: '',

      pageTitle: '',
      postThumbnails: '',
      postWebsite: '',
      postTicketsURL: '',
      postFacebook: '',
      postTwitter: '',
      postInstagram: '',

      postStartDate: '',
      postEndDate: '',
      postStartTime: '',
      postEndTime: '',

      addressLine: '',
      postal_town: '',
      administrative_area_level_2: '',
      addressPostCode: '',
      phoneNumber: '',

      number: 0,
      thumb0: '',
      thumb1: '',
      thumb2: '',
      thumb3: '',
      thumb4: '',
      thumb5: '',
      thumb6: '',
      thumb7: '',
      thumb8: '',
      thumb9: '',

      tagCouples: false,
      tagAdults: false,
      tagTeenagers: false,
      tagFamilies: false,
      tagChildren312: false,
      tagBabiesToddlers: false,
      tagPetFriendly: false,
      tagIndoor: false,
      tagOutdoor: false,
      tagSeasonal: false,

      postTypeSelect: true,
      pricingTables: false,
      listingForm: false,
      toDay: new Date().toISOString().split('T')[0],
    };

    this.addressInput = React.createRef();

    this.handleInputChange = this.handleInputChange.bind(this);

    this.ShowTypeSelect = this.ShowTypeSelect.bind(this);
    this.ShowPricing = this.ShowPricing.bind(this);
    this.ShowForm = this.ShowForm.bind(this);
    this.ShowNext = this.ShowNext.bind(this);
  }

  componentDidMount() {
    Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_KEY);
    Geocode.setRegion('uk');
    if (localStorage.getItem('selectedType')) {
      this.setState({
        activePostType: localStorage.getItem('selectedType'),
      });
      this.ShowPricing();
    }

    //get user's location
    //TODO: use userCurrentPotision() instead
    // navigator.geolocation.getCurrentPosition(function (position) {
    //   console.log('Latitude is :', position.coords.latitude);
    //   console.log('Longitude is :', position.coords.longitude);
    // });
  }

  handleInputChange(event) {
    const target = event.target;
    const value =
      target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  handleLinks = (url) => {
    return !/^https?:\/\//i.test(url) ? `https://${url}` : url;
  };

  onChangeText = (event) => {
    this.setState({ text: event.target.value });
  };

  onRemoveListing = (uid) => {
    this.props.firebase.listing(uid).delete();
  };

  activeToggle = (postType) => {
    this.setState({ activePostType: postType });
  };

  async geoLocation(location) {
    await Geocode.fromAddress(location).then((res) => {
      const { lat, lng } = res.results[0].geometry.location;
      this.setState({
        lat: lat,
        lng: lng,
      });
    });

    //TODO: move this
    // Create a GeoQuery based on a location
    // const query = geocollection.near({
    //   center: new firebase.firestore.GeoPoint(40.6323456, 22.9408366),
    //   radius: 1,
    // });

    //   .limit(1);
    //   .where('name', '==', 'platia aristotelous');

    // Get query (as Promise)
    // query.get().then((value) => {
    //   // All GeoDocument returned by GeoQuery, like the GeoDocument added above
    //   for (const doc of value.docs) {
    //     console.log(doc.data());
    //   }
    // });
  }

  onChangeAddress = (event) => {
    let location = event.target.value;
    if (location !== '' && location.length > 3) {
      this.geoLocation(
        this.state.addressLine +
          ',' +
          this.state.postal_town +
          ',' +
          this.state.administrative_area_level_2,
      );
    }
  };

  postAdded(docRef, status, preview) {
    this.setState({
      docRef: docRef.id,
    });
    if (!preview && status === 'draft') {
      this.setState(
        { notification: 'Your listing has been saved as a draft' },
        () => {
          this.props.history.push(ROUTES.MYLISTINGS);
        },
      );
    }
	  if (!preview && status === 'live') {
	let eMailListingLink = `https://togodo.co.uk/${this.state.activePostType}/?listing=${docRef.id}`;
      this.setState(
        { notification: 'Your listing has been published' },
        () => {
          const authUser = this.context;
          var templateParams = {
            email: authUser.email,
            name: authUser.username,
            listingTitle: this.state.postTitle,
            text: 'your listing has been published',
            listingLink: eMailListingLink,
          };
          emailjs
            .send(
              'service_smt',
              'template_uy80blu',
              templateParams,
              'user_XzyVLl9ojVvIJBtcrr1F6',
            )
            .then(
              function (response) {
                console.log(
                  'SUCCESS!',
                  response.status,
                  response.text,
                );
              },
              function (error) {
                console.log('FAILED...', error);
              },
            );
          this.props.history.push(ROUTES.MYLISTINGS);
        },
      );
    }
    if (preview) {
      const PREVIEW = `${this.state.activePostType}/?listing=${docRef.id}`;
      this.props.history.push(PREVIEW);
    }
  }
  onPostSave = (status, preview) => {
	let thumbnails = [];
    for (let i = 0; i <= 9; i++) {
      thumbnails.push(this.state[`thumb${i}`]);
	}
	  
    let thumbs = thumbnails.filter((item) => item);
    this.setState({ thumbnails: thumbs });
    const authUser = this.context;
    // Create a Firestore reference
    const firestore = firebase.firestore();
    // Create a GeoFirestore reference
    const GeoFirestore = geofirestore.initializeApp(firestore);
    // Create a GeoCollection reference
    const geocollection = GeoFirestore.collection('listings');
    // Add a GeoDocument to a GeoCollection
    geocollection
      .add({
        owner: authUser.uid,
        originalOwner: authUser.uid,
        ownerHash: md5(authUser.uid),
        createdAt: this.props.firebase.fieldValue.serverTimestamp(),
        editedAt: '',
        coordinates: new firebase.firestore.GeoPoint(
          this.state.lat,
          this.state.lng,
        ),
        postTitle: this.state.postTitle,
        postType: this.state.activePostType,
        postStartDate: this.state.postStartDate,
        postStartTime: this.state.postStartTime,
        postEndDate: this.state.postEndDate,
        postEndTime: this.state.postEndTime,
        postStatus: status,
        postThumbnails: thumbnails,
        postWebsite: this.state.postWebsite,
        postTicketsURL: this.state.postTicketsURL,
        postFacebook: this.state.postFacebook,
        postTwitter: this.state.postTwitter,
        postInstagram: this.state.postInstagram,
        pageTitle: this.state.pageTitle,
        pageDescription: this.state.pageDescription,
        postAddressLine: this.state.addressLine,
        postAddressTownCity: this.state.postal_town,
        postAddressCounty: this.state.administrative_area_level_2,
        postAddressPostCode: this.state.addressPostCode,
        postPhoneNumber: this.state.phoneNumber,
        tagCouples: this.state.tagCouples,
        tagAdults: this.state.tagAdults,
        tagTeenagers: this.state.tagTeenagers,
        tagFamilies: this.state.tagFamilies,
        tagChildren312: this.state.tagChildren312,
        tagBabiesToddlers: this.state.tagBabiesToddlers,
        tagPetFriendly: this.state.tagPetFriendly,
        tagIndoor: this.state.tagIndoor,
        tagOutdoor: this.state.tagOutdoor,
        tagSeasonal: this.state.tagSeasonal,
        location:
          this.state.addressLine +
          ',' +
          this.state.postal_town +
          ',' +
          this.state.administrative_area_level_2 +
          ',' +
          this.state.addressPostCode,
        days: [
          {
            everyday:
              this.state.everydayOpen +
              ' - ' +
              this.state.everydayClose,
            monday:
              this.state.mondayOpen + ' - ' + this.state.mondayClose,
            tuesday:
              this.state.tuesdayOpen +
              ' - ' +
              this.state.tuesdayClose,
            wednesday:
              this.state.wednesdayOpen +
              ' - ' +
              this.state.wednesdayClose,
            thursday:
              this.state.thursdayOpen +
              ' - ' +
              this.state.thursdayClose,
            friday:
              this.state.fridayOpen + ' - ' + this.state.fridayClose,
            saturday:
              this.state.saturdayOpen +
              ' - ' +
              this.state.saturdayClose,
            sunday:
              this.state.sundayOpen + ' - ' + this.state.sundayClose,
          },
        ],
      })
      .then((docRef) => this.postAdded(docRef, status, preview))
      .then(() => {
        localStorage.setItem('selectedType', null);
      })
      .catch((error) => error);
  };

  parentFunction = (imageURL, number, action) => {
    let thumb = `thumb${number}`;
    this.setState({ [thumb]: imageURL, number: number });
    let num = number + 1;
    let element = document.getElementById(`listing-image-${num}`);
    if (!action) {
      element.classList.remove('hidden');
    } else {
      element.classList.add('hidden');
    }
  };

  visitorRegistered = (status) => {
    this.setState({ newUser: status });
    console.log(status);
  };

  ShowTypeSelect() {
    this.setState({
      postTypeSelect: true,
      pricingTables: false,
      listingForm: false,
    });
  }

  ShowPricing() {
    this.setState({
      pricingTables: true,
      listingForm: false,
      postTypeSelect: false,
    });
  }

  ShowForm() {
    this.setState({
      listingForm: true,
      pricingTables: false,
      postTypeSelect: false,
    });
  }

  ShowNext() {
    this.setState({
      showNext: true,
    });
  }

  postEditorData = (data) => {
    this.setState({ pageDescription: data });
  };

  render = () => {
    const PlacesAutocomplete = () => {
      const {
        ready,
        value,
        suggestions: { status, data },
        setValue,
        clearSuggestions,
      } = usePlacesAutocomplete({
        requestOptions: {
          /* Define search scope here */
          componentRestrictions: { country: ['uk'] },
        },
        debounce: 300,
        cache: 24 * 60 * 60,
      });
      const ref = useOnclickOutside(() => {
        // When user clicks outside of the component, we can dismiss
        // the searched suggestions by calling this method
        clearSuggestions();
      });

      const handleInput = (e) => {
        // Update the keyword of the input element
        setValue(e.target.value);
      };

      const handleSelect =
        ({ description }) =>
        () => {
          // When user selects a place, we can replace the keyword without request data from API
          // by setting the second parameter to "false"
          setValue(description, false);
          clearSuggestions();

          // Get latitude and longitude via utility functions
          getGeocode({ address: description })
            .then((results) => getLatLng(results[0]))
            .then(({ lat, lng }) => {
              console.log('📍 Coordinates: ', { lat, lng });
              this.setState({ addressLine: description });
              this.geoLocation(description);
            })
            .catch((error) => {
              console.log('😱 Error: ', error);
            });

          // Get latitude and longitude via utility functions
          getGeocode({ address: description })
            .then(
              (results) =>
                results[0]['address_components'].map(
                  (addressPart) => {
                    // alert(addressPart['types'][0]);
                    this.setState({
                      [addressPart['types'][0]]:
                        addressPart['long_name'],
                    });
                    console.log(this.state);
                  },
                ),
              //   console.log(
              //     results,
              //     // results[0]['address_components'][0]['types'][0],
              //     // results[0]['address_components'][0]['long_name']['type'],
              //     // results[0]['address_components'][1]['long_name'],
              //     // results[0]['address_components'][2]['long_name'],
              //     // results[0]['address_components'][3]['long_name'],
              //     // results[0]['address_components'][4]['long_name'],
              //   ),
            )
            .catch((error) => {
              console.log('😱 Error: ', error);
            });

          getGeocode({ address: description })
            .then((results) => getZipCode(results[0], false))
            .then((zipCode) => {
              // console.log('ZIP Code: ', zipCode);
              this.setState({ addressPostCode: zipCode });
            })
            .catch((error) => {
              console.log('😱 Error: ', error);
            });

          getDetails({ address: description })
            .then((details) => {
              console.log('Details: ', details);
            })
            .catch((error) => {
              console.log('Error: ', error);
            });
        };

      const renderSuggestions = () =>
        data.map((suggestion) => {
          const {
            place_id,
            structured_formatting: { main_text, secondary_text },
          } = suggestion;

          return (
            <li key={place_id} onClick={handleSelect(suggestion)}>
              <strong>{main_text}</strong>{' '}
              <small>{secondary_text}</small>
            </li>
          );
        });

      return (
        <form ref={ref}>
          <input
            value={value}
            onChange={handleInput}
            disabled={!ready}
            autocomplete="off"
            placeholder={
              this.state.addressLine
                ? this.state.addressLine
                : 'Type an address'
            }
            className="form-control"
          />
          {status === 'OK' && (
            <ul className="auto-complete-list">
              {renderSuggestions()}
            </ul>
          )}
        </form>
      );
    };
    const {
      text,
      listings,
      loading,
      activePostType,
      lat,
      lng,
      docRef,
      postTypeSelect,
      pricingTables,
      listingForm,
      toDay,
    } = this.state;
    return (
      <>
        <InnerBanner className={activePostType} />
        {this.state.notification && (
          <NotifyUser
            title="Success"
            message={this.state.notification}
            status="success"
          />
        )}
        <AuthUserContext.Consumer>
          {(authUser) => (
            <div>
              <Helmet>
                <title>Add listing | ToGoDo</title>
              </Helmet>
              <form
                className="d-none"
                onSubmit={(event) =>
                  this.onCreateListing(event, authUser)
                }
              >
                <input
                  type="text"
                  value={text}
                  onChange={this.onChangeText}
                />
                <button type="submit">Send</button>
              </form>
              <div
                className={`white-bg step-process step-process-nav ${activePostType}`}
              >
                <div className="container">
                  <div className="row">
                    <div className="col-sm-12 text-center step-nav list-group">
                      <a
                        className={`list-group-item ${
                          postTypeSelect === true ? 'active' : ''
                        }`}
                        onClick={this.ShowTypeSelect}
                      >
                        Event/Attraction
                      </a>
                      <a
                        className={`list-group-item ${
                          pricingTables === true ? 'active' : ''
                        }`}
                        onClick={this.ShowPricing}
                      >
                        Listing type
                      </a>
                      <a
                        className={`list-group-item ${
                          listingForm === true ? 'active' : ''
                        }`}
                        onClick={this.ShowForm}
                      >
                        Listing details
                      </a>
                      <a className="list-group-item">
                        Save &amp; post
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {postTypeSelect && !pricingTables && !listingForm && (
                <>
                  <div
                    className="fw white-bg sect-pad-bot step-process"
                    // id="scrollspyHeading1"
                  >
                    <div
                      className={`banner-inner-para ${activePostType}`}
                    >
                      <div className="container">
                        <div className="row">
                          <div className="col-sm-12 col-lg-8 offset-lg-2 text-center">
                            <h1>Add a listing</h1>
                            <p>
                              Add your event or attraction to Togodo
                              in 3 easy steps.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="fw sect-pad-top">
                      <div className="container">
                        <div className="row">
                          <div className="col-sm-12 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">
                            <h2 className="purple text-center step-title">
                              I'm posting an...
                            </h2>
                            <div className="row">
                              <div
                                className={`col-6 event-sel ${
                                  activePostType === 'event'
                                    ? 'activePostType'
                                    : ''
                                }`}
                                onClick={(e) => {
                                  this.activeToggle('event');
                                  e.preventDefault();
                                }}
                              >
                                <Link className="fw step-box text-center icon event">
                                  <img src={images.eventIcon} />
                                  <h2 className="pink">Event</h2>
                                  <p>
                                    An event is a listing with a start
                                    and end date. The listing will
                                    expire once the event has ended.
                                  </p>
                                </Link>
                              </div>
                              <div
                                className={`col-6 attraction-sel ${
                                  activePostType === 'attraction'
                                    ? 'activePostType'
                                    : ''
                                }`}
                                onClick={(e) => {
                                  this.activeToggle('attraction');
                                  e.preventDefault();
                                }}
                              >
                                <Link
                                  to="#"
                                  className="fw step-box icon text-center attraction"
                                >
                                  <img src={images.attractionIcon} />
                                  <h2 className="blue">Attraction</h2>
                                  <p>
                                    An attraction is a physical place
                                    with set opening hours. This
                                    listing shows permanently.
                                  </p>
                                </Link>
                              </div>
                              <div className="col-sm-12 text-center">
                                <a
                                  className={`btn-purple ${
                                    !this.state.activePostType
                                      ? 'disabled'
                                      : ''
                                  }`}
                                  onClick={this.ShowPricing}
                                >
                                  Continue
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>{' '}
                </>
              )}
              {pricingTables &&
                !postTypeSelect &&
                !listingForm &&
                authUser && (
                  <>
                    <div className="fw white-bg sect-pad-top">
                      <div className="container">
                        <div className="row">
                          <div className="col-sm-12 text-center">
                            <h2 className="purple step-title">
                              To celebrate the launch of Togodo users
                              can post premium listings <br />
                              free until January 1st 2022!
                              {/* Choose the listing that best suits your requirements */}
                            </h2>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="fw white-bg step-process"
                      id="scrollspyHeading2"
                    >
                      <div className="container">
                        <div
                          className={`row pricetableRow attraction ${
                            activePostType != 'event'
                              ? 'activePostType'
                              : ''
                          }`}
                        >
                          <div className="col-sm-12">
                            <div className="price-box attraction disabled">
                              <div className="fw price-title text-center">
                                <h3>Standard Listing</h3>
                              </div>
                              <div className="fw">
                                <h4>Free</h4>
                                <Link to="#" className="btn">
                                  Choose Listing
                                </Link>
                                <ul>
                                  <li>14 day listing duration</li>
                                  <li>1 cover image</li>
                                  <li>
                                    Dedicated listing page including
                                    map
                                  </li>
                                  <li>Website link</li>
                                  <li>Buy tickets link</li>
                                  <li>
                                    300 character description limit
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div
                              className={`price-box featured attraction ${
                                this.state.showNext
                                  ? 'activePrice'
                                  : ''
                              }`}
                            >
                              <span>Most Popular</span>
                              <div className="fw price-title text-center">
                                <h3>Premium Listing</h3>
                              </div>
                              <div className="fw">
                                <h4>Free</h4>
                                <h3 className="promo">Until 2022!</h3>
                                <Link
                                  to="#"
                                  className="btn btn-white"
                                  onClick={this.ShowNext}
                                >
                                  Choose Listing
                                </Link>
                                <ul>
                                  <li>30 day listing duration</li>
                                  <li>Visually highlighted</li>
                                  <li>
                                    Boosted above standard listings
                                  </li>
                                  <li>10 gallery images</li>
                                  <li>Unlimited description limit</li>
                                  <li>
                                    Feature rich listing page
                                    including: map, images and social
                                    links
                                  </li>
                                  <li>Video embed</li>
                                  <li>
                                    Listing featured in weekly
                                    newsletter*
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div className="price-box attraction disabled">
                              <div className="fw price-title text-center">
                                <h3>Pro Listing</h3>
                              </div>
                              <div className="fw">
                                <h4>TBC</h4>
                                <Link
                                  to="contact.html"
                                  className="btn"
                                >
                                  Make an enquiry
                                </Link>
                                <ul>
                                  <li>60 day listing duration</li>
                                  <li>
                                    Suited to large or national events
                                  </li>
                                  <li>Featured on Togodo homepage</li>
                                  <li>
                                    Featured on email newsletter
                                  </li>
                                  <li>
                                    Includes all features of premium
                                    listing
                                  </li>
                                  <li>Other bespoke options</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-12 text-center cont-wrap sect-pad-bot">
                            <a
                              className="btn-purple"
                              onClick={this.ShowTypeSelect}
                            >
                              Back
                            </a>
                            {authUser && (
                              <a
                                onClick={this.ShowForm}
                                className={`btn-purple ${
                                  this.state.showNext
                                    ? ''
                                    : 'disabled'
                                }`}
                              >
                                Continue
                              </a>
                            )}
                            {this.state.newUser && (
                              <h3>
                                Please verify your email to continue
                              </h3>
                            )}
                          </div>
                        </div>
                        {!authUser && pricingTables && (
                          <>
                            <div
                              className={`fw white-bg step-process ${
                                activePostType === 'event'
                                  ? 'event'
                                  : 'attraction'
                              }`}
                              id="userActions"
                            >
                              <>
                                <AddListingSignUp
                                  registeredCall={this.visitorRegistered.bind(
                                    this,
                                  )}
                                  selectedType={activePostType}
                                />
                                <AddListingSignIn />
                                <a
                                  className="btn-purple"
                                  onClick={this.ShowTypeSelect}
                                >
                                  Back
                                </a>
                              </>
                            </div>
                          </>
                        )}
                        {authUser && (
                          <>
                            <div
                              className={`row pricetableRow event ${
                                activePostType === 'event'
                                  ? 'activePostType'
                                  : ''
                              }`}
                            >
                              <div className="col-sm-12">
                                <div className="price-box disabled">
                                  <div className="fw price-title text-center">
                                    <h3>Standard Listing</h3>
                                  </div>
                                  <div className="fw">
                                    <h4>Free</h4>
                                    <Link to="#" className="btn">
                                      Choose Listing
                                    </Link>
                                    <ul>
                                      <li>14 day listing duration</li>
                                      <li>1 cover image</li>
                                      <li>
                                        Dedicated listing page
                                        including map
                                      </li>
                                      <li>Website link</li>
                                      <li>Buy tickets link</li>
                                      <li>
                                        300 character description
                                        limit
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div
                                  className={`price-box featured ${
                                    this.state.showNext
                                      ? 'activePrice'
                                      : ''
                                  }`}
                                >
                                  <span>Most Popular</span>
                                  <div className="fw price-title text-center">
                                    <h3>Premium Listing</h3>
                                  </div>
                                  <div className="fw">
                                    <h4>FREE</h4>
                                    <h3 className="promo">
                                      Until 2022!
                                    </h3>
                                    <Link
                                      to="#"
                                      className="btn btn-white"
                                      onClick={this.ShowNext}
                                    >
                                      Choose Listing
                                    </Link>
                                    <ul>
                                      <li>30 day listing duration</li>
                                      <li>Visually highlighted</li>
                                      <li>
                                        Boosted above standard
                                        listings
                                      </li>
                                      <li>10 gallery images</li>
                                      <li>
                                        Unlimited description limit
                                      </li>
                                      <li>
                                        Feature rich listing page
                                        including: map, images and
                                        social links
                                      </li>
                                      <li>Video embed</li>
                                      <li>
                                        Listing featured in weekly
                                        newsletter*
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div className="price-box disabled">
                                  <div className="fw price-title text-center">
                                    <h3>Pro Listing</h3>
                                  </div>
                                  <div className="fw">
                                    <h4>TBC</h4>
                                    <Link
                                      to="contact.html"
                                      className="btn"
                                    >
                                      Make an enquiry
                                    </Link>
                                    <ul>
                                      <li>60 day listing duration</li>
                                      <li>
                                        Suited to national listings or
                                        events
                                      </li>
                                      <li>
                                        Featured on Togodo homepage
                                      </li>
                                      <li>
                                        Featured on email newsletter
                                      </li>
                                      <li>
                                        Includes all features of
                                        premium listing
                                      </li>
                                      <li>Other bespoke options</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-12 text-center cont-wrap sect-pad-bot">
                                <a
                                  className="btn-purple"
                                  onClick={this.ShowTypeSelect}
                                >
                                  Back
                                </a>
                                {authUser && (
                                  <a
                                    onClick={this.ShowForm}
                                    className={`btn-purple ${
                                      this.state.showNext
                                        ? ''
                                        : 'disabled'
                                    }`}
                                  >
                                    Continue
                                  </a>
                                )}
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>{' '}
                  </>
                )}
              {!authUser && pricingTables && (
                <div
                  class="fw white-bg step-process"
                  id="userActions"
                >
                  <>
                    <div
                      className={`fw white-bg step-process ${
                        activePostType === 'event'
                          ? 'event'
                          : 'attraction'
                      }`}
                      id="userActions"
                    >
                      <>
                        <AddListingSignUp
                          registeredCall={this.visitorRegistered.bind(
                            this,
                          )}
                          selectedType={activePostType}
                        />
                        <AddListingSignIn />

                        <div className="fw sect-pad-bot">
                          <div className="container">
                            <div className="row">
                              <div className="col-sm-12 text-center">
                                <a
                                  className="btn-purple"
                                  onClick={this.ShowTypeSelect}
                                >
                                  Back
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    </div>
                  </>
                </div>
              )}
              {listingForm && !pricingTables && !postTypeSelect && (
                <>
                  <div
                    className={`fw white-bg sect-pad-bot step-process ${activePostType}`}
                    id="scrollspyHeading3"
                  >
                    <div
                      className={`banner-inner-para ${activePostType}`}
                    >
                      <div className="container">
                        <div className="row">
                          <div className="col-sm-12 col-lg-8 offset-lg-2 text-center">
                            <h1>Listing Details</h1>
                            <p>
                              Add your event or attraction to Togodo
                              in 3 easy steps.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="container listing-details-wrap sect-pad-top">
                      <div className="row">
                        <div className="col-sm-12 col-md-8">
                          <div className="row">
                            <div className="col-sm-12">
                              <h2>Listing details</h2>
                              <h3>
                                {activePostType} name, date and time
                              </h3>
                            </div>
                            <div className="col-sm-12">
                              <label>{activePostType} name</label>
                              <input
                                type="text"
                                className="form-control"
                                maxLength="50"
                                spellCheck="false"
                                name="postTitle"
                                value={this.state.postTitle}
                                onChange={this.handleInputChange}
                              />
                              {this.state.postTitle && (
                                <RingCounter
                                  countThis={this.state.postTitle}
                                  setMaxTo={50}
                                  key={this.state.postTitle}
                                />
                              )}
                              <span className="charCounter"></span>
                              {/* Need to add a JS character counter here and limit characters on title to 50 characters*/}
                            </div>
                            {activePostType == 'event' && toDay && (
                              <>
                                <div className="col-sm-12 col-md-6">
                                  <label>
                                    {activePostType} start date
                                  </label>
                                  <input
                                    type="date"
                                    className="form-control"
                                    placeholder="DD/MM/YYYY"
                                    name="postStartDate"
                                    min={toDay}
                                    value={this.state.postStartDate}
                                    onChange={this.handleInputChange}
                                  />
                                </div>
                                <div className="col-sm-12 col-md-6">
                                  <label>
                                    {activePostType} end date
                                  </label>
                                  <input
                                    type="date"
                                    className="form-control"
                                    placeholder="DD/MM/YYYY"
                                    name="postEndDate"
                                    min={this.state.postStartDate}
                                    value={this.state.postEndDate}
                                    onChange={this.handleInputChange}
                                  />
                                </div>
                              </>
                            )}

                            {activePostType == 'event' && toDay && (
                              <>
                                <div className="col-sm-12 col-md-6">
                                  <label>
                                    {activePostType} start time
                                  </label>
                                  <input
                                    type="time"
                                    className="form-control"
                                    name="postStartTime"
                                    value={this.state.postStartTime}
                                    onChange={this.handleInputChange}
                                  />
                                </div>
                                <div className="col-sm-12 col-md-6">
                                  <label>
                                    {activePostType} end time
                                  </label>
                                  <input
                                    type="time"
                                    className="form-control"
                                    name="postEndTime"
                                    value={this.state.postEndTime}
                                    onChange={this.handleInputChange}
                                  />
                                </div>
                              </>
                            )}

                            {activePostType == 'attraction' && (
                              <>
                                <div className="col-sm-12">
                                  <h3>Opening Hours</h3>
                                </div>

                                <div className="col-sm-12">
                                  <div className="row">
                                    <div className="col-sm-12">
                                      <h5>Everyday</h5>
                                    </div>
                                    <div className="col-sm-12 col-md-6 time-wrap">
                                      <p>
                                        <label>Open at:</label>
                                      </p>
                                      <input
                                        type="time"
                                        step="900"
                                        name="everydayOpen"
                                        id="everydayOpen"
                                        value={
                                          this.state.everydayOpen
                                        }
                                        onChange={
                                          this.handleInputChange
                                        }
                                      />{' '}
                                    </div>
                                    <div className="col-sm-12 col-md-6 time-wrap">
                                      <p>
                                        <label>Close at:</label>
                                      </p>
                                      <input
                                        type="time"
                                        step="900"
                                        name="everydayClose"
                                        id="everydayClose"
                                        value={
                                          this.state.everydayClose
                                        }
                                        onChange={
                                          this.handleInputChange
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                {this.state.everydayClose && (
                                  <button
                                    className="btn resetBtn"
                                    onClick={(e) => {
                                      document.getElementById(
                                        'everydayOpen',
                                      ).value = null;
                                      document.getElementById(
                                        'everydayClose',
                                      ).value = null;
                                      this.setState({
                                        everydayOpen: null,
                                        everydayClose: null,
                                      });
                                      e.preventDefault();
                                    }}
                                  >
                                    Reset
                                  </button>
                                )}
                                {!this.state.everydayClose && (
                                  <>
                                    <div className="col-sm-12 col-md-6">
                                      <div className="row">
                                        <div className="col-sm-12">
                                          <h5>Monday</h5>
                                        </div>
                                        <div className="col-sm-12 col-md-6 time-wrap">
                                          <p>
                                            <label>Open at:</label>
                                          </p>
                                          <input
                                            type="time"
                                            step="900"
                                            name="mondayOpen"
                                            value={
                                              this.state.mondayOpen
                                            }
                                            onChange={
                                              this.handleInputChange
                                            }
                                          />{' '}
                                        </div>
                                        <div className="col-sm-12 col-md-6 time-wrap">
                                          <p>
                                            <label>Close at:</label>
                                          </p>
                                          <input
                                            type="time"
                                            step="900"
                                            name="mondayClose"
                                            value={
                                              this.state.mondayClose
                                            }
                                            onChange={
                                              this.handleInputChange
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-sm-12 col-md-6">
                                      <div className="row">
                                        <div className="col-sm-12">
                                          <h5>Tuesday</h5>
                                        </div>
                                        <div className="col-sm-12 col-md-6 time-wrap">
                                          <p>
                                            <label>Open at:</label>
                                          </p>
                                          <input
                                            type="time"
                                            step="900"
                                            name="tuesdayOpen"
                                            value={
                                              this.state.tuesdayOpen
                                            }
                                            onChange={
                                              this.handleInputChange
                                            }
                                          />{' '}
                                        </div>
                                        <div className="col-sm-12 col-md-6 time-wrap">
                                          <p>
                                            <label>Close at:</label>
                                          </p>
                                          <input
                                            type="time"
                                            step="900"
                                            name="tuesdayClose"
                                            value={
                                              this.state.tuesdayClose
                                            }
                                            onChange={
                                              this.handleInputChange
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-sm-12 col-md-6">
                                      <div className="row">
                                        <div className="col-sm-12">
                                          <h5>Wednesday</h5>
                                        </div>
                                        <div className="col-sm-12 col-md-6 time-wrap">
                                          <p>
                                            <label>Open at:</label>
                                          </p>
                                          <input
                                            type="time"
                                            step="900"
                                            name="wednesdayOpen"
                                            value={
                                              this.state.wednesdayOpen
                                            }
                                            onChange={
                                              this.handleInputChange
                                            }
                                          />{' '}
                                        </div>
                                        <div className="col-sm-12 col-md-6 time-wrap">
                                          <p>
                                            <label>Close at:</label>
                                          </p>
                                          <input
                                            type="time"
                                            step="900"
                                            name="wednesdayClose"
                                            value={
                                              this.state
                                                .wednesdayClose
                                            }
                                            onChange={
                                              this.handleInputChange
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-sm-12 col-md-6">
                                      <div className="row">
                                        <div className="col-sm-12">
                                          <h5>Thursday</h5>
                                        </div>
                                        <div className="col-sm-12 col-md-6 time-wrap">
                                          <p>
                                            <label>Open at:</label>
                                          </p>
                                          <input
                                            type="time"
                                            step="900"
                                            name="thursdayOpen"
                                            value={
                                              this.state.thursdayOpen
                                            }
                                            onChange={
                                              this.handleInputChange
                                            }
                                          />{' '}
                                        </div>
                                        <div className="col-sm-12 col-md-6 time-wrap">
                                          <p>
                                            <label>Close at:</label>
                                          </p>
                                          <input
                                            type="time"
                                            step="900"
                                            name="thursdayClose"
                                            value={
                                              this.state.thursdayClose
                                            }
                                            onChange={
                                              this.handleInputChange
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-sm-12 col-md-6">
                                      <div className="row">
                                        <div className="col-sm-12">
                                          <h5>Friday</h5>
                                        </div>
                                        <div className="col-sm-12 col-md-6 time-wrap">
                                          <p>
                                            <label>Open at:</label>
                                          </p>
                                          <input
                                            type="time"
                                            step="900"
                                            name="fridayOpen"
                                            value={
                                              this.state.fridayOpen
                                            }
                                            onChange={
                                              this.handleInputChange
                                            }
                                          />{' '}
                                        </div>
                                        <div className="col-sm-12 col-md-6 time-wrap">
                                          <p>
                                            <label>Close at:</label>
                                          </p>
                                          <input
                                            type="time"
                                            step="900"
                                            name="fridayClose"
                                            value={
                                              this.state.fridayClose
                                            }
                                            onChange={
                                              this.handleInputChange
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-sm-12 col-md-6">
                                      <div className="row">
                                        <div className="col-sm-12">
                                          <h5>Saturday</h5>
                                        </div>
                                        <div className="col-sm-12 col-md-6 time-wrap">
                                          <p>
                                            <label>Open at:</label>
                                          </p>
                                          <input
                                            type="time"
                                            step="900"
                                            name="saturdayOpen"
                                            value={
                                              this.state.saturdayOpen
                                            }
                                            onChange={
                                              this.handleInputChange
                                            }
                                          />{' '}
                                        </div>
                                        <div className="col-sm-12 col-md-6 time-wrap">
                                          <p>
                                            <label>Close at:</label>
                                          </p>
                                          <input
                                            type="time"
                                            step="900"
                                            name="saturdayClose"
                                            value={
                                              this.state.saturdayClose
                                            }
                                            onChange={
                                              this.handleInputChange
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-sm-12 col-md-6">
                                      <div className="row">
                                        <div className="col-sm-12">
                                          <h5>Sunday</h5>
                                        </div>
                                        <div className="col-sm-12 col-md-6 time-wrap">
                                          <p>
                                            <label>Open at:</label>
                                          </p>
                                          <input
                                            type="time"
                                            step="900"
                                            name="sundayOpen"
                                            value={
                                              this.state.sundayOpen
                                            }
                                            onChange={
                                              this.handleInputChange
                                            }
                                          />{' '}
                                        </div>
                                        <div className="col-sm-12 col-md-6 time-wrap">
                                          <p>
                                            <label>Close at:</label>
                                          </p>
                                          <input
                                            type="time"
                                            step="900"
                                            name="sundayClose"
                                            value={
                                              this.state.sundayClose
                                            }
                                            onChange={
                                              this.handleInputChange
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </>
                            )}
                          </div>
                          <div className="row d-none">
                            <div className="col-sm-12">
                              <h3>Listing start date</h3>
                              <p>
                                <em>
                                  The date you want your listing to
                                  commence on Togodo
                                </em>
                              </p>
                            </div>
                            <div className="col-sm-12 col-md-6">
                              <input
                                type="date"
                                className="form-control"
                                placeholder="DD/MM/YYYY"
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-12">
                              <h3>{activePostType} location</h3>
                            </div>
                            <div className="col-sm-12">
                              <label>Address line 1</label>
                              {/* <input
                                type="text"
                                className="form-control"
                                name="addressLine"
                                ref={this.addressInput}
                                onBlur={this.onChangeAddress.bind(
                                  this,
                                )}
                                onChange={this.handleInputChange}
                                required
                              /> */}
                              {/* <AddressAutoComplete /> */}
                              <PlacesAutocomplete />
                              {/* <input
                            className="form-control custom-input-fields"
                            type="text"
                            id="address"
                            name="address"
                            // placeholder="Address"
                            ref={this.addressInput}
                            //onChange={this.onChangeAddress.bind(this)}
                            onBlur={this.onChangeAddress.bind(this)}
                            required
                          /> */}
                            </div>
                            <div className="col-sm-12 col-md-6">
                              <label>Town / City</label>
                              <input
                                type="text"
                                className="form-control"
                                name="postal_town"
                                // onBlur={this.onChangeAddress.bind(
                                //   this,
                                // )}
                                placeholder={this.state.postal_town}
                                onChange={this.handleInputChange}
                              />
                            </div>
                            <div className="col-sm-12 col-md-6">
                              <label>County</label>
                              <input
                                type="text"
                                className="form-control"
                                name="administrative_area_level_2"
                                // onBlur={this.onChangeAddress.bind(
                                //   this,
                                // )}
                                placeholder={
                                  this.state
                                    .administrative_area_level_2
                                }
                                onChange={this.handleInputChange}
                              />
                            </div>
                            <div className="col-sm-12 col-md-6">
                              <label>Postcode</label>
                              <input
                                type="text"
                                className="form-control"
                                name="addressPostCode"
                                placeholder={
                                  this.state.addressPostCode
                                }
                                // onBlur={this.onChangeAddress.bind(
                                //   this,
                                // )}
                                onChange={this.handleInputChange}
                              />
                            </div>
                            {activePostType === 'attraction' && (
                              <div className="col-sm-12 col-md-6">
                                <label>Phone number</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="phoneNumber"
                                  placeholder={this.state.phoneNumber}
                                  onChange={this.handleInputChange}
                                />
                              </div>
                            )}
                            <div className="col-sm-12 listingMapContainer">
                              {lat && lng && (
                                <ListingMap center={[lat, lng]} />
                              )}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-12">
                              <h3>{activePostType} images</h3>
                              {/* <ImageCrop
                            functionCallFromParent={this.parentFunction.bind(
                              this,
                            )}
                            activePostType={activePostType}
                            currentUser={authUser.uid}
                            maxFiles={10}
                          /> */}
                              {Array.apply(null, { length: 10 }).map(
                                (e, i) => (
                                  <div
                                    id={`listing-image-${i}`}
                                    className={
                                      i === 0 ? `` : `hidden`
                                    }
                                  >
                                    {i == 0 ? (
                                      <span className="mainListingImage">
                                        Main listing image:
                                      </span>
                                    ) : (
                                      ''
                                    )}
                                    <PostImages
                                      functionCallFromParent={this.parentFunction.bind(
                                        this,
                                      )}
                                      activePostType={activePostType}
                                      currentUser={authUser.uid}
                                      maxFiles={10}
                                      number={i}
                                    />
                                  </div>
                                ),
                              )}

                              {/* {Array.apply(null, { length: 10 }).map(
                            (e, i) => {
                              let avarName = `this.state.thumb` + i;
                              this.state.thumb5 != ''
                                ? this.state.thumb5
                                : 'test';
                            },
                          )} */}
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-sm-12">
                              <h3 className="listing-details">Listing details page</h3>
                            </div>
                            <div className="col-sm-12">
                              <label>Listing page title</label>
                              <input
                                type="text"
                                className="form-control"
                                name="pageTitle"
                                value={this.state.pageTitle}
                                onChange={this.handleInputChange}
                              />
                            </div>
                            <div className="col-sm-12 mb-4">
                              <label>Listing page description</label>
                              <PostEditor
                                // value={value}
                                functionCallFromParent={this.postEditorData.bind(
                                  this,
                                )}
                              />
                              {this.state.pageDescription && (
                                <RingCounter
                                  countThis={
                                    this.state.pageDescription
                                  }
                                  setMaxTo={800}
                                  key={this.state.pageDescription}
                                />
                              )}
                              {/* I want this to be a WYSIWYG editor */}
                              {/*
							//TODO: figure out the character counter
						  */}
                              {/* Need to add a JS character counter here and limit characters on title to 800 characters on the free account –&nbsp;Unlimited on premium*/}
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-sm-12">
                              <h3>{activePostType} categories:</h3>
                            </div>
                            <div className="col-sm-12 col-md-4">
                              <div className="checkbox-wrap">
                                <label htmlFor="tagCouples">
                                  Couples
                                </label>
                                <input
                                  type="checkbox"
                                  id="tagCouples"
                                  className={`visually-hidden ${this.state.tagCouples}`}
                                  name="tagCouples"
                                  checked={this.state.tagCouples}
                                  onChange={this.handleInputChange}
                                />
                                <div className="control-me" />
                              </div>
                            </div>
                            <div className="col-sm-12 col-md-4">
                              <div className="checkbox-wrap">
                                <label htmlFor="tagAdults">
                                  Adults
                                </label>
                                <input
                                  type="checkbox"
                                  id="tagAdults"
                                  name="tagAdults"
                                  className={`visually-hidden ${this.state.tagAdults}`}
                                  checked={this.state.tagAdults}
                                  onChange={this.handleInputChange}
                                />
                                <div className="control-me" />
                              </div>
                            </div>
                            <div className="col-sm-12 col-md-4">
                              <div className="checkbox-wrap">
                                <label htmlFor="tagTeenagers">
                                  Teenagers
                                </label>
                                <input
                                  type="checkbox"
                                  id="tagTeenagers"
                                  name="tagTeenagers"
                                  className={`visually-hidden ${this.state.tagTeenagers}`}
                                  checked={this.state.tagTeenagers}
                                  onChange={this.handleInputChange}
                                />
                                <div className="control-me" />
                              </div>
                            </div>
                            <div className="col-sm-12 col-md-4">
                              <div className="checkbox-wrap">
                                <label htmlFor="tagFamilies">
                                  Families
                                </label>
                                <input
                                  type="checkbox"
                                  id="tagFamilies"
                                  name="tagFamilies"
                                  className={`visually-hidden ${this.state.tagFamilies}`}
                                  checked={this.state.tagFamilies}
                                  onChange={this.handleInputChange}
                                />
                                <div className="control-me" />
                              </div>
                            </div>
                            <div className="col-sm-12 col-md-4">
                              <div className="checkbox-wrap">
                                <label htmlFor="tagChildren312">
                                  Children (3 to 12)
                                </label>
                                <input
                                  type="checkbox"
                                  id="tagChildren312"
                                  name="tagChildren312"
                                  className={`visually-hidden ${this.state.tagChildren312}`}
                                  checked={this.state.tagChildren312}
                                  onChange={this.handleInputChange}
                                />
                                <div className="control-me" />
                              </div>
                            </div>
                            <div className="col-sm-12 col-md-4">
                              <div className="checkbox-wrap">
                                <label htmlFor="tagBabiesToddlers">
                                  Babies and Toddlers
                                </label>
                                <input
                                  type="checkbox"
                                  id="tagBabiesToddlers"
                                  name="tagBabiesToddlers"
                                  className={`visually-hidden ${this.state.tagBabiesToddlers}`}
                                  checked={
                                    this.state.tagBabiesToddlers
                                  }
                                  onChange={this.handleInputChange}
                                />
                                <div className="control-me" />
                              </div>
                            </div>
                            <div className="col-sm-12 col-md-4">
                              <div className="checkbox-wrap">
                                <label htmlFor="tagPetFriendly">
                                  Dog Friendly
                                </label>
                                <input
                                  type="checkbox"
                                  id="tagPetFriendly"
                                  name="tagPetFriendly"
                                  className={`visually-hidden ${this.state.tagPetFriendly}`}
                                  checked={this.state.tagPetFriendly}
                                  onChange={this.handleInputChange}
                                />
                                <div className="control-me" />
                              </div>
                            </div>
                            <div className="col-sm-12 col-md-4">
                              <div className="checkbox-wrap">
                                <label htmlFor="tagIndoor">
                                  Indoor
                                </label>
                                <input
                                  type="checkbox"
                                  id="tagIndoor"
                                  name="tagIndoor"
                                  className={`visually-hidden ${this.state.tagIndoor}`}
                                  checked={this.state.tagIndoor}
                                  onChange={this.handleInputChange}
                                />
                                <div className="control-me" />
                              </div>
                            </div>
                            <div className="col-sm-12 col-md-4">
                              <div className="checkbox-wrap">
                                <label htmlFor="tagOutdoor">
                                  Outdoor
                                </label>
                                <input
                                  type="checkbox"
                                  id="tagOutdoor"
                                  name="tagOutdoor"
                                  className={`visually-hidden ${this.state.tagOutdoor}`}
                                  checked={this.state.tagOutdoor}
                                  onChange={this.handleInputChange}
                                />
                                <div className="control-me" />
                              </div>
                            </div>
                            <div className="col-sm-12 col-md-4">
                              <div className="checkbox-wrap">
                                <label htmlFor="tagSeasonal">
                                  Seasonal
                                </label>
                                <input
                                  type="checkbox"
                                  id="tagSeasonal"
                                  name="tagSeasonal"
                                  className={`visually-hidden ${this.state.tagSeasonal}`}
                                  checked={this.state.tagSeasonal}
                                  onChange={this.handleInputChange}
                                />
                                <div className="control-me" />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-12">
                              <h3>Website and social links</h3>
                            </div>
                            <div className="col-sm-12 col-md-6">
                              <label>Website URL</label>
                              <input
                                type="text"
                                className="form-control"
                                name="postWebsite"
                                value={this.handleLinks(
                                  this.state.postWebsite,
                                )}
                                onChange={this.handleInputChange}
                              />
                            </div>
                            <div className="col-sm-12 col-md-6">
                              <label>Buy tickets URL</label>
                              <input
                                type="text"
                                className="form-control"
                                name="postTicketsURL"
                                value={this.handleLinks(
                                  this.state.postTicketsURL,
                                )}
                                onChange={this.handleInputChange}
                              />
                            </div>
                            {/* The following social links are only for premium listings */}
                            <div className="col-sm-12 col-md-6">
                              <label>Facebook URL</label>
                              <input
                                type="text"
                                className="form-control"
                                name="postFacebook"
                                value={this.handleLinks(
                                  this.state.postFacebook,
                                )}
                                onChange={this.handleInputChange}
                              />
                            </div>
                            <div className="col-sm-12 col-md-6">
                              <label>Twitter URL</label>
                              <input
                                type="text"
                                className="form-control"
                                name="postTwitter"
                                value={this.handleLinks(
                                  this.state.postTwitter,
                                )}
                                onChange={this.handleInputChange}
                              />
                            </div>
                            <div className="col-sm-12 col-md-6">
                              <label>Instagram URL</label>
                              <input
                                type="text"
                                className="form-control"
                                name="postInstagram"
                                value={this.handleLinks(
                                  this.state.postInstagram,
                                )}
                                onChange={this.handleInputChange}
                              />
                            </div>
                          </div>
                          <a
                            className="btn-purple mb-2"
                            onClick={this.ShowPricing}
                          >
                            Back
                          </a>
                          <div className="row">
                            {!docRef && (
                              <div className="col-12">
                                {this.state.lat && this.state.lng && (
                                  <a
                                    href
                                    className="btn draft-btn"
                                    onClick={(e) => {
                                      this.onPostSave('draft', false);
                                      e.preventDefault();
                                    }}
                                  >
                                    Save As Draft
                                  </a>
                                )}
                                {this.state.lat && this.state.lng && (
                                  <a
                                    href
                                    className="btn preview-btn"
                                    onClick={(e) => {
                                      this.onPostSave('draft', true);
                                      e.preventDefault();
                                    }}
                                  >
                                    Preview
                                  </a>
                                )}
                                {this.selectedType == 'event' &&
                                  this.state.lat &&
                                  this.state.lng &&
                                  this.state.postTitle &&
                                  this.state.pageDescription &&
                                  this.state.pageTitle &&
                                  this.state.postStartDate &&
                                  this.state.postEndDate &&
                                  this.state.postStartTime &&
                                  this.state.postEndTime && (
                                    <a
                                      href
                                      className="btn publish-btn"
                                      onClick={(e) => {
                                        this.onPostSave('live',false);
                                        e.preventDefault();
                                      }}
                                    >
                                      Publish Listing
                                    </a>
                                  )}
                                {this.selectedType != 'event' &&
                                  this.state.lat &&
                                  this.state.lng &&
                                  this.state.postTitle &&
                                  this.state.pageDescription &&
                                  this.state.pageTitle && (
                                    <a
                                      href
                                      className="btn publish-btn"
                                      onClick={(e) => {
                                       this.onPostSave('live', false);
                                        e.preventDefault();
                                      }}
                                    >
                                      Publish Listing
                                    </a>
                                  )}
                              </div>
                            )}
                            {docRef && (
                              <div className="col-12">
                                <a
                                  href
                                  className="btn"
                                  onClick={(e) => {
                                    this.props.history.push(
                                      ROUTES.MYLISTINGS,
                                    );
                                  }}
                                >
                                  My Listings
                                </a>
                              </div>
                            )}
                          </div>
                        </div>
                        {this.state.postTitle && (
                          <div className="col-sm-12 col-md-4 eblock-wrap sideBarWrap">
                            <Link
                              to="#"
                              className={`eblock postPreview ${activePostType}`}
                            >
                              <div className="eblock-img">
                                <div className="distance text-center d-none">
                                  {/* 2.6mi */}
                                </div>
                                {!this.state.thumb0 && (
                                  <img
                                    src={images.placeHolder}
                                    className="resp-img"
                                  />
                                )}
                                {this.state.thumb0 && (
                                  <img
                                    src={this.state.thumb0}
                                    className="resp-img"
                                  />
                                )}
                              </div>
                              <div className="eblock-dets">
                                {activePostType != 'attraction' && (
                                  <ul>
                                    {this.state.postStartDate && (
                                      <li className="date">
                                        {formatDate(
                                          this.state.postStartDate,
                                        )}
                                      </li>
                                    )}
                                    {this.state.postStartTime && (
                                      <li className="time">
                                        {this.state.postStartTime} -{' '}
                                        {this.state.postEndTime}
                                      </li>
                                    )}
                                  </ul>
                                )}
                                <h3>{this.state.postTitle}</h3>
                                <p>{this.state.addressLine}</p>
                                {/* {lat} - {lng} */}
                              </div>
                            </Link>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
        </AuthUserContext.Consumer>
      </>
    );
  };
}

export default withFirebase(AddListing);
