import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import images from '../../constants/images';
import {
  AuthUserContext,
  withAuthorization,
  withEmailVerification,
} from '../Session';
import { withFirebase } from '../Firebase';
import firebase from 'firebase';
import 'firebase/firestore';
import * as geofirestore from 'geofirestore';
import { formatDate } from '../../helpers/ui';
import './search-results.scss';
import Distance from '../UI/distance';
import Select from 'react-select';
import CardsLoader from '../ContentLoaders/cardsLoader';
import AddressAutoComplete from '../UI/AddressAutoCompleteSearch';

import UserFavourites from '../UI/wishList';
import UserInterested from '../UI/interested';

import Slider from 'react-rangeslider';
import 'react-rangeslider/lib/index.css'

import { getNumberOfDays } from '../../helpers/ui';

class SearchPage extends Component {
  static contextType = AuthUserContext;
  constructor(props) {
    super(props);
    this.state = {
      searchTerms: {
        proximity: '',
        area: '',
        desc: '',
      },
      limit: 20,
      totalResults: 0,
      currentLocation: {
        lat: 0.0,
        lng: 0.0,
        listings: [],
        loading: true,
      },
      days: 'Max',
      tagCouples: false,
      tagAdults: false,
      tagTeenagers: false,
      tagFamilies: false,
      tagChildren312: false,
      tagBabiesToddlers: false,
      tagPetFriendly: false,
      tagIndoor: false,
      tagOutdoor: false,
      tagSeasonal: false,
      typeEvent: false,
      typeAttraction: false,
      sidebarStatus: false,
    };
    this.onGetListings = this.onGetListings.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

	componentDidMount() {
		const dd = localStorage.getItem('days');
		// (dd == null || dd == 'all') ? this.setState({ days: '90' }) : this.setState({ days: dd });
    const searchTerms = queryString.parse(this.props.location.search);
    searchTerms.area
      ? localStorage.setItem('area', searchTerms.area)
		: localStorage.setItem('area', '');
	  searchTerms.days === 'all'
		  ? localStorage.setItem('days', '30') : '30';
		!searchTerms.days ? localStorage.setItem('days', '30') : '';
    const area_lat = Number(localStorage.getItem('lat'));
    const area_lng = Number(localStorage.getItem('lng'));

    //set the selected option based on the search term
    searchTerms.area === undefined ? (searchTerms.area = '') : '';
    searchTerms.proximity === undefined
      ? (searchTerms.proximity = 10)
      : '';
    this.setState({
      searchTerms: searchTerms,
      selectedID: searchTerms.proximity / 10 - 1,
	  days: localStorage.getItem('days'),
      options: [
        { value: '10', label: '10 Miles' },
        { value: '20', label: '20 Miles' },
        { value: '30', label: '30 Miles' },
        { value: '60', label: '60 Miles' },
      ],
    });
    if (area_lat > 0 || area_lng > 0) {
      this.onGetListings(area_lat, area_lng, searchTerms);
    } else {
      this.setState({ loading: true });
      if (navigator && navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((pos) => {
          const coords = pos.coords;
          searchTerms.area = 'of your current location';
          localStorage.setItem('area', searchTerms.area);
          this.onGetListings(
            coords.latitude,
            coords.longitude,
            searchTerms,
          );
        });
      }
    }
  }

  setListingasExpired(listing) {
    const db = firebase.firestore();
    const docRef = db.collection('listings').doc(listing);
    docRef.update({ postStatus: 'expired' });
  }

  onGetListings = (lat, lng, searchTerms) => {
    this.setState({ loading: true });
    const firestore = firebase.firestore();
    const GeoFirestore = geofirestore.initializeApp(firestore);
    const geocollection = GeoFirestore.collection('listings');
    // const = parseInt(searchTerms.proximity) * 1.60934;

    //   if (lat != undefined || lng != undefined) {
    var radius = searchTerms.proximity
      ? parseInt(searchTerms.proximity) * 1.60934
      : 0;
    // } else {
    // var radius = 60 * 1.60934;
    // searchTerms.area = 'London, UK';
    // }
    //   console.log(radius);

    //TODO: use userCurrentPotision() instead

    //   if (lat != undefined || lng != undefined) {
    // 	  //nothing
    // 	  console.log('edo');
    //   } else {
    // 	  if (navigator && navigator.geolocation) {
    // 		  navigator.geolocation.getCurrentPosition((pos) => {
    // 			  const coords = pos.coords;
    // 			   lat = coords.latitude;
    // 			   lng = coords.longitude;
    // 		  });
    // 		  console.log('lala');
    // 	  } else {
    // 		   lat = 51.509865;
    // 		  lng = -0.118092;
    // 		  console.log('koko');
    // 	  }
    //   }
    console.log(lat);

    const query = geocollection
      .near({
        center: new firebase.firestore.GeoPoint(lat, lng),
        radius: radius,
      })
      .limit(this.state.limit)
      .where('postStatus', '==', 'live');

    query.get().then((value) => {
      // All GeoDocument returned by GeoQuery, like the GeoDocument added above
      const sorted = value.docs.sort(
        (a, b) => a.distance - b.distance,
      );
      let listings = [];
      for (const doc of sorted) {
        // if (
        //   doc.data().postTitle.includes(searchTerms.area) ||
        //   doc.data().postAddressPostCode.includes(searchTerms.area) ||
        //   doc.data().postAddressCounty.includes(searchTerms.area) ||
        //   doc.data().postAddressTownCity.includes(searchTerms.area) ||
        //   doc.data().postAddressLine.includes(searchTerms.area)
        // ) {
        //   listings.push({ ...doc.data(), uid: doc.id });
        // }
        listings.push({ ...doc.data(), uid: doc.id });
      }
      this.setState({
        listings: listings,
        total: listings.length,
        eventsCounter: listings.filter((c) => c.postType === 'event')
          .length,
        attractionsCounter: listings.filter(
          (c) => c.postType === 'attraction',
        ).length,
        loading: false,
      });
      //   console.log(listings);
    });
  };

  countItems(listings, field, value) {
    let totalNumber = listings.filter(
      (listing) => listing[field] === value,
    ).length;
    return totalNumber;
  }

  handleInputChange(event) {
    const target = event.target;
    const value =
      target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    if (name == 'typeEvent') {
      //   alert(name);
    }
    this.setState({
      [name]: value,
    });
    console.log(name, value);
  }

  handleChangeStart = () => {
    console.log('Change event started');
  };

  handleChange = (days) => {
    this.setState({
      days: days,
	});
	localStorage.setItem('days', days);
  };

  handleChangeComplete = () => {
    console.log('Change event completed');
  };

  showSidebar = () => {
    this.state.sidebarStatus != true
      ? this.setState({ sidebarStatus: true })
      : this.setState({ sidebarStatus: false });
  };

  render() {
    const {
      totalResults,
      searchTerms,
      listings,
      total,
      selectedID,
      loading,
      sidebarStatus,
	  days,
    } = this.state;
    const authUser = this.context;
    const options = [
      { value: '10', label: '10 Miles' },
      { value: '20', label: '20 Miles' },
      { value: '30', label: '30 Miles' },
      { value: '60', label: '60 Miles' },
    ];
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? '#ddd' : '#1E082E',
        background: state.isSelected ? '#1E082E' : '#ddd',
        color: state.isFocused ? '#ddd' : '#1E082E',
        background: state.isFocused ? '#1E082E' : '#ddd',
      }),
      singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';
        return { ...provided, opacity, transition };
      },
    };
    return (
      <>
        <div className="fw search-res-wrap">
          <button
            className="trigger trigger-class btn"
            onClick={() => {
              this.showSidebar();
            }}
          >
            Refine Search
          </button>
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12 col-lg-9 offset-lg-3 search-res-title search-res-wrap text-center">
                {loading && <h4>Fetching listings..</h4>}
                {!loading && listings && (
                  <h4>
                    Your search returned{' '}
                    <span className="pink">{total}</span> results{' '}
                    <br />
                    within{' '}
                    <span className="pink">
                      {searchTerms.proximity} miles
                    </span>
                    {searchTerms.area !== '' ? (
                      <>
                        {' '}
                        of
                        <span className="pink">
                          {' '}
                          {searchTerms.area}
                        </span>
                      </>
                    ) : (
                      <>
                        {' '}
                        of your
                        <span className="pink">
                          {' current location'}
                        </span>
                      </>
                    )}
                  </h4>
                )}
                {total === 0 && !loading && (
                  <h3>Please refine your search</h3>
                )}
              </div>
            </div>
            <div className="row max-width">
              <div className="col-sm-12 col-lg-3">
                <div
                  className={`sidebar ${sidebarStatus ? `open` : ''}`}
                >
                  <div className="fw title">
                    <h4>Refine Search</h4>
                  </div>
                  <div className="fw refine-opt">
                    <form>
                      <div className="fw">
                        {/* <input
                          type="text"
                          defaultValue={searchTerms.area}
                          name="area"
                          className="form-control"
						></input> */}
                        <div class="fw sidebar-search">
                          <AddressAutoComplete
                            value={searchTerms.area}
                          />
                          {!loading && (
                            <Select
                              id="sidebar-search-select"
                              options={options}
                              defaultValue={options[selectedID]}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              name="proximity"
                              isSearchable={false}
                              styles={customStyles}
                            />
                          )}
                          {loading && (
                            <Select
                              id="sidebar-search-select"
                              options={options}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              name="proximity"
                              isSearchable={false}
                              styles={customStyles}
                            />
                          )}
                          <button className="btn" type="submit">
                            Update
                          </button>
                        </div>
                        {/* {selectedID ? (
                          <Select
                            id="sidebar-search-select"
                            options={options}
                            defaultValue={options[selectedID]}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            name="proximity"
                            isSearchable={false}
                            styles={customStyles}
                          />
                        ) : (
                          <Select
                            id="sidebar-search-select"
                            options={options}
                            defaultValue={options[0]}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            name="proximity"
                            isSearchable={false}
                            styles={customStyles}
                          />
                        )} */}
                        <div className="fw">
                          <div className="daysSlider">
                            <Slider
                              min={0}
                              max={100}
                              // value={localStorage.getItem('days') != null ? localStorage.getItem('days') : days}
                              value={days}
                              onChangeStart={this.handleChangeStart}
                              onChange={this.handleChange}
                              onChangeComplete={
                                this.handleChangeComplete
                              }
                            />
                            <div className="numDays">
                              Within the next {days} days
                            </div>
                          </div>{' '}
                        </div>
                        <ul>
                          <h5>Listing type</h5>
                          <li>
                            {listings && (
                              <p>
                                {this.countItems(
                                  listings,
                                  'postType',
                                  'event',
                                )}
                              </p>
                            )}
                            <input
                              className="styled-checkbox"
                              id="typeEvent"
                              name="typeEvent"
                              type="checkbox"
                              checked={this.state.typeEvent}
                              onChange={this.handleInputChange}
                            />
                            <label htmlFor="typeEvent">Event</label>
                          </li>
                          <li>
                            {listings && (
                              <p>
                                {this.countItems(
                                  listings,
                                  'postType',
                                  'attraction',
                                )}
                              </p>
                            )}
                            <input
                              className="styled-checkbox"
                              id="typeAttractiont"
                              name="typeAttractiont"
                              type="checkbox"
                              checked={this.state.typeAttraction}
                              onChange={this.handleInputChange}
                            />
                            <label htmlFor="typeAttraction">
                              Attraction
                            </label>
                          </li>
                        </ul>
                      </div>
                      <div className="fw">
                        <ul className="d-none">
                          <h5>When</h5>
                          <li>
                            <p>22</p>
                            <input
                              className="styled-checkbox"
                              id="typeAttraction"
                              name="typeAttraction"
                              type="checkbox"
                              checked={this.state.typeAttraction}
                              onChange={this.handleInputChange}
                            />
                            <label htmlFor="styled-checkbox-3">
                              Today
                            </label>
                          </li>
                          <li>
                            <p>6</p>
                            <input
                              className="styled-checkbox"
                              id="styled-checkbox-4"
                              type="checkbox"
                              defaultValue="value2"
                              defaultChecked
                            />
                            <label htmlFor="styled-checkbox-4">
                              Tomorrow
                            </label>
                          </li>
                          <li>
                            <p>12</p>
                            <input
                              className="styled-checkbox"
                              id="styled-checkbox-5"
                              type="checkbox"
                              defaultValue="value2"
                              defaultChecked
                            />
                            <label htmlFor="styled-checkbox-5">
                              This Weekend
                            </label>
                          </li>
                          <li>
                            <p>0</p>
                            <input
                              className="styled-checkbox"
                              id="styled-checkbox-6"
                              type="checkbox"
                              defaultValue="value2"
                            />
                            <label htmlFor="styled-checkbox-6">
                              This Week
                            </label>
                          </li>
                        </ul>
                      </div>
                      <div className="fw">
                        <ul>
                          <h5>Suitable for</h5>
                          <li className="">
                            {listings && (
                              <p>
                                {this.countItems(
                                  listings,
                                  'tagCouples',
                                  true,
                                )}
                              </p>
                            )}
                            <input
                              id="tagCouples"
                              type="checkbox"
                              className={`styled-checkbox ${this.state.tagCouples}`}
                              name="tagCouples"
                              checked={this.state.tagCouples}
                              onChange={this.handleInputChange}
                            />
                            <label htmlFor="tagCouples">
                              Couples
                            </label>
                          </li>
                          <li>
                            {listings && (
                              <p>
                                {this.countItems(
                                  listings,
                                  'tagAdults',
                                  true,
                                )}
                              </p>
                            )}
                            <input
                              id="tagAdults"
                              type="checkbox"
                              className={`styled-checkbox ${this.state.tagAdults}`}
                              name="tagAdults"
                              checked={this.state.tagAdults}
                              onChange={this.handleInputChange}
                            />
                            <label htmlFor="tagAdults">Adults</label>
                          </li>
                          <li>
                            {' '}
                            {listings && (
                              <p>
                                {this.countItems(
                                  listings,
                                  'tagTeenagers',
                                  true,
                                )}
                              </p>
                            )}
                            <input
                              id="tagTeenagers"
                              type="checkbox"
                              className={`styled-checkbox ${this.state.tagTeenagers}`}
                              name="tagTeenagers"
                              checked={this.state.tagTeenagers}
                              onChange={this.handleInputChange}
                            />
                            <label htmlFor="tagTeenagers">
                              Teenagers
                            </label>
                          </li>
                          <li>
                            {' '}
                            {listings && (
                              <p>
                                {this.countItems(
                                  listings,
                                  'tagFamilies',
                                  true,
                                )}
                              </p>
                            )}
                            <input
                              id="tagFamilies"
                              type="checkbox"
                              className={`styled-checkbox ${this.state.tagFamilies}`}
                              name="tagFamilies"
                              checked={this.state.tagFamilies}
                              onChange={this.handleInputChange}
                            />
                            <label htmlFor="tagFamilies">
                              Families
                            </label>
                          </li>
                          <li>
                            {' '}
                            {listings && (
                              <p>
                                {this.countItems(
                                  listings,
                                  'tagChildren312',
                                  true,
                                )}
                              </p>
                            )}
                            <input
                              id="tagChildren312"
                              type="checkbox"
                              className={`styled-checkbox ${this.state.tagChildren312}`}
                              name="tagChildren312"
                              checked={this.state.tagChildren312}
                              onChange={this.handleInputChange}
                            />
                            <label htmlFor="tagChildren312">
                              Children (3 to 12)
                            </label>
                          </li>
                          <li>
                            {listings && (
                              <p>
                                {this.countItems(
                                  listings,
                                  'tagBabiesToddlers',
                                  true,
                                )}
                              </p>
                            )}
                            <input
                              id="tagBabiesToddlers"
                              type="checkbox"
                              className={`styled-checkbox ${this.state.tagBabiesToddlers}`}
                              name="tagBabiesToddlers"
                              checked={this.state.tagBabiesToddlers}
                              onChange={this.handleInputChange}
                            />
                            <label htmlFor="tagBabiesToddlers">
                              Babies and Toddlers
                            </label>
                          </li>
                          <li>
                            {listings && (
                              <p>
                                {this.countItems(
                                  listings,
                                  'tagPetFriendly',
                                  true,
                                )}
                              </p>
                            )}
                            <input
                              id="tagPetFriendly"
                              type="checkbox"
                              className={`styled-checkbox ${this.state.tagPetFriendly}`}
                              name="tagPetFriendly"
                              checked={this.state.tagPetFriendly}
                              onChange={this.handleInputChange}
                            />
                            <label htmlFor="tagPetFriendly">
                              Dog Friendly
                            </label>
                          </li>
                        </ul>
                      </div>
                      <div className="fw">
                        <ul>
                          <h5>Suitable for</h5>
                          <li>
                            {listings && (
                              <p>
                                {this.countItems(
                                  listings,
                                  'tagIndoor',
                                  true,
                                )}
                              </p>
                            )}
                            <input
                              id="tagIndoor"
                              type="checkbox"
                              className={`styled-checkbox ${this.state.tagIndoor}`}
                              name="tagIndoor"
                              checked={this.state.tagIndoor}
                              onChange={this.handleInputChange}
                            />
                            <label htmlFor="tagIndoor">Indoor</label>
                          </li>
                          <li>
                            {listings && (
                              <p>
                                {this.countItems(
                                  listings,
                                  'tagOutdoor',
                                  true,
                                )}
                              </p>
                            )}
                            <input
                              id="tagOutdoor"
                              type="checkbox"
                              className={`styled-checkbox ${this.state.tagOutdoor}`}
                              name="tagOutdoor"
                              checked={this.state.tagOutdoor}
                              onChange={this.handleInputChange}
                            />
                            <label htmlFor="tagOutdoor">
                              Outdoor
                            </label>
                          </li>
                          <li>
                            {' '}
                            {listings && (
                              <p>
                                {this.countItems(
                                  listings,
                                  'tagSeasonal',
                                  true,
                                )}
                              </p>
                            )}
                            <input
                              id="tagSeasonal"
                              type="checkbox"
                              className={`styled-checkbox ${this.state.tagSeasonal}`}
                              name="tagSeasonal"
                              checked={this.state.tagSeasonal}
                              onChange={this.handleInputChange}
                            />
                            <label htmlFor="tagSeasonal">
                              Seasonal
                            </label>
                          </li>
                        </ul>
                      </div>
                    </form>
                  </div>
                  <div className="update-res">
                    <a
                      href="#"
                      className="btn trigger-class"
                      onClick={() => {
                        this.showSidebar();
                      }}
                    >
                      Update results
                    </a>
                  </div>
                </div>
              </div>
              {loading && !listings && (
                <div className="col-sm-12 col-lg-9 search-res-wrap sect-pad-bot">
                  <div className="row">
                    <div className="col-sm-12 col-md-4 eblock-wrap">
                      <CardsLoader />
                    </div>
                    <div className="col-sm-12 col-md-4 eblock-wrap">
                      <CardsLoader />
                    </div>
                    <div className="col-sm-12 col-md-4 eblock-wrap">
                      <CardsLoader />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12 col-md-4 eblock-wrap">
                      <CardsLoader />
                    </div>
                    <div className="col-sm-12 col-md-4 eblock-wrap">
                      <CardsLoader />
                    </div>
                    <div className="col-sm-12 col-md-4 eblock-wrap">
                      <CardsLoader />
                    </div>
                  </div>
                </div>
              )}
              {total > 0 && !loading && (
                <div className="col-sm-12 col-lg-9 search-res-wrap sect-pad-bot">
                  <div className="row">
                    {listings &&
                      listings.map((listing) =>
                        listing.postType === 'event' ? (
                          Date.parse(listing.postEndDate) >
                          Date.parse(new Date()) ? (
                            <>
                              {getNumberOfDays(
                                listing.postStartDate,
                                new Date(),
                              ) < days ? (
                                <div
                                  className={`col-sm-12 col-md-4 eblock-wrap searchResultsItem ${
                                    !this.state.tagCouples &&
                                    !this.state.tagAdults &&
                                    !this.state.tagTeenagers &&
                                    !this.state.tagFamilies &&
                                    !this.state.tagChildren312 &&
                                    !this.state.tagBabiesToddlers &&
                                    !this.state.tagPetFriendly &&
                                    !this.state.tagIndoor &&
                                    !this.state.tagOutdoor &&
                                    !this.state.tagSeasonal
                                      ? 'd-block '
                                      : 'd-none '
                                  }${listing.postType}${
                                    listing.tagCouples &&
                                    this.state.tagCouples
                                      ? ' tagCouples'
                                      : ''
                                  }${
                                    listing.tagAdults &&
                                    this.state.tagAdults
                                      ? ' tagAdults'
                                      : ''
                                  }${
                                    listing.tagTeenagers &&
                                    this.state.tagTeenagers
                                      ? ' tagTeenagers'
                                      : ''
                                  }${
                                    listing.tagFamilies &&
                                    this.state.tagFamilies
                                      ? ' tagFamilies'
                                      : ''
                                  }${
                                    listing.tagChildren312 &&
                                    this.state.tagChildren312
                                      ? ' tagChildren312'
                                      : ''
                                  }${
                                    listing.tagBabiesToddlers &&
                                    this.state.tagBabiesToddlers
                                      ? ' tagBabiesToddlers'
                                      : ''
                                  }${
                                    listing.tagPetFriendly &&
                                    this.state.tagPetFriendly
                                      ? ' tagPetFriendly'
                                      : ''
                                  }${
                                    listing.tagIndoor &&
                                    this.state.tagIndoor
                                      ? ' tagIndoor'
                                      : ''
                                  }${
                                    listing.tagOutdoor &&
                                    this.state.tagOutdoor
                                      ? ' tagOutdoor'
                                      : ''
                                  }${
                                    listing.tagSeasonal &&
                                    this.state.tagSeasonal
                                      ? ' tagSeasonal'
                                      : ''
                                  }${
                                    listing.postType == 'event'
                                      ? ' typeEvent'
                                      : ' typeAttraction'
                                  } ${
                                    this.state.typeEvent &&
                                    listing.postType != 'event'
                                      ? 'hideEvent'
                                      : ''
                                  } ${
                                    this.state.typeAttraction &&
                                    listing.postType != 'attraction'
                                      ? 'hideAttraction'
                                      : ''
                                  } ${getNumberOfDays(
                                    listing.postStartDate,
                                    new Date(),
                                  )}`}
                                >
                                  {authUser && (
                                    <UserInterested
                                      listing={listing.uid}
                                    />
                                  )}
                                  <Link
                                    to={`/${listing.postType}/?listing=${listing.uid}`}
                                    className="eblock"
                                  >
                                    <div className="eblock-img">
                                      <Distance
                                        listing={listing}
                                        type="blockListing"
                                      />
                                      {/* <div className="featured-tag">Featured</div> */}
                                      {/* <div className="distance text-center d-none">
                              2.6mi
                            </div> */}
                                      {listing.postThumbnails &&
                                        listing.postThumbnails[0] && (
                                          <img
                                            src={
                                              listing
                                                .postThumbnails[0]
                                            }
                                            className="resp-img"
                                          />
                                        )}
                                      {listing.postThumbnails &&
                                        !listing
                                          .postThumbnails[0] && (
                                          <img
                                            src={images.placeHolder}
                                            className="resp-img"
                                          />
                                        )}
                                      {/* <img
                                      src={listing.postThumbnails[0]}
                                      className="resp-img"
                                    /> */}
                                    </div>
                                    <div className="eblock-dets">
                                      {listing.postType ===
                                        'event' && (
                                        <ul>
                                          <li className="date">
                                            {formatDate(
                                              listing.postStartDate,
                                            ).slice(0, -5)}
                                          </li>
                                          <li
                                            className={`time ${
                                              listing.postStartTime
                                                ? ''
                                                : 'd-none'
                                            }`}
                                          >
                                            {listing.postStartTime &&
                                              listing.postStartTime}
                                            {listing.postEndTime &&
                                              `- ${listing.postEndTime}`}
                                          </li>
                                        </ul>
                                      )}
                                      <h3>{listing.postTitle}</h3>
                                      <p>
                                        {listing.postAddressTownCity}
                                        {`, ${listing.postAddressCounty}`}
                                      </p>
                                    </div>
                                  </Link>
                                </div>
                              ) : (
                                ''
                              )}
                            </>
                          ) : (
                            this.setListingasExpired(listing.uid)
                          )
                        ) : (
                          <>
                            <div
                              className={`col-sm-12 col-md-4 eblock-wrap searchResultsItem ${
                                !this.state.tagCouples &&
                                !this.state.tagAdults &&
                                !this.state.tagTeenagers &&
                                !this.state.tagFamilies &&
                                !this.state.tagChildren312 &&
                                !this.state.tagBabiesToddlers &&
                                !this.state.tagPetFriendly &&
                                !this.state.tagIndoor &&
                                !this.state.tagOutdoor &&
                                !this.state.tagSeasonal
                                  ? 'd-block '
                                  : 'd-none '
                              }${listing.postType}${
                                listing.tagCouples &&
                                this.state.tagCouples
                                  ? ' tagCouples'
                                  : ''
                              }${
                                listing.tagAdults &&
                                this.state.tagAdults
                                  ? ' tagAdults'
                                  : ''
                              }${
                                listing.tagTeenagers &&
                                this.state.tagTeenagers
                                  ? ' tagTeenagers'
                                  : ''
                              }${
                                listing.tagFamilies &&
                                this.state.tagFamilies
                                  ? ' tagFamilies'
                                  : ''
                              }${
                                listing.tagChildren312 &&
                                this.state.tagChildren312
                                  ? ' tagChildren312'
                                  : ''
                              }${
                                listing.tagBabiesToddlers &&
                                this.state.tagBabiesToddlers
                                  ? ' tagBabiesToddlers'
                                  : ''
                              }${
                                listing.tagPetFriendly &&
                                this.state.tagPetFriendly
                                  ? ' tagPetFriendly'
                                  : ''
                              }${
                                listing.tagIndoor &&
                                this.state.tagIndoor
                                  ? ' tagIndoor'
                                  : ''
                              }${
                                listing.tagOutdoor &&
                                this.state.tagOutdoor
                                  ? ' tagOutdoor'
                                  : ''
                              }${
                                listing.tagSeasonal &&
                                this.state.tagSeasonal
                                  ? ' tagSeasonal'
                                  : ''
                              }${
                                listing.postType == 'event'
                                  ? ' typeEvent'
                                  : ' typeAttraction'
                              } ${
                                this.state.typeEvent &&
                                listing.postType != 'event'
                                  ? 'hideEvent'
                                  : ''
                              } ${
                                this.state.typeAttraction &&
                                listing.postType != 'attraction'
                                  ? 'hideAttraction'
                                  : ''
                              }  ${
                                listing.postStartDate
                                  ? getNumberOfDays(
                                      listing.postStartDate,
                                      new Date(),
                                    )
                                  : 'not-expiring'
                              }`}
                            >
                              {authUser && (
                                <UserFavourites
                                  listing={listing.uid}
                                />
                              )}
                              <Link
                                to={`/${listing.postType}/?listing=${listing.uid}`}
                                className="eblock"
                              >
                                <div className="eblock-img">
                                  <Distance
                                    listing={listing}
                                    type="blockListing"
                                  />
                                  {/* <div className="featured-tag">Featured</div> */}
                                  {/* <div className="distance text-center d-none">
                              2.6mi
                            </div> */}
                                  {listing.postThumbnails &&
                                    listing.postThumbnails[0] && (
                                      <img
                                        src={
                                          listing.postThumbnails[0]
                                        }
                                        className="resp-img"
                                      />
                                    )}
                                  {listing.postThumbnails &&
                                    !listing.postThumbnails[0] && (
                                      <img
                                        src={images.placeHolder}
                                        className="resp-img"
                                      />
                                    )}
                                  {/* <img
                                    src={listing.postThumbnails[0]}
                                    className="resp-img"
                                  /> */}
                                </div>
                                <div className="eblock-dets">
                                  {listing.postType === 'event' && (
                                    <ul>
                                      <li className="date">
                                        {formatDate(
                                          listing.postStartDate,
                                        ).slice(0, -5)}
                                      </li>
                                      <li
                                        className={`time ${
                                          listing.postStartTime
                                            ? ''
                                            : 'd-none'
                                        }`}
                                      >
                                        {listing.postStartTime &&
                                          listing.postStartTime}
                                      </li>
                                    </ul>
                                  )}
                                  <h3>{listing.postTitle}</h3>
                                  <p>
                                    {listing.postAddressTownCity}
                                    {`, ${listing.postAddressCounty}`}
                                  </p>
                                </div>
                              </Link>
                            </div>{' '}
                          </>
                        ),
                      )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <style></style>
        <div
          dangerouslySetInnerHTML={{
            __html:
              '<style>.fw.footer.sect-pad-top.sect-pad-bot.lala {display: none!important;}</style>',
          }}
        ></div>
      </>
    );
  }
}

export default withFirebase(SearchPage);
