import { useEffect } from 'react';
import {
  atalhoTheme,
  useNotifications,
  setUpNotifications,
} from 'reapop';

setUpNotifications({
  defaultProps: {
    position: 'bottom-center',
    dismissible: true,
  },
});

export const NotifyUser = (props) => {
  const { notify } = useNotifications();
  useEffect(() => {
	  notify({
      title: props.title,
      message: props.message,
      status: props.status,
      dismissAfter: 4000,
    });
	
  }, []);
  return '';
};
