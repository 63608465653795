import React, { Component, useState } from 'react';
import { Link } from 'react-router-dom';
import emailjs from 'emailjs-com';

import {
  atalhoTheme,
  useNotifications,
  setUpNotifications,
} from 'reapop';

import './banner-inner.scss';
import './select-menu.scss';

function ContactPage() {
  const [formData, updateFormData] = React.useState();
  const { notify } = useNotifications();
  const handleChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value.trim(),
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(formData);
    let templateParams = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      enquiryType: formData.enquiryType,
      enquiryText: formData.enquiryText,
    };

    emailjs
      .send(
        'service_smt',
        'template_nkzcp9l',
        templateParams,
        'user_XzyVLl9ojVvIJBtcrr1F6',
      )
      .then(
        function (response) {
          // console.log('SUCCESS!', response.status, response.text);
          notify({
            title: 'Success',
            message: 'Your request has been sent successfully',
            status: 'success',
            dismissAfter: 4000,
          });
        },
        function (error) {
          console.log('FAILED...', error);
        },
      )
      .then(function (response) {
        {
          document.getElementById('contactForm').reset();
        }
      });
  };

  const enquiryTypes = [
    { value: 'pro-listing', label: 'Pro listing enquiry' },
    { value: 'general', label: 'General enquiry' },
    { value: 'account', label: 'My account' },
    { value: 'report-bug', label: 'Report a bug' },
    { value: 'report-listing', label: 'Report a listing' },
    { value: 'other', label: 'Other' },
  ];

  return (
    <>
      <div className="page-template-dashboard contactPage">
        <div className="fw banner-inner">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <h1>Contact us</h1>
              </div>
            </div>
          </div>
        </div>

        <div className="fw white-bg sect-pad-top sect-pad-bot">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-8">
                <form id="contactForm">
                  <h2>Enquiry form</h2>
                  <p>bla bla bla</p>
                  <input
                    type="text"
                    name="firstName"
                    className="form-control"
                    placeholder="First name"
                    onChange={handleChange}
                  />
                  <input
                    type="text"
                    name="lastName"
                    className="form-control"
                    placeholder="Last name"
                    onChange={handleChange}
                  />
                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    placeholder="Email address"
                    onChange={handleChange}
                  />
                  <select
                    id="enquiryOptions"
                    name="enquiryType"
                    className="basic-multi-select"
                    onChange={handleChange}
                  >
                    <option>Enquiry type...</option>
                    {Object.values(enquiryTypes).map((key, index) => (
                      <option value={key['value']}>
                        {key['label']}
                      </option>
                    ))}
                  </select>
                  <textarea
                    name="enquiryText"
                    className="form-control"
                    placeholder="Enquiry..."
                    onChange={handleChange}
                  ></textarea>
                  <button className="btn" onClick={handleSubmit}>
                    Submit
                  </button>
                </form>
              </div>

              <div className="col-sm-12 col-md-4"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactPage;
