import React from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { Carousel } from 'react-bootstrap';
import AddressAutoComplete from '../UI/AddressAutoComplete';

import * as ROUTES from '../../constants/routes';
import images from '../../constants/images';

import './home-banner.scss';
import './select-menu.scss';
const options = [
  { value: '10', label: '10 Miles' },
  { value: '20', label: '20 Miles' },
  { value: '30', label: '30 Miles' },
  { value: '60', label: '60 Miles' },
];

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? '#ddd' : '#1E082E',
    background: state.isSelected ? '#1E082E' : '#ddd',
    color: state.isFocused ? '#ddd' : '#1E082E',
	background: state.isFocused ? '#1E082E' : '#ddd',
	fontSize: '18px',
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';
    return { ...provided, opacity, transition };
  },
};

const HeroCarousel = () => (
  <>
    {/* CAROUSEL ---------------------------------------------------------------*/}
    <div
      id="home-carousel"
      className="carousel slide carousel-fade"
      data-ride="carousel"
      data-interval={3000}
    >
      <div className="cara-overlay text-center d-flex align-items-center flex-column justify-content-center">
        <div className="pattern1" />
        <div className="pattern2" />
        <div className="container">
          <div className="row">
            <div className="col">
              <h1>Find things 'togodo' near you!</h1>
              <div className="search-wrap text-center">
                <form action="/search" method="GET">
                  {/* <input
                    type="text"
                    className="main-search-input"
                    placeholder="Postcode, Town or County"
                    name="area"
                  /> */}
				<AddressAutoComplete/>
                  <Select
                    id="main-search-select"
                    options={options}
                    defaultValue={options[0]}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    name="proximity"
					isSearchable={false}
					styles={customStyles}
									/>
					<input class="d-none" type="text" name="days" value="all"/>
                  <button type="submit" className="btn">
                    Search
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Carousel
        className="carousel-inner"
        controls={false}
        indicators={false}
      >
        <Carousel.Item
          className="carousel-item"
          style={{ backgroundImage: `url(${images.banner2})` }}
        />
        <Carousel.Item
          className="carousel-item"
          style={{ backgroundImage: `url(${images.banner3})` }}
        />
        <Carousel.Item
          className="carousel-item"
          style={{ backgroundImage: `url(${images.banner4})` }}
        />
      </Carousel>
    </div>
  </>
);
export default HeroCarousel;
