import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import { AuthUserContext } from '../Session';
import SignOutButton from '../SignOut';
import SignIn from '../SignIn';
import SignUpForm from '../SignUp';
import PasswordForgetLink from '../PasswordForget';
import Select from 'react-select';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';
import images from '../../constants/images';
import { splitName } from '../../helpers/users';

import AddressAutoComplete from '../UI/AddressAutoComplete';


import './header.scss';
import './select-menu.scss';

const options = [
  { value: '10', label: '10 Miles' },
  { value: '20', label: '20 Miles' },
  { value: '30', label: '30 Miles' },
  { value: '60', label: '60 Miles' },
];

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? '#ddd' : '#1E082E',
    background: state.isSelected ? '#1E082E' : '#ddd',
    color: state.isFocused ? '#ddd' : '#1E082E',
    background: state.isFocused ? '#1E082E' : '#ddd',
    fontSize: '18px',
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';
    return { ...provided, opacity, transition };
  },
};

function LoginRegister(props) {
  return (
    <Modal
      {...props}
      id="logModal"
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <SignIn />
        <SignUpForm />
      </Modal.Body>
    </Modal>
  );
}

const NavigationAuth = ({ authUser }) => (
  <ul>
    <li>
      <Link to={ROUTES.LANDING}>Landing</Link>
    </li>
    <li>
      <Link to={ROUTES.HOME}>Home</Link>
    </li>
    <li>
      <Link to={ROUTES.ACCOUNT}>Account</Link>
    </li>
    {!!authUser.roles[ROLES.ADMIN] && (
      <li>
        <Link to={ROUTES.ADMIN}>Admin</Link>
      </li>
    )}
    <li>
      <SignOutButton />
    </li>
  </ul>
);

const NavigationNonAuth = () => (
  <ul>
    <li>
      <Link to={ROUTES.LANDING}>Landing</Link>
    </li>
    <li>
      <Link to={ROUTES.SIGN_IN}>Sign In</Link>
    </li>
  </ul>
);

function Navigation() {
  const [toggleState, setToggleState] = useState('is-not-active');
  const [toggleSearch, setToggleSearch] = useState('inactive');
  const [modalShow, setModalShow] = useState(false);

  function toggle() {
    setToggleState(
      toggleState === 'is-not-active' ? 'is-active' : 'is-not-active',
    );
  }

  function SearchToggle() {
    setToggleSearch(
      toggleSearch === 'inactive' ? 'active' : 'inactive',
    );
  }

  return (
    <>
      {/* <div className={`switch ${toggleState}`} onClick={toggle} /> */}
      <LoginRegister
        show={modalShow}
        onHide={() => setModalShow(false)}
      />

      {/* Inner Search */}
      <div className={`inner-search ${toggleSearch}`}>
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="search-wrap text-center">
                <form action="/search" method="GET">
                  {/* <input
                    type="text"
                    className="main-search-input"
                    placeholder="Postcode, Town or County"
                    name="area"
                  /> */}
                  <AddressAutoComplete />
                  <Select
                    id="main-search-select"
                    options={options}
                    defaultValue={options[0]}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    name="proximity"
                    isSearchable={false}
                    styles={customStyles}
                  />
                  <input
                    class="d-none"
                    type="text"
                    name="days"
                    value="all"
                  />
                  <button type="submit" className="btn">
                    Search
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <AuthUserContext.Consumer>
        {(authUser) =>
          authUser
            ? <NavigationAuth authUser={authUser} /> && (
                <div className="nav">
                  <div className="search" onClick={SearchToggle} />
                  <Link to={ROUTES.LANDING}>
                    <img src={images.logo} className="logo" />
                  </Link>
                  {/* <Link to={ROUTES.MYLISTINGS} className="btn">
                    {authUser.username}
                  </Link> */}
                  <Link to={ROUTES.MYLISTINGS} className="logged-in">
                    <div
                      className="profile-img"
                      style={{
                        backgroundImage: `url(${authUser.profileImage})`,
                      }}
                    />
                    <p>{splitName(authUser.username, 0)}</p>
                  </Link>

                  <button
                    className={`hamburger hamburger--spin ${toggleState}`}
                    onClick={toggle}
                    type="button"
                  >
                    <span className="hamburger-box">
                      <span className="hamburger-inner" />
                    </span>
                  </button>
                  <div className={`nav-panel ${toggleState}`}>
                    <div className={`nav-panel-flex ${toggleState}`}>
                      <ul>
                        <li>
                          <Link
                            to={ROUTES.MYLISTINGS}
                            onClick={toggle}
                          >
                            My dashboard
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={ROUTES.ADDLISTING}
                            onClick={toggle}
                          >
                            Add listing
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={ROUTES.ABOUTPAGE}
                            onClick={toggle}
                          >
                            About us
                          </Link>
                        </li>
                        <li>
                          <Link to={ROUTES.FAQPAGE} onClick={toggle}>
                            FAQs
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={ROUTES.CONTACTPAGE}
                            onClick={toggle}
                          >
                            Contact us
                          </Link>
                        </li>
                        {!!authUser.roles[ROLES.ADMIN] && (
                          <li>
                            <Link to={ROUTES.ADMIN} onClick={toggle}>
                              Admin
                            </Link>
                          </li>
                        )}
                        <li className="nav-signout">
                          <SignOutButton classes="btn" />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              )
            : <NavigationNonAuth /> && (
                <>
                  <div className="nav">
                    <div className="search" onClick={SearchToggle} />
                    <Link to={ROUTES.LANDING}>
                      <img src={images.logo} className="logo" />
                    </Link>
                    <a
                      href="#"
                      className="btn setModalShow"
                      onClick={() => {
                        setModalShow(true);
                      }}
                    >
                      Login / Signup
                    </a>
                    <button
                      className={`hamburger hamburger--spin ${toggleState}`}
                      onClick={toggle}
                      type="button"
                    >
                      <span className="hamburger-box">
                        <span className="hamburger-inner" />
                      </span>
                    </button>
                    <div className={`nav-panel ${toggleState}`}>
                      <div
                        className={`nav-panel-flex ${toggleState}`}
                      >
                        <ul>
                          <li>
                            <Link
                              to={ROUTES.ADDLISTING}
                              onClick={toggle}
                            >
                              Add listing
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={ROUTES.ABOUTPAGE}
                              onClick={toggle}
                            >
                              About us
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={ROUTES.FAQPAGE}
                              onClick={toggle}
                            >
                              FAQs
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={ROUTES.CONTACTPAGE}
                              onClick={toggle}
                            >
                              Contact us
                            </Link>
                          </li>
                          <li className="nav-signin">
                            <a
                              href="#"
                              className="btn setModalShow"
                              onClick={() => {
                                setModalShow(true);
                              }}
                            >
                              Login / Signup
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </>
              )
        }
      </AuthUserContext.Consumer>
    </>
  );
}

export default Navigation;
