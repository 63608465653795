import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';

import './dashboard-sub-nav.scss';

function DashboardSubNav() {
  const location = useLocation();
  return (
    <>
      {' '}
      <div className="row">
        <div className="col-sm-12 text-center">
          <ul className="account-nav">
            <li
              className={
                ROUTES.MYLISTINGS == location.pathname ? 'active' : ''
              }
            >
              <Link to={ROUTES.MYLISTINGS}>My listings</Link>
            </li>
            <li
              className={
                ROUTES.MYFAVOURITES == location.pathname
                  ? 'active'
                  : ''
              }
            >
              <Link to={ROUTES.MYFAVOURITES}>My favourites</Link>
            </li>

            <li
              className={
                ROUTES.MYINTERESTS == location.pathname
                  ? 'active'
                  : ''
              }
            >
              <Link to={ROUTES.MYINTERESTS}>My interests</Link>
            </li>

            <li
              className={
                ROUTES.DASHBOARD == location.pathname ? 'active' : ''
              }
            >
              <Link to={ROUTES.DASHBOARD}>Account settings</Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default DashboardSubNav;
