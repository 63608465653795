import React, { useState, useEffect } from 'react';
import GoogleMapReact from 'google-map-react';
import images from '../../constants/images';

function TheMap(props) {
  const [cordinates, setCordinates] = useState(props);
  const MapMarker = ({ text }) => (
    <img
      src={images.mapMarker}
      style={{ height: '40px', width: 'auto' , marginLeft: '-20px', marginTop: '-20px'}}
    />
  );
  useEffect(() => {
    setCordinates(props);
  }, [props]);

  return (
    <div
      id="listingMapContainer"
      className="mb-3"
      style={{ height: '400px', width: '100%' }}
    >
      <GoogleMapReact
        yesIWantToUseGoogleMapApiInternals
        bootstrapURLKeys={{
          key: process.env.REACT_APP_GOOGLE_MAPS_KEY,
        }}
        defaultZoom={13}
        center={cordinates.center}
      >
        <MapMarker
          style={{ height: '50px', width: '50px' }}
          lat={cordinates.center[0]}
          lng={cordinates.center[1]}
        />
      </GoogleMapReact>
    </div>
  );
}

export default TheMap;
