function reqIMG(r) {
  return require(`../images/${r}`).default;
}
const images = {
  logo: reqIMG('togodo-logo.svg'),
  banner2: reqIMG('banner2.jpg'),
  banner3: reqIMG('banner3.jpg'),
  banner4: reqIMG('banner4.jpg'),
  profile: reqIMG('profile-image.jpg'),
  plusIcon: reqIMG('plus-icon.svg'),
  listing1: reqIMG('listing1.jpg'),
  listing2: reqIMG('listing2.jpg'),
  eventIcon: reqIMG('event-icon.svg'),
  attractionIcon: reqIMG('attraction-icon.svg'),
  smallMod2: reqIMG('small-mod2.jpg'),
  mapMarker: reqIMG('mapMarker.svg'),
  mapMarkerAttraction: reqIMG('mapMarkerAttraction.svg'),
  placeHolder: reqIMG('fallback-thumb.png'),
  view: reqIMG('view-icon.svg'),
  trash: reqIMG('trash-icon.svg'),
  edit: reqIMG('edit-icon.svg'),
  noprofileimage: reqIMG('default-avatar.jpg'),
};

export default images;
