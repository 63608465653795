import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import images from '../../constants/images';
import * as ROUTES from '../../constants/routes';

import './banner-inner.scss';

class AboutPage extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {}

  render = () => {
    return (
      <>
		<div className="page-template-dashboard aboutPage">

    <div className="fw banner-inner">
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <h1>About us</h1>
          </div>
        </div>
      </div>
    </div>

    <div className="fw white-bg sect-pad-top sect-pad-bot">
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <h2>Content</h2>
          </div>
        </div>
      </div>
    </div>

    </div>
      </>
    );
  };
}

export default AboutPage;
