import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import images from '../../constants/images';
import * as ROUTES from '../../constants/routes';

import './banner-inner.scss';

class PrivacyTerms extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {}

  render = () => {
    return (
      <>

        <div className="fw banner-inner">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <h1>Privacy policy</h1>
              </div>
            </div>
          </div>
        </div>

        <div className="fw white-bg">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 sect-pad-top sect-pad-bot">
              <h2>Privacy policy</h2>
              </div>
            </div>
          </div>
        </div>

      </>
    );
  };
}

export default PrivacyTerms;
