import React, { Component } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import { InterestedSvg } from './interestedSVG';
import firebase from 'firebase/app';
import { NotifyUser } from '../UI/theNotification';

import {
  AuthUserContext,
  withAuthorization,
  withEmailVerification,
} from '../Session';

import './wishList.scss';
export class UserInterests extends Component {
  static contextType = AuthUserContext;
  constructor(props) {
    super(props);

    this.state = {
      userID: '',
	  active: false,
	  notify: false,
	  remove: false,
    };
  }

  componentDidMount() {
    const authUser = this.context;
    const listing = this.props.listing;

    this.props.firebase
      .user(authUser.uid)
      .get()
      .then((doc) => {
		  if (doc.exists) {
			  const userInterests = doc.data().interests;
			  if (userInterests) {
				  let active = userInterests.includes(listing);
				  active
					  ? this.setState({ active: true })
					  : this.setState({ active: false });
			  }
		  }
      });
  }

  onToggleFavourite = (status) => {
    const authUser = this.context;
    const listing = this.props.listing;
    let active = this.state.active;
    active
      ? this.setState(
          {
            ...this.state,
			active: false,
			notify: false,
			removed: true,
          },
          () => {
            let status = this.state.active;
            this.updateuserInterests(authUser.uid, listing, status);
          },
        )
      : this.setState(
          {
            ...this.state,
			active: true,
			notify: true,
			removed: false,
          },
          () => {
            let status = this.state.active;
            this.updateuserInterests(authUser.uid, listing, status);
          },
		);
	  
	  	const db = firebase.firestore();	  
	      active
          ? this.setState(
              {
                ...this.state,
                active: false,
                notify: false,
                removed: true,
              },
              () => {
      			 const increment =
               firebase.firestore.FieldValue.increment(-1);
             const docRef = db.collection('listings').doc(listing);
             docRef.update({ usersInteresed: increment });
              },
            )
          : this.setState(
              {
                ...this.state,
                active: true,
                notify: true,
                removed: false,
              },
              () => {
                const increment =
                  firebase.firestore.FieldValue.increment(1);
				  const docRef = db
					  .collection('listings')
					  .doc(listing);
                docRef.update({ usersInteresed: increment });
              },
			  );
	  

  };

  updateuserInterests = (user, listing, status) => {
    let userRef = this.props.firebase.user(user);
    if (status === true) {
      userRef.update({
        interests: firebase.firestore.FieldValue.arrayUnion(listing),
      });
    } else if (status === false) {
      userRef.update({
        interests:
          firebase.firestore.FieldValue.arrayRemove(listing),
      });
    }
  };

  render() {
    const { active } = this.state;
    return (
      <AuthUserContext.Consumer>
        {(authUser) =>
          authUser ? (
            <div
              className={active ? 'favbtn animate active' : 'favbtn'}
              onClick={() =>
                this.onToggleFavourite(this.state.active)
              }
            >
              <InterestedSvg />
              {this.state.notify && (
                <NotifyUser
                  title="Listing added to interests!"
                  message="Find your interests under your dashboard"
                  status="success"
                />
              )}
              {this.state.removed && (
                <NotifyUser
                  title="Success"
                  message="Removed from interests"
                  status="success"
                />
              )}
            </div>
          ) : (
            ''
          )
        }
      </AuthUserContext.Consumer>
    );
  }
}

const condition = (authUser) => !!authUser;

export default compose(
  withFirebase,
//   withEmailVerification,
  withAuthorization(condition),
)(UserInterests);
