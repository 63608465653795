import React from 'react';
import ReactDOM from 'react-dom';

// import * as Sentry from '@sentry/react';
// import { Integrations } from '@sentry/tracing';

import './styles/index.css';
// import * as serviceWorker from './serviceWorker.js_';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import App from './components/App';
import Firebase, { FirebaseContext } from './components/Firebase';

ReactDOM.render(
  <FirebaseContext.Provider value={new Firebase()}>
    <App />
  </FirebaseContext.Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

//disable all console output when deployed on netlify
if (process.env.NETLIFY) {
	console.log = console.warn = console.error = () => { };
}