import React from 'react';
import NotificationsSystem, {
  atalhoTheme,
  useNotifications,
} from 'reapop';

const NotiFicationsContainer = () => {
  const { notifications, dismissNotification } = useNotifications();
  return (
    <div>
      <NotificationsSystem
        notifications={notifications}
        dismissNotification={(id) => dismissNotification(id)}
        theme={atalhoTheme}
      />
    </div>
  );
};


export default NotiFicationsContainer