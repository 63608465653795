export const LANDING = '/';
export const HOME = '/home';

export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const PASSWORD_FORGET = '/pw-forget';
export const ACCOUNT = '/account';

export const ADMIN = '/admin';
export const ADMIN_DETAILS = '/admin/:id';

export const DASHBOARD = '/dashboard';
export const MYLISTINGS = '/my-listings';
export const MYFAVOURITES = '/my-favourites';
export const MYINTERESTS = '/my-interests';
export const ADDLISTING = '/add-listing';
export const EDITLISTING = '/edit-listing';

export const EVENT = '/event';
export const ATTRACTION = '/attraction';

export const SEARCH = '/search';

export const FAQPAGE = '/faq';
export const CONTACTPAGE = '/contact';
export const ABOUTPAGE = '/about';
export const CLAIMPAGE = '/claim';
export const PRIVACYPAGE = '/privacy';
export const TERMSPAGE = '/terms';

export const CRONALL = '/cronall';
