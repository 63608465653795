import React, { Component, Fragment } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import UploadAdapter from './uploadAdapter';

class TextEditor extends Component {
  render() {
    const { value, onChange } = this.props;

    const custom_config = {
      extraPlugins: [FirebaseUploader],
      toolbar: {
        items: [
          'heading',
          '|',
          'bold',
          'italic',
          'link',
          'bulletedList',
          'numberedList',
          '|',
          'blockQuote',
          'insertTable',
          '|',
          'imageUpload',
		  'mediaEmbed',
		  '|',
          'undo',
          'redo',
        ],
      },
      table: {
        contentToolbar: [
          'tableColumn',
          'tableRow',
          'mergeTableCells',
        ],
      },
    };

    return (
      <CKEditor
        required
        editor={ClassicEditor}
        config={custom_config}
        data={value}
        onChange={(event, editor) => {
          const data = editor.getData();
		  //TODO: maybe rename the parent function ?
		  this.props.functionCallFromParent(data);
        }}
      />
    );
  }
}

function FirebaseUploader(editor) {
  editor.plugins.get('FileRepository').createUploadAdapter = (
    loader,
  ) => {
    return new UploadAdapter(loader, '1111');
  };
}

export default TextEditor;
