import React, { Component } from 'react';
import HeroCarousel from '../HeroCarousel';
import FeaturedListings from '../FeaturedListings';
import About from '../About';
import SignupSection from '../SignupSection';
import images from '../../constants/images';
import * as ROUTES from '../../constants/routes';



class Landing extends Component {
  constructor(props) {
    super(props);
  }
	
	componentDidMount() {
		if (localStorage.getItem('selectedType')) {
			localStorage.setItem('selectedType', '');
			this.props.history.push(ROUTES.ADDLISTING);
		}
	}

  render() {
    return (
      <div>
        <HeroCarousel />
        <FeaturedListings />
        <About />
        <SignupSection />
      </div>
    );
  }
}

// const Landing = () => (
//   <>
//     <div>
//       <HeroCarousel />
//       <FeaturedListings />
//       <About />
//       <SignupSection />
//     </div>
//   </>
// );

const CommingSoon = () => (
  <>
    <div
      className="purple-bg d-flex align-items-center justify-content-center flex-column"
      style={{
        height: '100vh',
        backgroundImage: `url(${images.banner3})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'right center',
      }}
    >
      <img
        src={images.logo}
        style={{
          width: 280,
          height: 'auto',
          marginBottom: 30,
          textShadow: '0 0 20px 0 rgba(0,0,0,0.5)',
        }}
      />
      <h2 style={{ textShadow: '0px 0px 10px rgba(0,0,0,0.6)' }}>
        Things to do near you... coming soon
      </h2>
    </div>
  </>
);

export default Landing;
// export default CommingSoon;
