import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import { AuthUserContext } from '../Session';
import { formatDate } from '../../helpers/ui';
import firebase from 'firebase/app';
import emailjs from 'emailjs-com';

// import './featuredListings.scss';
class CronAll extends Component {
  static contextType = AuthUserContext;
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      listings: [],
    };
  }
  componentDidMount() {
    this.onListenForFeaturedListings();
  }

  setListingasExpired(listing) {
    const db = firebase.firestore();
    const docRef = db.collection('listings').doc(listing);
    docRef.update({ postStatus: 'expired' });
  }

  notifyUser(owner, listingTitle) {
    this.unsubscribe = firebase
      .firestore()
      .collection('users')
      .doc(owner)
      .get()
      .then((doc) => {
        let templateParams = {
          email: doc.data().email,
          name: doc.data().username,
          listingTitle: listingTitle,
          text: 'your listing is about to expire',
        };
        emailjs
          .send(
            'service_smt',
            'template_uy80blu',
            templateParams,
            'user_XzyVLl9ojVvIJBtcrr1F6',
          )
          .then(
            function (response) {
              console.log('SUCCESS!', response.status, response.text);
            },
            function (error) {
              console.log('FAILED...', error);
            },
          );
      });
  }

  onListenForFeaturedListings = () => {
    this.setState({ loading: true });
    this.unsubscribe = this.props.firebase
      .listings()
      .where('postStatus', '==', 'live')
      .orderBy('createdAt', 'desc')
      .onSnapshot((snapshot) => {
        if (snapshot.size) {
          let listings = [];
          snapshot.forEach((doc) =>
            listings.push({ ...doc.data(), uid: doc.id }),
          );

          this.setState({
            listings: listings,
            total: listings.length,
            loading: false,
          });
        } else {
          this.setState({ listings: null, loading: false });
        }
      });
  };

  render = () => {
    const { listings, loading } = this.state;
    return (
      <>
        <div className="fw grad4 sect-pad-top sect-pad-bot">
          <div className="container">
            <div className="row block-titles">
              <div className="col-7">
                {!loading && <h2>All Listings</h2>}
                {loading && <h2>Loading Listings...</h2>}
              </div>
            </div>
            <div className="row mt-3">
              {!listings && <h1>There are no listings ...</h1>}
              {listings.map((listing) =>
                listing.postType === 'event' ? (
                  Date.parse(listing.postEndDate) >
                  Date.parse(new Date()) ? (
                    <>
                      <div className="col-sm-12 col-md-4 eblock-wrap">
                        <Link
                          to={`/${listing.postType}/?listing=${listing.uid}`}
                          className="eblock"
                        >
                          <div className="eblock-img">
                            {/* <div className="featured-tag">Featured</div> */}
                            {/* <Distance
                              listing={listing}
                              type="blockListing"
                            /> */}
                            {listing.postThumbnails[0] && (
                              <img
                                src={listing.postThumbnails[0]}
                                className="resp-img"
                              />
                            )}
                            {/* <img
                                    src={listing.postThumbnails[0]}
                                    className="resp-img"
                                  /> */}
                          </div>
                          <div className="eblock-dets">
                            <ul>
                              <li className="date">
                                {formatDate(
                                  listing.postStartDate,
                                ).slice(0, -5)}
                              </li>
                              {/* {listing.postTime && ( */}
                              <li className="time">14:00 - 15:00</li>
                              {/* )} */}
                            </ul>
                            <h3>{listing.postTitle}</h3>
                            {this.state.owner &&
                              this.state.owner.email}
                            <p>
                              {listing.postAddressTownCity}
                              {`, ${listing.postAddressCounty}`}
                            </p>
                          </div>
                        </Link>
                      </div>
                    </>
					  ) : (
							  this.setListingasExpired(listing.uid),
							  this.notifyUser( listing.owner, listing.postTitle)
					  
                  )
                ) : (
                  <>
                    <div className="col-sm-12 col-md-4 eblock-wrap">
                      <Link
                        to={`/${listing.postType}/?listing=${listing.uid}`}
                        className="eblock"
                      >
                        <div className="eblock-img">
                          {/* <div className="featured-tag">Featured</div> */}
                          {/* <Distance
                              listing={listing}
                              type="blockListing"
                            /> */}
                          {listing.postThumbnails[0] && (
                            <img
                              src={listing.postThumbnails[0]}
                              className="resp-img"
                            />
                          )}
                          {/* <img
                                  src={listing.postThumbnails[0]}
                                  className="resp-img"
                                /> */}
                        </div>
                        <div className="eblock-dets">
                          <ul>
                            <li className="date">
                              {formatDate(
                                listing.postStartDate,
                              ).slice(0, -5)}
                            </li>
                            {/* {listing.postTime && ( */}
                            <li className="time">14:00 - 15:00</li>
                            {/* )} */}
                          </ul>
                          <h3>{listing.postTitle}</h3>
                          <p>
                            {listing.postAddressTownCity}
                            {`, ${listing.postAddressCounty}`}
                          </p>
                        </div>
                      </Link>
                    </div>
                  </>
                ),
              )}
            </div>
          </div>
        </div>
      </>
    );
  };
}

export default compose(withFirebase)(CronAll);
