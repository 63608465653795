import { compose } from 'recompose';
import usePlacesAutocomplete, {
  getGeocode,
  getDetails,
  getZipCode,
  getLatLng,
} from 'use-places-autocomplete';
import useOnclickOutside from 'react-cool-onclickoutside';

import {
  AuthUserContext,
  withAuthorization,
  withEmailVerification,
} from '../Session';

import { withFirebase } from '../Firebase';
import firebase from 'firebase';
import 'firebase/firestore';

const PlacesAutocomplete = () => {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
      componentRestrictions: { country: ['uk'] },
    },
    debounce: 300,
    cache: 24 * 60 * 60,
  });
  const ref = useOnclickOutside(() => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions();
  });

  const handleInput = (e) => {
    // Update the keyword of the input element
    setValue(e.target.value);
  };

  const handleSelect =
    ({ description }) =>
    () => {
      // When user selects a place, we can replace the keyword without request data from API
      // by setting the second parameter to "false"
      setValue(description, false);
      clearSuggestions();

      // Get latitude and longitude via utility functions
      getGeocode({ address: description })
        .then((results) => getLatLng(results[0]))
        .then(({ lat, lng }) => {
          console.log('📍 Coordinates: ', { lat, lng });
          localStorage.setItem('lat', lat);
          localStorage.setItem('lng', lng);
        })
        .catch((error) => {
          console.log('😱 Error: ', error);
        });

      // Get latitude and longitude via utility functions
      getGeocode({ address: description })
        .then((results) =>
          console.log(
            results[0]['address_components'],
            results[0]['address_components'][0]['long_name'],
            results[0]['address_components'][1]['long_name'],
            results[0]['address_components'][2]['long_name'],
            results[0]['address_components'][3]['long_name'],
            results[0]['address_components'][4]['long_name'],
          ),
        )
        .catch((error) => {
          console.log('😱 Error: ', error);
        });

      getGeocode({ address: description })
        .then((results) => getZipCode(results[0], false))
        .then((zipCode) => {
          console.log('ZIP Code: ', zipCode);
        })
        .catch((error) => {
          console.log('😱 Error: ', error);
        });

      getDetails({ address: description })
        .then((details) => {
          console.log('Details: ', details);
        })
        .catch((error) => {
          console.log('Error: ', error);
        });
    };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <li key={place_id} onClick={handleSelect(suggestion)}>
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </li>
      );
    });
  const area = localStorage.getItem('area');
  return (
    <>
      <AuthUserContext.Consumer>
        {(authUser) =>
          authUser ? (
            <>
              {onListenForLocation(authUser)}
              <input
                id="UserTownCountry"
                value={value}
                onChange={handleInput}
                disabled={!ready}
                // defaultValue={area}
                placeholder={
                  localStorage.getItem('TownCountry')
                    ? localStorage.getItem('TownCountry')
                    : 'Postcode, Town or County'
                }
                autocomplete="off"
                className="form-control main-search-input mb-0"
                name="area"
                defaultValue={localStorage.getItem('TownCountry')}
              />
              {/* We can use the "status" to decide whether we should display the dropdown or not */}
              {status === 'OK' && (
                <ul className="auto-complete-list">
                  {renderSuggestions()}
                </ul>
              )}
              {/* </div> */}{' '}
            </>
          ) : (
            ''
          )
        }
      </AuthUserContext.Consumer>
    </>
  );
};


const onListenForLocation = (authUser) => {
  const firestore = firebase.firestore();
  firestore
    .collection('users')
    .doc(authUser.uid)
    .onSnapshot(function (doc) {
		localStorage.setItem('TownCountry', doc.data().TownCountry);
    });
};

// export default PlacesAutocomplete;
const condition = (authUser) => !!authUser;

export default compose(
  withEmailVerification,
  withFirebase,
  withAuthorization(condition),
)(PlacesAutocomplete);
