import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import UserInterests from '../UI/interested';
import {
  AuthUserContext,
  withAuthorization,
  withEmailVerification,
} from '../Session';
import { formatDate } from '../../helpers/ui';
import DashboardSubNav from '../Navigation/dashboardSubNav';
import InnerBanner from './innerBanner';
import images from '../../constants/images';
import * as ROUTES from '../../constants/routes';

import './dashboard-my-account.scss';

import './dashboard-my-listing.scss';
// import './dashboard-sub-nav.scss';
import './banner-inner.scss';

class MyInterests extends Component {
  static contextType = AuthUserContext;
  constructor(props) {
    super(props);
    this.state = {
		loading: false,
		listings: null,
		noresults: false,
    };
  }
  componentDidMount() {
    this.onListenForListings();
  }

  onListenForListings = () => {
    const authUser = this.context;
    this.setState({ loading: true });
    this.props.firebase
      .user(authUser.uid)
      .get()
      .then((doc) => {
        if (doc.exists) {
			const userData = doc.data();
			// console.log(promises);
			if (userData) {
					// console.log('empty');
					this.setState({
					listings: null,
					loading: false,
				});
			}
          const promises = userData.interests.map((u) =>
            this.props.firebase.listings().doc(u).get(),
		  );
          Promise.all(promises).then((results) => {
            results.map((docSnapshot) => {});
            let listings = [];
            results.forEach((doc) =>
              listings.push({ ...doc.data(), uid: doc.id }),
            );
            if (results && listings.length > 0) {
              this.setState({
                listings: listings.reverse(),
                loading: false,
              });
            } else {
              this.setState({
				  loading: false,
				  noresults: true,
              });
            }
          });
        } else {
          this.setState({
            listings: null,
            loading: false,
          });
        }
      })
      .catch((error) => {
        console.log('Error getting document:', error);
      });
  };

  render = () => {
    const { listings, loading, noresults } = this.state;
    return (
      <>
        <AuthUserContext.Consumer>
          {(authUser) =>
            authUser ? (
              <div className="page-template-dashboard userFavourites userInterests">
                <InnerBanner user={authUser} />

                <div className="fw grad4 sect-pad-top sect-pad-bot step-process">
                  <div className="container">
                    <DashboardSubNav />
                    <div className="row">
                      <div className="col-sm-12 text-center">
                        {loading && (
                          <h2 className="nothing-here">Loading...</h2>
                        )}
                        {!listings && !loading ? (
                          <h2 className="nothing-here">
                            There are currently no listings to display.
                          </h2>
                        ) : null}
                      </div>
                      {listings &&
                        listings.map((listing) => (
                          <>
                            {listing.postType && (
                              <div
                                className={`col-sm-12 col-md-4 eblock-wrap ${listing.postType}`}
                                onClick={this.onListenForListings}
                              >
                                <UserInterests
                                  listing={listing.uid}
                                />
                                <Link
                                  to={`/${listing.postType}/?listing=${listing.uid}`}
                                  className="eblock"
                                >
                                  <div className="eblock-img">
                                    {/* <div className="featured-tag">Featured</div> */}
                                    {/* <div className="distance text-center">
                              2.6mi
                            </div> */}
                                    {listing.postThumbnails &&
                                      listing.postThumbnails[0] && (
                                        <img
                                          src={
                                            listing.postThumbnails[0]
                                          }
                                          className="resp-img"
                                        />
                                      )}
                                    {listing.postThumbnails &&
                                      !listing.postThumbnails[0] && (
                                        <img
                                          src={images.placeHolder}
                                          className="resp-img"
                                        />
                                      )}
                                  </div>
                                  <div className="eblock-dets">
                                    {listing.postType === 'event' && (
                                      <ul>
                                        <li className="date">
                                          {formatDate(
                                            listing.postStartDate,
                                          ).slice(0, -5)}
                                        </li>
                                        {/* {listing.postTime && ( */}
                                        <li className="time">
                                          14:00 - 15:00
                                        </li>
                                        {/* )} */}
                                      </ul>
                                    )}
                                    <h3>{listing.postTitle}</h3>
                                    <p>
                                      {listing.postAddressCounty}
                                      {`, ${listing.postAddressTownCity}`}
                                    </p>
                                  </div>
                                </Link>
                              </div>
                            )}
                          </>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ''
            )
          }
        </AuthUserContext.Consumer>
      </>
    );
  };
}

const condition = (authUser) => !!authUser;

export default compose(
  withFirebase,
  withEmailVerification,
  withAuthorization(condition),
)(MyInterests);
