import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';

import {
  AuthUserContext,
  withAuthorization,
  withEmailVerification,
} from '../Session';
import { formatDate } from '../../helpers/ui';
import DashboardSubNav from '../Navigation/dashboardSubNav';
import InnerBanner from './innerBanner';
import images from '../../constants/images';
import * as ROUTES from '../../constants/routes';

import './dashboard-my-account.scss';

import './dashboard-my-listing.scss';
// import './dashboard-sub-nav.scss';
import './banner-inner.scss';

class MyListings extends Component {
  static contextType = AuthUserContext;
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }
  componentDidMount() {
    this.onListenForListings();
  }

  onListenForListings = () => {
    const authUser = this.context;
    this.setState({ loading: true });

    this.unsubscribe = this.props.firebase
      .listings()
      .where('owner', '==', authUser.uid)
      //   .orderBy('createdAt', 'desc')

      .onSnapshot((snapshot) => {
        if (snapshot.size) {
          let listings = [];
          snapshot.forEach((doc) =>
            listings.push({ ...doc.data(), uid: doc.id }),
          );
          this.setState({
            listings: listings,
            loading: false,
          });
        } else {
          this.setState({ listings: null, loading: false });
        }
      });
  };

  onDeleteListing = (uid) => {
    this.unsubscribe = this.props.firebase.listing(uid).delete();
  };

  render = () => {
    const { listings, loading } = this.state;
    return (
      <>
        <AuthUserContext.Consumer>
          {(authUser) =>
            authUser ? (
              <div className="page-template-dashboard">
                <InnerBanner user={authUser} />

                <div className="fw grad4 sect-pad-top sect-pad-bot step-process">
                  <div className="container">
                    <DashboardSubNav />
                    <div className="row">
                      <div className="col-sm-12 col-md-4">
                        <div className="add-listing-wrap">
                          {/* <Link
                            to={ROUTES.ADDLISTING}
                            className="add-listing"
                          >
                            <img src={images.plusIcon} />
                            <h3>Add a Listing</h3>
                          </Link> */}
                          <a
                            className="add-listing"
                            href="/add-listing"
                          >
                            <img src={images.plusIcon} />
                            <h3>Add a Listing</h3>
                          </a>
                        </div>
                      </div>
                      {loading && (
                        <h2 className="nothing-here">Loading...</h2>
                      )}

                      {!listings && !loading && (
                        <h2 className="nothing-here">
                          You have no listings...
                        </h2>
                      )}
                      {listings &&
                        listings.map((listing) => (
                          <div
                            className={`col-sm-12 col-md-4 eblock-wrap ${listing.postType}`}
                          >
                            <div className="status-wrap grad6">
                              {/* {listing.editedAt && ( */}
                              <span className="status-date">
                                {listing.postStatus === 'draft'
                                  ? `Last saved: `
                                  : `Created at: `}
                                {listing.editedAt
                                  ? formatDate(
                                      listing.editedAt.seconds * 1000,
                                    )
                                  : listing.createdAt
                                  ? formatDate(
                                      listing.createdAt.seconds *
                                        1000,
                                    )
                                  : ''}
                              </span>
                              {/* )} */}

                              <span
                                className={`status ${listing.postStatus}`}
                              >
                                {listing.postStatus}
                              </span>
                            </div>
                            <div className="eblock">
                              <div className="eblock-img">
                                <Link
                                  to={`/${listing.postType}/?listing=${listing.uid}`}
                                  className="btn btn-white view-btn"
                                >
                                  <img src={images.view} />
                                  <p>View</p>
                                </Link>
                                <Link
                                  to={`/edit-listing?edit=${listing.uid}`}
                                  className="btn btn-white edit-btn"
                                >
                                  <img src={images.edit} />
                                  <p>Edit</p>
                                </Link>
                                <a
                                  onClick={(e) => {
                                    e.preventDefault();
                                    if (
                                      confirm(
                                        'Are you sure you want to delete this listing?',
                                      )
                                    ) {
                                      this.onDeleteListing(
                                        listing.uid,
                                      );
                                    } else {
                                      // Do nothing!
                                    }
                                  }}
                                  className="btn btn-white delete trash-btn"
                                >
                                  <img src={images.trash} />
                                  <p>Del</p>
                                </a>
                                {/* <img
                                  src={listing.postThumbnails[0]}
                                  className="resp-img"
                                /> */}

                                {listing.postThumbnails[0] && (
                                  <img
                                    src={listing.postThumbnails[0]}
                                    className="resp-img"
                                  />
                                )}
                                {!listing.postThumbnails[0] && (
                                  <img
                                    src={images.placeHolder}
                                    className="resp-img"
                                  />
                                )}
                              </div>
                              <div className="eblock-dets">
                                {listing.postType === 'event' && (
                                  <ul>
                                    <li className="date">
                                      {formatDate(
                                        listing.postStartDate,
                                      )}
                                    </li>
                                    <li
                                      className={`time ${
                                        listing.postStartTime
                                          ? ''
                                          : 'd-none'
                                      }`}
                                    >
                                      {listing.postStartTime &&
                                        listing.postStartTime}
                                      {listing.postEndTime &&
                                        `- ${listing.postEndTime}`}
                                    </li>
                                  </ul>
                                )}
                                <h3>{listing.postTitle}</h3>
                                <p>
                                  {listing.postAddressCounty}
                                  {`, ${listing.postAddressTownCity}`}
                                </p>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ''
            )
          }
        </AuthUserContext.Consumer>
      </>
    );
  };
}

const condition = (authUser) => !!authUser;

export default compose(
  withFirebase,
  withEmailVerification,
  withAuthorization(condition),
)(MyListings);
