import React, { Component } from 'react';
import { splitDay } from '../../helpers/ui';
class PostSidebar extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const listing = this.props.listing;
    return (
      <>
        {listing.postType == 'attraction' && listing.days && (
          <div class="fw grad7 block opening-times">
            <h3>Opening Times</h3>
            {splitDay(listing.days[0].everyday, 0) !==
              '- undefined' && (
              <ul class="fw">
                <li class="ot-left">Monday</li>
                <li class="ot-right text-right">
                  {listing.days[0].everyday.replace(
                    'undefined - undefined',
                    '-',
                  )}
                </li>
                <li class="ot-left">Tuesday</li>
                <li class="ot-right text-right">
                  {listing.days[0].everyday.replace(
                    'undefined - undefined',
                    '-',
                  )}
                </li>
                <li class="ot-left">Wednesday</li>
                <li class="ot-right text-right">
                  {listing.days[0].everyday.replace(
                    'undefined - undefined',
                    '-',
                  )}
                </li>
                <li class="ot-left">Thursday</li>
                <li class="ot-right text-right">
                  {listing.days[0].everyday.replace(
                    'undefined - undefined',
                    '-',
                  )}
                </li>
                <li class="ot-left">Friday</li>
                <li class="ot-right text-right">
                  {listing.days[0].everyday.replace(
                    'undefined - undefined',
                    '-',
                  )}
                </li>
                <li class="ot-left">Saturday</li>
                <li class="ot-right text-right">
                  {listing.days[0].everyday.replace(
                    'undefined - undefined',
                    '-',
                  )}
                </li>
                <li class="ot-left">Sunday</li>
                <li class="ot-right text-right">
                  {listing.days[0].everyday.replace(
                    'undefined - undefined',
                    '-',
                  )}
                </li>
              </ul>
            )}
            {console.log(listing.days[0])}
            {splitDay(listing.days[0].everyday, 0) ===
              '- undefined' && (
              <ul class="fw">
                {splitDay(listing.days[0].monday, 0) !==
                  '- undefined' && (
                  <>
                    <li class="ot-left">Monday</li>
                    <li class="ot-right text-right">
                      {splitDay(listing.days[0].monday, 0).replace(
                        'undefined',
                        '',
                      )}
                      {splitDay(listing.days[0].monday, 0) !==
                      'undefined'
                        ? ' - '
                        : ''}
                      {splitDay(listing.days[0].monday, 1).replace(
                        'undefined',
                        'Closed',
                      )}
                    </li>{' '}
                  </>
                )}
                {splitDay(listing.days[0].tuesday, 0) !==
                  '- undefined' && (
                  <>
                    <li class="ot-left">Tuesday</li>
                    <li class="ot-right text-right">
                      {splitDay(listing.days[0].tuesday, 0).replace(
                        'undefined',
                        '',
                      )}
                      {splitDay(listing.days[0].tuesday, 0) !==
                      'undefined'
                        ? ' - '
                        : ''}
                      {splitDay(listing.days[0].tuesday, 1).replace(
                        'undefined',
                        'Closed',
                      )}
                    </li>{' '}
                  </>
                )}
                {splitDay(listing.days[0].wednesday, 0) !==
                  '- undefined' && (
                  <>
                    <li class="ot-left">Wednesday</li>
                    <li class="ot-right text-right">
                      {splitDay(listing.days[0].wednesday, 0).replace(
                        'undefined',
                        '',
                      )}
                      {splitDay(listing.days[0].wednesday, 0) !==
                      'undefined'
                        ? ' - '
                        : ''}
                      {splitDay(listing.days[0].wednesday, 1).replace(
                        'undefined',
                        'Closed',
                      )}
                    </li>{' '}
                  </>
                )}
                {splitDay(listing.days[0].thursday, 0) !==
                  '- undefined' && (
                  <>
                    <li class="ot-left">Thursday</li>
                    <li class="ot-right text-right">
                      {splitDay(listing.days[0].thursday, 0).replace(
                        'undefined',
                        '',
                      )}
                      {splitDay(listing.days[0].thursday, 0) !==
                      'undefined'
                        ? ' - '
                        : ''}
                      {splitDay(listing.days[0].thursday, 1).replace(
                        'undefined',
                        'Closed',
                      )}
                    </li>{' '}
                  </>
                )}
                {splitDay(listing.days[0].friday, 0) !==
                  '- undefined' && (
                  <>
                    <li class="ot-left">Friday</li>
                    <li class="ot-right text-right">
                      {splitDay(listing.days[0].friday, 0).replace(
                        'undefined',
                        '',
                      )}
                      {splitDay(listing.days[0].friday, 0) !==
                      'undefined'
                        ? ' - '
                        : ''}
                      {splitDay(listing.days[0].friday, 1).replace(
                        'undefined',
                        'Closed',
                      )}
                    </li>{' '}
                  </>
                )}
                {splitDay(listing.days[0].saturday, 0) !==
                  '- undefined' && (
                  <>
                    <li class="ot-left">Saturday</li>
                    <li class="ot-right text-right">
                      {splitDay(listing.days[0].saturday, 0).replace(
                        'undefined',
                        '',
                      )}
                      {splitDay(listing.days[0].saturday, 0) !==
                      'undefined'
                        ? ' - '
                        : ''}
                      {splitDay(listing.days[0].saturday, 1).replace(
                        'undefined',
                        'Closed',
                      )}
                    </li>{' '}
                  </>
                )}
                {splitDay(listing.days[0].sunday, 0) !==
                  '- undefined' && (
                  <>
                    <li class="ot-left">Sunday</li>
                    <li class="ot-right text-right">
                      {splitDay(listing.days[0].sunday, 0).replace(
                        'undefined',
                        '',
                      )}
                      {splitDay(listing.days[0].sunday, 0) !==
                      'undefined'
                        ? ' - '
                        : ''}
                      {splitDay(listing.days[0].sunday, 1).replace(
                        'undefined',
                        'Closed',
                      )}
                    </li>{' '}
                  </>
                )}
              </ul>
            )}
          </div>
        )}
        <div
          className={`block listing-cats ${listing.postType} ${
            listing.tagCouples ||
            listing.tagAdults ||
            listing.tagTeenagers ||
            listing.tagFamilies ||
            listing.tagChildren312 ||
            listing.tagBabiesToddlers ||
            listing.tagPetFriendly ||
            listing.tagIndoor ||
            listing.tagOutdoor ||
            listing.tagOutdoor ||
            listing.tagSeasonal
              ? ''
              : 'd-none'
          }`}
        >
          <h3>Suitable for...</h3>
          <ul>
            {listing.tagCouples && (
              <li className="cat-tick">Couples</li>
            )}
            {listing.tagAdults && (
              <li className="cat-tick">Adults</li>
            )}
            {listing.tagTeenagers && (
              <li className="cat-tick">Teenagers</li>
            )}
            {listing.tagFamilies && (
              <li className="cat-tick">Families</li>
            )}
            {listing.tagChildren312 && (
              <li className="cat-tick">Children (3 To 12)</li>
            )}
            {listing.tagBabiesToddlers && (
              <li className="cat-tick">Babies and Toddlers</li>
            )}
            {listing.tagPetFriendly && (
              <li className="cat-tick">Dog Friendly</li>
            )}
            {listing.tagIndoor && (
              <li className="cat-tick">Indoor</li>
            )}
            {listing.tagOutdoor && (
              <li className="cat-tick">Outdoor</li>
            )}
            {listing.tagSeasonal && (
              <li className="cat-tick">Seasonal</li>
            )}
          </ul>
        </div>
      </>
    );
  }
}

export default PostSidebar;
