import React, { Component } from 'react';
import { withFirebase } from '../Firebase';
import { formatDate } from '../../helpers/ui';
import UserFavourites from '../UI/wishList';
import UserInterested from '../UI/interested';
import Distance from '../UI/distance';
import CardsLoader from '../ContentLoaders/cardsLoader';
import images from '../../constants/images';

class RelatedListings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      relatedListings: [],
      limit: 3,
    };
  }
  componentDidMount() {
    this.onListenForListings();
  }

  onListenForListings = () => {
    this.unsubscribe = this.props.firebase
      .listings()
      .where(
        'postType',
        '==',
        this.props.postType,
      )
      .onSnapshot((snapshot) => {
        if (snapshot.size) {
          let relatedListings = [];
          snapshot.forEach((doc) => {
            let cl = doc.data();
            if (
              cl.postStatus === 'live' &&
              cl.postTitle != this.props.postTitle &&
              cl.postThumbnails[0] != ''
            ) {
              relatedListings.push({ ...doc.data(), uid: doc.id });
            }
          });
          this.setState({
            relatedListings: relatedListings.reverse(),
            loading: false,
          });
        } else {
          this.setState({ relatedListings: null, loading: false });
        }
      });
  };
  render = () => {
    const { relatedListings, loading } = this.state;
    const authUser = localStorage.getItem('authUser');
    return (
      <>
        <div className="sect-pad-top sect-pad-bot related-posts">
          <div className="container">
            <div className="row block-titles">
              <div className="col-sm-12">
                <h2>Related Listings</h2>
              </div>
            </div>
            {loading && (
              <div className="row block-titles">
                <div className="col-sm-12 col-md-4 eblock-wrap">
                  <CardsLoader />
                </div>
                <div className="col-sm-12 col-md-4 eblock-wrap">
                  <CardsLoader />
                </div>
                <div className="col-sm-12 col-md-4 eblock-wrap">
                  <CardsLoader />
                </div>
              </div>
            )}
            <div className="row mt-3">
              {relatedListings.slice(0, 3).map((listing, index) => (
                <div
                  className={`col-sm-12 col-md-4 eblock-wrap ${listing.postType}`}
                >
                  {authUser && listing.postType === 'attraction' && (
                    <UserFavourites listing={listing.uid} />
                  )}
                  {authUser && listing.postType === 'event' && (
                    <UserInterested listing={listing.uid} />
                  )}
                  <a
                    href={`/${listing.postType}/?listing=${listing.uid}`}
                    className="eblock"
                  >
                    <div className="eblock-img">
                      {/* <div className="featured-tag">Featured</div> */}
                      <Distance
                        listing={listing}
                        type="blockListing"
                      />
                      {listing.postThumbnails[0] && (
                        <img
                          src={listing.postThumbnails[0]}
                          className="resp-img"
                        />
                      )}
                      {!listing.postThumbnails[0] && (
                        <img
                          src={images.placeHolder}
                          className="resp-img"
                        />
                      )}
                    </div>
                    <div className="eblock-dets">
                      {listing.postType === 'event' && (
                        <ul>
                          <li className="date">
                            {formatDate(listing.postStartDate).slice(
                              0,
                              -5,
                            )}
                          </li>
                          <li
                            className={`time ${
                              listing.postStartTime ? '' : 'd-none'
                            }`}
                          >
                            {listing.postStartTime &&
                              listing.postStartTime}
                            {listing.postEndTime &&
                              `- ${listing.postEndTime}`}
                          </li>
                        </ul>
                      )}
                      <h3>{listing.postTitle}</h3>
                      <p>
                        {listing.postAddressTownCity}
                        {`, ${listing.postAddressCounty}`}
                      </p>
                    </div>
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
      </>
    );
  };
}

export default withFirebase(RelatedListings);
