import React from 'react';

import AuthUserContext from './context';
import { withFirebase } from '../Firebase';
import './email-verify.scss';

const needsEmailVerification = authUser =>
  authUser &&
  !authUser.emailVerified &&
  authUser.providerData
    .map(provider => provider.providerId)
    .includes('password');

const withEmailVerification = Component => {
  class WithEmailVerification extends React.Component {
    constructor(props) {
      super(props);

      this.state = { isSent: false };
    }

    onSendEmailVerification = () => {
      this.props.firebase
        .doSendEmailVerification()
        .then(() => this.setState({ isSent: true }));
    };

    render() {
      return (
        <AuthUserContext.Consumer>
          {authUser =>
            needsEmailVerification(authUser) ? (
              <div className="unverifiedAccount text-center sect-pad-top sect-pad-bot">
              <div className="container">
              <div className="row">
              <div className="col-sm-12 col-md-8 offset-md-2">
              <h2>Email verification needed</h2>
                {this.state.isSent ? (
                  <p>
                    Confirmation email sent! To view your dashboard you need to first verify your email address.
                    Please check your inbox for the verification email and click the verification link to complete the signup process.
                    If you can't see the email please check your spam folder. Refresh this page once you've verified your email.
                  </p>
                ) : (
                  <p>
                    To view your dashboard you need to first verify your email address.
                    Please check your inbox for the verification email and click the verification link to complete the signup process.
                    If you can't see the email please check your spam folder. Refresh this page once you've verified your email.
                  </p>
                )}

                <button
                  type="button"
                  className="btn"
                  onClick={this.onSendEmailVerification}
                  disabled={this.state.isSent}
                >
                  Re-send confirmation email
                </button>
              </div>
              </div>
              </div>
              </div>

            ) : (
              <Component {...this.props} />
            )
          }
        </AuthUserContext.Consumer>
      );
    }
  }

  return withFirebase(WithEmailVerification);
};

export default withEmailVerification;
