import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import SignIn from '../SignIn';
import SignOutButton from '../SignOut';
import SignUpForm from '../SignUp';
import * as ROUTES from '../../constants/routes';
import images from '../../constants/images';

import './footer.scss';

function LoginRegister(props) {
  return (
    <Modal
      {...props}
      id="logModal"
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <SignIn />
        <SignUpForm />
      </Modal.Body>
    </Modal>
  );
}
function Footer() {
  const authUser = localStorage.getItem('authUser');
	const [modalShow, setModalShow] = useState(false);
	const location = useLocation();
  return (
    <>
      {/*footer ------------------------------------------------------------------*/}
      <div
        className={`fw footer sect-pad-top sect-pad-bot ${
          location.pathname.includes('/attraction')
            ? 'attraction'
            : 'lala'
        }`}
      >
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-3">
              <img src={images.logo} className="logo" />
            </div>
            <div className="col-sm-12 col-md-3 col-lg-4">
              <ul className="sitemap">
                <li>
                  <Link to={ROUTES.LANDING}>Home</Link>
                </li>
                <li>
                  <Link to={ROUTES.ADDLISTING}>Add listing</Link>
                </li>
                <li>
                  <Link to={ROUTES.ABOUTPAGE}>About us</Link>
                </li>
                <li>
                  <Link to={ROUTES.FAQPAGE}>FAQs</Link>
                </li>
                <li>
                  <Link to={ROUTES.CONTACTPAGE}>Contact us</Link>
                </li>
              </ul>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-5 text-md-right ctas">
              <>
                <Link
                  to={ROUTES.ADDLISTING}
                  className="btn btn-white"
                >
                  Add a listing
                </Link>
                {!authUser && (
                  <>
                    <a
                      href="#"
                      className="btn setModalShow"
                      onClick={() => setModalShow(true)}
                    >
                      Login / Signup
                    </a>
                    <LoginRegister
                      show={modalShow}
                      onHide={() => setModalShow(false)}
                    />
                  </>
                )}
                {authUser && (<SignOutButton classes="btn" />)}
              </>
            </div>
          </div>
          <div className="row footer2">
            <div className="col-sm-12 col-md-3">
              <p>© Togodo 2021</p>
            </div>
            <div className="col-sm-12 col-md-5 col-lg-6">
              <ul>
                <li>
                  <Link to={ROUTES.TERMSPAGE}>
                    Terms &amp; conditions
                  </Link>
                </li>
                <li>
                  <Link to={ROUTES.PRIVACYPAGE}>
                    Privacy policy
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-sm-12 col-md-4 col-lg-3 text-center text-md-right">
              <p>Website by Altitude Digital</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
