import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { NotificationsProvider } from 'reapop';
import NotiFicationsContainer from '../UI/notificationsContainer';

import Navigation from '../Navigation';
import Footer from '../Footer';

import LandingPage from '../Landing';
import SignUpPage from '../SignUp';
import SignInPage from '../SignIn';
import PasswordForgetPage from '../PasswordForget';
import HomePage from '../Home';
import UserDashboard from '../Dashboard';
import MyListings from '../Dashboard/mylistings';
import MyFavourites from '../Dashboard/myfavourites';
import MyInterests from '../Dashboard/myinterests';
import AccountPage from '../Account';
import AdminPage from '../Admin';
import AddListing from '../Listings/ListingAdd';
import EditListing from '../Listings/ListingEdit';
import Search from '../Search';
import AboutPage from '../Pages/about';
import ClaimPage from '../Pages/claim';
import ContactPage from '../Pages/contact';
import FaqPage from '../Pages/faq';
import Terms from '../Pages/terms';
import Privacy from '../Pages/privacy';
import NotFound404 from '../NotFound';
import ScrollToTop from '../UI/scrollTop';
import CronAll from '../Crons/cronAll';

import * as ROUTES from '../../constants/routes';
import { withAuthentication } from '../Session';
import Event from '../Single/event';
import Attraction from '../Single/attraction';

import EmailsSetup from '../Emails';

const App = () => (
  <Router>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Togodo</title>
      <link rel="canonical" href="https://togodo.co.uk" />
    </Helmet>
    <EmailsSetup />
    <NotificationsProvider>
      <Navigation />
      <ScrollToTop />
      <Switch>
        <Route exact path={ROUTES.LANDING} component={LandingPage} />
        <Route path={ROUTES.SIGN_UP} component={SignUpPage} />
        <Route path={ROUTES.SIGN_IN} component={SignInPage} />
        <Route
          path={ROUTES.PASSWORD_FORGET}
          component={PasswordForgetPage}
        />
        <Route path={ROUTES.HOME} component={HomePage} />
        <Route path={ROUTES.DASHBOARD} component={UserDashboard} />
        <Route path={ROUTES.MYLISTINGS} component={MyListings} />
        <Route path={ROUTES.MYFAVOURITES} component={MyFavourites} />
        <Route path={ROUTES.MYINTERESTS} component={MyInterests} />
        <Route path={ROUTES.ADDLISTING} component={AddListing} />
        <Route path={ROUTES.EDITLISTING} component={EditListing} />
        <Route path={ROUTES.ACCOUNT} component={AccountPage} />
        <Route path={ROUTES.ADMIN} component={AdminPage} />
        <Route path={ROUTES.ABOUTPAGE} component={AboutPage} />
        <Route path={ROUTES.CLAIMPAGE} component={ClaimPage} />
        <Route path={ROUTES.CONTACTPAGE} component={ContactPage} />
        <Route path={ROUTES.FAQPAGE} component={FaqPage} />
        <Route path={ROUTES.TERMSPAGE} component={Terms} />
        <Route path={ROUTES.CRONALL} component={CronAll} />

        <Route path={ROUTES.PRIVACYPAGE} component={Privacy} />
        <Route path={ROUTES.SEARCH} component={Search} />
        <Route path={ROUTES.EVENT} component={Event} />
        <Route path={ROUTES.ATTRACTION} component={Attraction} />
        <Route component={NotFound404} />
      </Switch>
      <NotiFicationsContainer />
    </NotificationsProvider>
    <Footer />
  </Router>
);

export default withAuthentication(App);
