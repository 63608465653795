import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { compose } from 'recompose';
import emailjs from 'emailjs-com';
import AddressAutoComplete from '../UI/SettingsAddressAutoComplete';

import { withFirebase } from '../Firebase';
import firebase from 'firebase';
import 'firebase/firestore';

import { NotifyUser } from '../UI/theNotification';

import Select from 'react-select';
import {
  AuthUserContext,
  withAuthorization,
  withEmailVerification,
} from '../Session';
import DashboardSubNav from '../Navigation/dashboardSubNav';
import InnerBanner from './innerBanner';
import { splitName } from '../../helpers/users';

import './dashboard-my-account.scss';

import './dashboard-my-listing.scss';
import './eblock.scss';

const options = [
  { value: '10', label: '10 Miles' },
  { value: '20', label: '20 Miles' },
  { value: '30', label: '30 Miles' },
  { value: '60', label: '60 Miles' },
];
function userRequests(action, usr) {
  if (action === 'delete') {
    var templateParams = {
      userId: usr.uid,
      userName: usr.username,
      userEmail: usr.email,
      action: 'account deletion request',
    };
  } else {
    var templateParams = {
      userId: usr.uid,
      userName: usr.username,
      userEmail: usr.email,
      action: 'data request',
    };
  }
  if (action) {
    emailjs.send(
      'service_smt',
      'template_0y63fpk',
      templateParams,
      'user_XzyVLl9ojVvIJBtcrr1F6',
    );
  }
}
const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? '#ddd' : '#1E082E',
    background: state.isSelected ? '#1E082E' : '#ddd',
    color: state.isFocused ? '#ddd' : '#1E082E',
    background: state.isFocused ? '#1E082E' : '#ddd',
    fontSize: '18px',
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';
    return { ...provided, opacity, transition };
  },
};

function UserDashboard() {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [NotifyUpdateUserInfo, setUpdateUserInfo] = useState(false);
  const [NotifyRequest, setRequest] = useState(false);
  const [setPreferences, userPreferences] = useState('');
  const [selectedOption, setSelectedOption] = useState(null);
  
  return (
    <>
      <AuthUserContext.Consumer>
        {(authUser) =>
          authUser && onListedForPreferences(authUser) ? (
            <div className="page-template-dashboard">
              {/* <Listings /> */}
              <InnerBanner user={authUser} />
              {/* {setPreferences()} */}
              {/* {onListedForPreferences(authUser) ? onListedForPreferences(authUser) : ''}
               */}
              {/* Step Nav ---------------------------------------------------------------*/}
              <div className="fw grad4 sect-pad-top sect-pad-bot">
                <div className="container">
                  <DashboardSubNav />
                  <form
                    role="form"
                    name="profile_setting_form"
                    id="profile_setting_form"
                    action="#"
                  >
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="fw">
                          <h2>
                            Personal details{' '}
                            {/* {onListedForPreferences(authUser)} */}
                          </h2>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <label>First name*</label>
                        <input
                          type="text"
                          placeholder="Enter First Name"
                          name="first_name"
                          onInput={(e) =>
                            setFirstName(e.target.value)
                          }
                          className="form-control"
                          defaultValue={splitName(
                            authUser.username,
                            0,
                          )}
                          //   disabled
                        />
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <label>Last name*</label>
                        <input
                          type="text"
                          placeholder="Enter Last Name"
                          name="last_name"
                          onInput={(e) => setLastName(e.target.value)}
                          className="form-control"
                          defaultValue={splitName(
                            authUser.username,
                            1,
                          )}
                          //   disabled
                        />
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <label>Username</label>
                        <input
                          className="form-control"
                          defaultValue={authUser.email}
                          disabled
                        />
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <label>Email address*</label>
                        <input
                          type="text"
                          placeholder="Enter address"
                          name="address"
                          className="form-control"
                          defaultValue={authUser.email}
                          disabled
                        />
                      </div>
                      <div className="col-sm-12">
                        <div className id="update_message" />
                        <button
                          type="button"
                          onClick={(e) => {
                            e.preventDefault();
                            setUpdateUserInfo(true);
                            updateUserInfo(
                              authUser,
                              firstName
                                ? firstName
                                : splitName(authUser.username, 0),
                              lastName
                                ? lastName
                                : splitName(authUser.username, 1),
                            );
                            setUpdateUserInfo(true);
                          }}
                          className="btn green-haze"
                        >
                          Update
                        </button>
                        {NotifyUpdateUserInfo
                          ? (setUpdateUserInfo(false),
                            (
                              <NotifyUser
                                title="Success"
                                message="Your profile has been updated"
                                status="success"
                              />
                            ))
                          : ''}
                      </div>
                      <hr className="divide-line" />
                    </div>
                  </form>
                  <div className="row">
                    <div className="col-sm-12">
                      <h2>My preferences</h2>
                      <h3>
                        Personalise your Togodo experience by
                        selecting your preferences below.
                      </h3>
                    </div>
                    <div className="col-sm-12">
                      <h5>I'm interested in...</h5>
                    </div>
                  </div>
                  <form action name="user_pref" id="user_pref">
                    <div className="row">
                      <div className="col-sm-12 col-md-4 col-lg-3">
                        <div className="checkbox-wrap">
                          <label htmlFor="toggle14">Events</label>
                          <input
                            type="checkbox"
                            id="toggle14"
                            name="events"
                            className="visually-hidden"
                            onClick={(e) => {
                              handleInputChange(authUser, e);
                              //   setUpdateUserInfo(true);
                            }}
                            defaultChecked={
                              authUser.preferences &&
                              localStorage
                                .getItem('preferences')
                                .includes('events')
                                ? 'checked'
                                : ''
                            }
                          />

                          <div className="control-me" />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-4 col-lg-3">
                        <div className="checkbox-wrap">
                          <label htmlFor="toggle15">
                            Attractions
                          </label>
                          <input
                            type="checkbox"
                            id="toggle15"
                            name="attractions"
                            className="visually-hidden"
                            onClick={(e) => {
                              handleInputChange(authUser, e);
                              //   setUpdateUserInfo(true);
                            }}
                            defaultChecked={
                              authUser.preferences &&
                              localStorage
                                .getItem('preferences')
                                .includes('attractions')
                                ? 'checked'
                                : ''
                            }
                          />
                          <div className="control-me" />
                        </div>
                      </div>
                    </div>
                    {NotifyRequest
                      ? (setRequest(false),
                        (
                          <NotifyUser
                            title="Success"
                            message="We received your request. Someone will get in touch with you shortly."
                            status="success"
                          />
                        ))
                      : ''}
                    <div className="row">
                      <div className="col-sm-12 col-md-6 custom-select-menu">
                        <h5>Within:</h5>
                        <Select
                          id="miles-small"
                          options={options}
                          defaultValue={
                            localStorage.getItem('radius')
                              ? options[`${localStorage.getItem('radius')}`]
                              : options[0]
                          }
                          //   onChange={setSelectedOption}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          styles={customStyles}
                          name="radius"
                          onChange={(e) => {
							  setSelectedOption;
							  localStorage.setItem('radius', selectedOption);
                            // updateRadius(authUser, e, selectedOption);
                          }}
                        />
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <h5>Of:</h5>
                        {/* <input
                          type="text"
                          className="form-control"
                          name="town_or_country"
                          placeholder="Town or County"
                          defaultValue={authUser.TownCountry}
                          onChange={(e) => {
                            updateTownCountry(authUser, e);
                            // setUpdateUserInfo(true);
                          }}
                        /> */}
                        <AddressAutoComplete />
                      </div>
                      <div className="col-sm-12">
                        <div className="row">
                          <div className="col-sm-12">
                            <h5>Within the following categories:</h5>
                          </div>
                          <div className="col-sm-12 col-md-4 col-lg-3">
                            <div className="checkbox-wrap">
                              <label htmlFor="toggle1">Couples</label>
                              <input
                                type="checkbox"
                                id="toggle1"
                                name="couples"
                                className="visually-hidden"
                                onClick={(e) => {
                                  handleInputChange(authUser, e);
                                  //   setUpdateUserInfo(true);
                                }}
                                defaultChecked={
                                  authUser.preferences &&
                                  localStorage
                                    .getItem('preferences')
                                    .includes('couples')
                                    ? 'checked'
                                    : ''
                                }
                              />
                              <div className="control-me" />
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-4 col-lg-3">
                            <div className="checkbox-wrap">
                              <label htmlFor="toggle2">Adults</label>
                              <input
                                type="checkbox"
                                id="toggle2"
                                name="adults"
                                className="visually-hidden"
                                onClick={(e) => {
                                  handleInputChange(authUser, e);
                                  //   setUpdateUserInfo(true);
                                }}
                                defaultChecked={
                                  authUser.preferences &&
                                  localStorage
                                    .getItem('preferences')
                                    .includes('adults')
                                    ? 'checked'
                                    : ''
                                }
                              />
                              <div className="control-me" />
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-4 col-lg-3">
                            <div className="checkbox-wrap">
                              <label htmlFor="toggle3">
                                Teenagers
                              </label>
                              <input
                                type="checkbox"
                                id="toggle3"
                                name="teenagers"
                                className="visually-hidden"
                                onClick={(e) => {
                                  handleInputChange(authUser, e);
                                  //   setUpdateUserInfo(true);
                                }}
                                defaultChecked={
                                  authUser.preferences &&
                                  localStorage
                                    .getItem('preferences')
                                    .includes('teenagers')
                                    ? 'checked'
                                    : ''
                                }
                              />
                              <div className="control-me" />
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-4 col-lg-3">
                            <div className="checkbox-wrap">
                              <label htmlFor="toggle4">
                                Families
                              </label>
                              <input
                                type="checkbox"
                                id="toggle4"
                                name="families"
                                className="visually-hidden"
                                onClick={(e) => {
                                  handleInputChange(authUser, e);
                                  //   setUpdateUserInfo(true);
                                }}
                                defaultChecked={
                                  authUser.preferences &&
                                  localStorage
                                    .getItem('preferences')
                                    .includes('families')
                                    ? 'checked'
                                    : ''
                                }
                              />
                              <div className="control-me" />
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-4 col-lg-3">
                            <div className="checkbox-wrap">
                              <label htmlFor="toggle5">
                                Children (3 to 12)
                              </label>
                              <input
                                type="checkbox"
                                id="toggle5"
                                name="children"
                                className="visually-hidden"
                                onClick={(e) => {
                                  handleInputChange(authUser, e);
                                  //   setUpdateUserInfo(true);
                                }}
                                defaultChecked={
                                  authUser.preferences &&
                                  localStorage
                                    .getItem('preferences')
                                    .includes('children')
                                    ? 'checked'
                                    : ''
                                }
                              />
                              <div className="control-me" />
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-4 col-lg-3">
                            <div className="checkbox-wrap">
                              <label htmlFor="toggle6">
                                Babies and Toddlers
                              </label>
                              <input
                                type="checkbox"
                                id="toggle6"
                                name="babies_toddlers"
                                className="visually-hidden"
                                onClick={(e) => {
                                  handleInputChange(authUser, e);
                                  //   setUpdateUserInfo(true);
                                }}
                                defaultChecked={
                                  authUser.preferences &&
                                  localStorage
                                    .getItem('preferences')
                                    .includes('babies_toddlers')
                                    ? 'checked'
                                    : ''
                                }
                              />
                              <div className="control-me" />
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-4 col-lg-3">
                            <div className="checkbox-wrap">
                              <label htmlFor="toggle7">
                                Dog Friendly
                              </label>
                              <input
                                type="checkbox"
                                id="toggle7"
                                name="dog_friendly"
                                className="visually-hidden"
                                onClick={(e) => {
                                  handleInputChange(authUser, e);
                                  //   setUpdateUserInfo(true);
                                }}
                                defaultChecked={
                                  authUser.preferences &&
                                  localStorage
                                    .getItem('preferences')
                                    .includes('dog_friendly')
                                    ? 'checked'
                                    : ''
                                }
                              />
                              <div className="control-me" />
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-4 col-lg-3">
                            <div className="checkbox-wrap">
                              <label htmlFor="toggle8">Indoor</label>
                              <input
                                type="checkbox"
                                id="toggle8"
                                name="indoor"
                                className="visually-hidden"
                                onClick={(e) => {
                                  handleInputChange(authUser, e);
                                  //   setUpdateUserInfo(true);
                                }}
                                defaultChecked={
                                  authUser.preferences &&
                                  localStorage
                                    .getItem('preferences')
                                    .includes('indoor')
                                    ? 'checked'
                                    : ''
                                }
                              />
                              <div className="control-me" />
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-4 col-lg-3">
                            <div className="checkbox-wrap">
                              <label htmlFor="toggle9">Outdoor</label>
                              <input
                                type="checkbox"
                                id="toggle9"
                                name="outdoor"
                                className="visually-hidden"
                                onClick={(e) => {
                                  handleInputChange(authUser, e);
                                  //   setUpdateUserInfo(true);
                                }}
                                defaultChecked={
                                  authUser.preferences &&
                                  localStorage
                                    .getItem('preferences')
                                    .includes('outdoor')
                                    ? 'checked'
                                    : ''
                                }
                              />
                              <div className="control-me" />
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-4 col-lg-3">
                            <div className="checkbox-wrap">
                              <label htmlFor="toggle10">
                                Seasonal
                              </label>
                              <input
                                type="checkbox"
                                id="toggle10"
                                name="seasonal"
                                className="visually-hidden"
                                onClick={(e) => {
                                  handleInputChange(authUser, e);
                                  //   setUpdateUserInfo(true);
                                }}
                                defaultChecked={
                                  authUser.preferences &&
                                  localStorage
                                    .getItem('preferences')
                                    .includes('seasonal')
                                    ? 'checked'
                                    : ''
                                }
                              />
                              <div className="control-me" />
                            </div>
                          </div>
                          <div className="col-sm-12">
                            <button
                              type="button"
                              onClick={(e) => {
                                e.preventDefault();
                                setUpdateUserInfo(true);
                                updateUserInfo(
                                  authUser,
                                  firstName
                                    ? firstName
                                    : splitName(authUser.username, 0),
                                  lastName
                                    ? lastName
                                    : splitName(authUser.username, 1),
                                );
                                setUpdateUserInfo(true);
                                updateTownCountry(authUser, e);
                              }}
                              className="btn green-haze"
                            >
                              Update
                            </button>
                          </div>
                          <hr className="divide-line" />
                        </div>
                        <div className="row">
                          <div className="col-sm-12">
                            <h2>Email newsletter</h2>
                            <h5>
                              Send me weekly emails based on my
                              preferences...
                            </h5>
                            <div className="checkbox-wrap">
                              <label htmlFor="toggle13">Opt in</label>
                              <input
                                type="checkbox"
                                id="toggle13"
                                name="newsletter"
                                className="visually-hidden"
                                onClick={(e) => {
                                  handleInputChange(authUser, e);
                                  setUpdateUserInfo(true);
                                }}
                                defaultChecked={
                                  authUser.preferences &&
                                  localStorage
                                    .getItem('preferences')
                                    .includes('newsletter')
                                    ? 'checked'
                                    : ''
                                }
                              />
                              <div className="control-me" />
                            </div>
                          </div>
                          <div className="col-sm-12 d-none">
                            <div
                              className
                              id="user_pref_update_message"
                            />
                            <button
                              type="button"
                              onclick="update_profile_user_pref();"
                              className="btn "
                            >
                              Update{' '}
                            </button>
                          </div>
                          <hr className="divide-line" />
                        </div>
                        <div className="row">
                          <div className="col-sm-12">
                            <h2>Data Request</h2>
                            <h5>I want copy of my data.</h5>
                          </div>
                          <div className="col-sm-12">
                            <div
                              className
                              id="user_pref_update_message"
                            />
                            <button
                              type="button"
                              onClick={() => {
                                userRequests('dataRequest', authUser);
                                setRequest(true);
                              }}
                              className="btn "
                            >
                              Account data request.
                            </button>
                          </div>
                          <hr className="divide-line" />
                        </div>
                        <div className="row">
                          <div className="col-sm-12">
                            <h2>Account Deletion</h2>
                            <h5>I want to delete my account.</h5>
                          </div>
                          <div className="col-sm-12">
                            <div
                              className
                              id="user_pref_update_message"
                            />
                            <button
                              type="button"
                              onClick={() => {
                                userRequests('delete', authUser);
                                setRequest(true);
                              }}
                              className="btn "
                            >
                              Account delection request.
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          ) : (
            ''
          )
        }
      </AuthUserContext.Consumer>
    </>
  );
}

const condition = (authUser) => !!authUser;

const updateUserInfo = (authUser, firstName, lastName) => {
  const firestore = firebase.firestore();
  const currentUser = firestore.collection('users').doc(authUser.uid);

  currentUser.update({
    username: firstName + ' ' + lastName,
  });
};

const handleInputChange = (authUser, event) => {
  const target = event.target;
  const value =
    target.type === 'checkbox' ? target.checked : target.value;
  const name = target.name;

  const firestore = firebase.firestore();
  const currentUser = firestore.collection('users').doc(authUser.uid);

  if (value) {
    currentUser.update({
      preferences: firebase.firestore.FieldValue.arrayUnion(name),
    });
  } else {
    currentUser.update({
      preferences: firebase.firestore.FieldValue.arrayRemove(name),
    });
  }
  console.log('updated');
};

const updateTownCountry = (authUser, event) => {
  const target = event.target;

  const firestore = firebase.firestore();
  const currentUser = firestore.collection('users').doc(authUser.uid);

  currentUser.update({
    TownCountry: document.getElementById('UserTownCountry').value,
  });
};

const updateRadius = (authUser, event, se) => {
	const target = event.target;
	let val = selectedOption / 10 - 1;
	console.log(val);
  const firestore = firebase.firestore();
  const currentUser = firestore.collection('users').doc(authUser.uid);

  currentUser.update({
    radius: val,
  });
  
};

const onListedForPreferences = (authUser) => {
	const firestore = firebase.firestore();
  firestore
    .collection('users')
    .doc(authUser.uid)
    .onSnapshot(function (doc) {
		localStorage.setItem('preferences', doc.data().preferences);
	});
	return true;
};

export default compose(
  withEmailVerification,
  withFirebase,
  withAuthorization(condition),
)(UserDashboard);
