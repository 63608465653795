import firebase from 'firebase';

class UploadAdapter {
  constructor(loader, uid) {
    this.loader = loader;
    this.uid = uid;
  }
  upload() {
    return this.loader.file.then(
      (file) =>
        new Promise((resolve, reject) => {
          // firebase reference
          let uploadTask = firebase
            .storage()
            .ref()
            .child(`listings/${this.uid}/${file.name}`)
            .put(file);

          uploadTask.on(
            firebase.storage.TaskEvent.STATE_CHANGED,
            (snapshot) => {
              /* snapshot with info about 
                            the upload progress & metadata */
            },
            (error) => {
              alert(error);
            },
            () => {
              // upload successful
              uploadTask.snapshot.ref
                .getDownloadURL()
                .then((downloadURL) => {
                  resolve({
                    default: downloadURL,
                  });
                });
            },
          );
        }),
    );
  }
}

export default UploadAdapter;
