import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import SignIn from '../SignIn';
import SignUpForm from '../SignUp';
import { AuthUserContext } from '../Session';

import * as ROUTES from '../../constants/routes';
import './signup-or-post.scss';

function LoginRegister(props) {
  return (
    <Modal
      {...props}
      id="logModal"
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <SignIn />
        <SignUpForm />
      </Modal.Body>
    </Modal>
  );
}

function SignupSection() {
  const authUser = localStorage.getItem('authUser');
  const [modalShow, setModalShow] = useState(false);
  return (
    <>
      {authUser && (
        <>
          {/*Sign Up Wrap - Member ------------------------------------------------------------*/}
          <div className="dark-purple-bg2 su-post-wrap">
            <div className="container">
              <div className="row">
                <div className="col-sm pattern1-behind">
                  <div className="route-block rb-signup sign-up-icon">
                    <h2 className="pink">Welcome back!</h2>
                    <p>
                      Get personalised things
                      <br /> 'togodo' direct to your inbox
                    </p>
                    <Link to={ROUTES.DASHBOARD} className="btn-white">
                      Email newsletter
                    </Link>
                  </div>
                </div>
                <div className="col-sm pattern2-behind">
                  <div className="post-img post-img-1" />
                  <div className="post-img post-img-2" />
                  <div className="post-img post-img-3" />
                  <div className="route-block rb-post">
                    <h2>
                      Post your event or <br />
                      attraction now, its <br />
                      quick and easy!
                    </h2>
                    <Link
                      to={ROUTES.ADDLISTING}
                      className="btn-white"
                    >
                      Add a listing
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {!authUser && (
        <>
          {/*Sign Up Wrap - Visitor ------------------------------------------------------------*/}
          <div className="dark-purple-bg2 su-post-wrap">
            <div className="container">
              <div className="row">
                <div className="col-sm pattern1-behind">
                  <div className="route-block rb-signup sign-up-icon">
                    <h2 className="pink">Signup, it’s free!</h2>
                    <p>
                      Get personalised things
                      <br /> 'togodo' direct to your inbox
                    </p>
                    <a
                      href="#"
                      className="btn setModalShow"
                      onClick={() => setModalShow(true)}
                    >
                      Count me in!
                    </a>
                    <LoginRegister
                      show={modalShow}
                      onHide={() => setModalShow(false)}
                    />
                  </div>
                </div>
                <div className="col-sm pattern2-behind">
                  <div className="post-img post-img-1" />
                  <div className="post-img post-img-2" />
                  <div className="post-img post-img-3" />
                  <div className="route-block rb-post">
                    <h2>
                      Post your event or <br />
                      attraction now, its <br />
                      quick and easy!
                    </h2>
                    <Link
                      to={ROUTES.ADDLISTING}
                      className="btn-white"
                    >
                      Add a listing
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
export default SignupSection;
