import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import UserFavourites from '../UI/wishList';
import UserInterested from '../UI/interested';
import { AuthUserContext } from '../Session';
import { formatDate } from '../../helpers/ui';
import images from '../../constants/images';
import CardsLoader from '../ContentLoaders/cardsLoader';
import firebase from 'firebase/app';
// import firebase from 'firebase';
// import 'firebase/firestore';
// import * as geofirestore from 'geofirestore';
// import Distance from '../UI/distance';

import './featuredListings.scss';
class FeaturedListings extends Component {
  static contextType = AuthUserContext;
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      listings: [],
      limit: 6,
    };
  }
  componentDidMount() {
    this.onListenForFeaturedListings();
  }

setListingasExpired(listing) {
	const db = firebase.firestore();
    const docRef = db.collection('listings').doc(listing);
    docRef.update({ postStatus: 'expired' });
 }

  onListenForFeaturedListings = () => {
    this.setState({ loading: true });
    this.unsubscribe = this.props.firebase
      .listings()
      .where('postStatus', '==', 'live')
      //   .where(
      //     'postEndDate',
      //     '>',
      //     yourDate.toISOString().split('T')[0],
      //   )
      .orderBy('createdAt', 'desc')
      //   .orderBy('postEndDate', 'desc')
      .limit(this.state.limit)
      .onSnapshot((snapshot) => {
        if (snapshot.size) {
          let listings = [];
          snapshot.forEach((doc) =>
            listings.push({ ...doc.data(), uid: doc.id }),
          );

          this.setState({
            listings: listings,
            total: listings.length,
            loading: false,
          });
        } else {
          this.setState({ listings: null, loading: false });
        }
      });
  };

  render = () => {
    const { listings, loading } = this.state;
    return (
      <>
        <AuthUserContext.Consumer>
          {(authUser) =>
            authUser ? (
              <div className="fw grad4 sect-pad-top sect-pad-bot">
                <div className="container">
                  <div className="row block-titles">
                    <div className="col-sm-12">
                      <h2>Recent listings</h2>
                      {loading && (
                        <div className="row block-titles">
                          <div className="col-sm-12 col-md-4 eblock-wrap">
                            <CardsLoader />
                          </div>
                          <div className="col-sm-12 col-md-4 eblock-wrap">
                            <CardsLoader />
                          </div>
                          <div className="col-sm-12 col-md-4 eblock-wrap">
                            <CardsLoader />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row mt-3">
                    {!listings && <h1>There are no listings ...</h1>}
                    {listings &&
                      listings.map((listing) =>
                        listing.postType === 'event' ? (
                          Date.parse(listing.postEndDate) >
                          Date.parse(new Date()) ? (
                            <>
                              <div
                                className={`col-sm-12 col-md-4 eblock-wrap ${listing.postType}`}
                              >
                                {listing.postType == 'attraction' ? (
                                  <UserFavourites
                                    listing={listing.uid}
                                  />
                                ) : (
                                  <UserInterested
                                    listing={listing.uid}
                                  />
                                )}
                                <Link
                                  to={`/${listing.postType}/?listing=${listing.uid}`}
                                  className="eblock"
                                >
                                  <div className="eblock-img">
                                    {/* <div className="featured-tag">Featured</div> */}
                                    {/* <Distance
										listing={listing}
										type="blockListing"
										/> */}
                                    {listing.postThumbnails[0] && (
                                      <img
                                        src={
                                          listing.postThumbnails[0]
                                        }
                                        className="resp-img"
                                      />
                                    )}
                                    {!listing.postThumbnails[0] && (
                                      <img
                                        src={images.placeHolder}
                                        className="resp-img"
                                      />
                                    )}
                                  </div>

                                  <div className="eblock-dets">
                                    {listing.postType === 'event' && (
                                      <ul>
                                        <li className="date">
                                          {formatDate(
                                            listing.postStartDate,
                                          ).slice(0, -5)}
                                        </li>
                                        <li
                                          className={`time ${
                                            listing.postStartTime
                                              ? ''
                                              : 'd-none'
                                          }`}
                                        >
                                          {listing.postStartTime &&
                                            listing.postStartTime}
                                          {listing.postEndTime &&
                                            `- ${listing.postEndTime}`}
                                        </li>
                                      </ul>
                                    )}
                                    <h3>{listing.postTitle}</h3>
                                    <p>
                                      {listing.postAddressTownCity}
                                      {`, ${listing.postAddressCounty}`}
                                    </p>
                                  </div>
                                </Link>
                              </div>
                            </>
                          ) : (
                            this.setListingasExpired(listing.uid)
                          )
                        ) : (
                          <>
                            <div
                              className={`col-sm-12 col-md-4 eblock-wrap ${listing.postType}`}
                            >
                              {listing.postType == 'attraction' ? (
                                <UserFavourites
                                  listing={listing.uid}
                                />
                              ) : (
                                <UserInterested
                                  listing={listing.uid}
                                />
                              )}
                              <Link
                                to={`/${listing.postType}/?listing=${listing.uid}`}
                                className="eblock"
                              >
                                <div className="eblock-img">
                                  {/* <div className="featured-tag">Featured</div> */}
                                  {/* <Distance
                              listing={listing}
                              type="blockListing"
                            /> */}
                                  {listing.postThumbnails[0] && (
                                    <img
                                      src={listing.postThumbnails[0]}
                                      className="resp-img"
                                    />
                                  )}
                                  {!listing.postThumbnails[0] && (
                                    <img
                                      src={images.placeHolder}
                                      className="resp-img"
                                    />
                                  )}
                                </div>

                                <div className="eblock-dets">
                                  {listing.postType === 'event' && (
                                    <ul>
                                      <li className="date">
                                        {formatDate(
                                          listing.postStartDate,
                                        ).slice(0, -5)}
                                      </li>
                                      <li
                                        className={`time ${
                                          listing.postStartTime
                                            ? ''
                                            : 'd-none'
                                        }`}
                                      >
                                        {listing.postStartTime &&
                                          listing.postStartTime}
                                        {listing.postEndTime &&
                                          `- ${listing.postEndTime}`}
                                      </li>
                                    </ul>
                                  )}
                                  <h3>{listing.postTitle}</h3>
                                  <p>
                                    {listing.postAddressTownCity}
                                    {`, ${listing.postAddressCounty}`}
                                  </p>
                                </div>
                              </Link>
                            </div>
                          </>
                        ),
                      )}
                  </div>
                </div>
              </div>
            ) : (
              <div className="fw grad4 sect-pad-top sect-pad-bot">
                <div className="container">
                  <div className="row block-titles">
                    <div className="col-7">
                      {!loading && <h2>Featured Listings</h2>}
                      {loading && <h2>Loading Listings...</h2>}
                    </div>
                    {/* {!loading && (
                      <div className="col-5 text-right">
                        <a href="#" className="btn">
                          See all listings
                        </a>
                      </div>
                    )} */}
                  </div>
                  <div className="row mt-3">
                    {!listings && <h1>There are no listings ...</h1>}
                    {listings &&
                      listings.map((listing) =>
                        listing.postType === 'event' ? (
                          Date.parse(listing.postEndDate) >
                          Date.parse(new Date()) ? (
                            <>
                              <div
                                className={`col-sm-12 col-md-4 eblock-wrap ${listing.postType}`}
                              >
                                {listing.postType == 'attraction' ? (
                                  <UserFavourites
                                    listing={listing.uid}
                                  />
                                ) : (
                                  <UserInterested
                                    listing={listing.uid}
                                  />
                                )}
                                <Link
                                  to={`/${listing.postType}/?listing=${listing.uid}`}
                                  className="eblock"
                                >
                                  <div className="eblock-img">
                                    {/* <div className="featured-tag">Featured</div> */}
                                    {/* <Distance
										listing={listing}
										type="blockListing"
										/> */}
                                    {listing.postThumbnails[0] && (
                                      <img
                                        src={
                                          listing.postThumbnails[0]
                                        }
                                        className="resp-img"
                                      />
                                    )}
                                    {!listing.postThumbnails[0] && (
                                      <img
                                        src={images.placeHolder}
                                        className="resp-img"
                                      />
                                    )}
                                  </div>

                                  <div className="eblock-dets">
                                    {listing.postType === 'event' && (
                                      <ul>
                                        <li className="date">
                                          {formatDate(
                                            listing.postStartDate,
                                          ).slice(0, -5)}
                                        </li>
                                        <li
                                          className={`time ${
                                            listing.postStartTime
                                              ? ''
                                              : 'd-none'
                                          }`}
                                        >
                                          {listing.postStartTime &&
                                            listing.postStartTime}
                                          {listing.postEndTime &&
                                            `- ${listing.postEndTime}`}
                                        </li>
                                      </ul>
                                    )}
                                    <h3>{listing.postTitle}</h3>
                                    <p>
                                      {listing.postAddressTownCity}
                                      {`, ${listing.postAddressCounty}`}
                                    </p>
                                  </div>
                                </Link>
                              </div>
                            </>
                          ) : (
                            this.setListingasExpired(listing.uid)
                          )
                        ) : (
                          <>
                            <div
                              className={`col-sm-12 col-md-4 eblock-wrap ${listing.postType}`}
                            >
                              {listing.postType == 'attraction' ? (
                                <UserFavourites
                                  listing={listing.uid}
                                />
                              ) : (
                                <UserInterested
                                  listing={listing.uid}
                                />
                              )}
                              <Link
                                to={`/${listing.postType}/?listing=${listing.uid}`}
                                className="eblock"
                              >
                                <div className="eblock-img">
                                  {/* <div className="featured-tag">Featured</div> */}
                                  {/* <Distance
                              listing={listing}
                              type="blockListing"
                            /> */}
                                  {listing.postThumbnails[0] && (
                                    <img
                                      src={listing.postThumbnails[0]}
                                      className="resp-img"
                                    />
                                  )}
                                  {!listing.postThumbnails[0] && (
                                    <img
                                      src={images.placeHolder}
                                      className="resp-img"
                                    />
                                  )}
                                </div>

                                <div className="eblock-dets">
                                  {listing.postType === 'event' && (
                                    <ul>
                                      <li className="date">
                                        {formatDate(
                                          listing.postStartDate,
                                        ).slice(0, -5)}
                                      </li>
                                      <li
                                        className={`time ${
                                          listing.postStartTime
                                            ? ''
                                            : 'd-none'
                                        }`}
                                      >
                                        {listing.postStartTime &&
                                          listing.postStartTime}
                                        {listing.postEndTime &&
                                          `- ${listing.postEndTime}`}
                                      </li>
                                    </ul>
                                  )}
                                  <h3>{listing.postTitle}</h3>
                                  <p>
                                    {listing.postAddressTownCity}
                                    {`, ${listing.postAddressCounty}`}
                                  </p>
                                </div>
                              </Link>
                            </div>
                          </>
                        ),
                      )}
                  </div>
                </div>
              </div>
            )
          }
        </AuthUserContext.Consumer>
      </>
    );
  };
}

export default compose(withFirebase)(FeaturedListings);
