import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import Geocode from 'react-geocode';
import { getDistance, convertDistance } from 'geolib';

import firebase from 'firebase';
import 'firebase/firestore';
import * as geofirestore from 'geofirestore';

import { withFirebase } from '../Firebase';

class Distance extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      currentLocation: [],
    };
  }

  componentDidMount() {
    if (navigator && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((pos) => {
        const coords = pos.coords;
        this.setState({
          currentLocation: {
            lat: coords.latitude,
            lng: coords.longitude,
          },
        });
      });
    }
    this.onGetListing(this.props.listing);
    Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_KEY);
    Geocode.setRegion('uk');
  }

//   componentWillReceiveProps(nextProps) {
//     this.setState(nextProps);
//   }

  onGetListing = (listing) => {
    this.setState({ loading: true });
    if (navigator && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((pos) => {
        const coords = pos.coords;
        const distance = getDistance(
          {
            latitude: coords.latitude,
            longitude: coords.longitude,
          },
          {
            latitude: listing.coordinates.latitude,
            longitude: listing.coordinates.longitude,
          },
          1,
        );
        const miles = convertDistance(distance, 'mi');
        this.setState({
          currentLocation: {
            lat: coords.latitude,
            lng: coords.longitude,
          },
          distance: `${Math.round(miles.toFixed(2))}mi`,
          loading: false,
        }),
          () => {
            console.log('done');
          };
      });
    }
  };

  render = () => {
    const { distance, type } = this.state;
    return (
      <>
        {distance && (
          <>
            {this.props.type === 'blockListing' && (
              <div className="distance text-center">
                {distance && distance}
              </div>
            )}{' '}
          </>
        )}
        {distance && (
          <>
            {this.props.type === 'singlePage' && (
              <li className="distance">{distance}</li>
            )}
          </>
        )}
      </>
    );
  };
}

export default withFirebase(Distance);
