import React from 'react';
import { init } from 'emailjs-com';

class EmailsSetup extends React.Component {
  componentDidMount() {
    init('user_XzyVLl9ojVvIJBtcrr1F6');
  }
  render() {
    return <></>;
  }
}

export default EmailsSetup;
