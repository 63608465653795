import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import queryString from 'query-string';
import Geocode from 'react-geocode';
import { getDistance, convertDistance } from 'geolib';
import SignupSection from '../SignupSection';
import firebase from 'firebase';
import 'firebase/firestore';
import { AuthUserContext } from '../Session';
import { Carousel } from 'react-bootstrap';
import UserFavourites from '../UI/wishList';
import { Modal, Button } from 'react-bootstrap';
import SignUpForm from '../SignUp/claimListingSignup';

import PostSidebar from './sideBar';
import { withFirebase } from '../Firebase';
import { formatDate } from '../../helpers/ui';
import ListingContentLoader from '../ContentLoaders/listingContentLoader';
import ListingMap from '../Maps/ListingMapSingle';
import Distance from '../UI/distance';
import './back-sharing.scss';
import './listing-content.scss';
import './event-contact.scss';
import './cara-listing.scss';
import './listing-social-links.scss';
import './listing-opening-times.scss';

import Related from '../Related';

class Attraction extends Component {
  static contextType = AuthUserContext;
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      currentLocation: [],
    };
    this.carousel = React.createRef();
  }

  componentDidMount() {
    // console.log('props values', this.props);
    if (navigator && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((pos) => {
        const coords = pos.coords;
        this.setState({
          currentLocation: {
            lat: coords.latitude,
            lng: coords.longitude,
          },
        });
      });
    }

    const searchTerms = queryString.parse(this.props.location.search);
    this.setState({
      listingID: searchTerms.listing,
      hash: searchTerms.hash,
    });
    this.onGetListing(searchTerms.listing);
	  this.setState({ nowShowing: searchTerms.listing });
    Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_KEY);
    Geocode.setRegion('uk');
  }

  claimListing = (userid) => {
    this.props.firebase
      .listings()
      .doc(this.state.listingID)
      .update({ owner: userid });
    this.setState({ successModal: true });
  };

  onGetListing = (listingtoedit) => {
    this.setState({
      loading: true,
      listing: null,
    });
    this.unsubscribe = this.props.firebase
      .listings()
      .doc(listingtoedit)
      .get()
      .then((doc) => {
        this.setState({
          listing: doc.data(),
          loading: false,
        });

        if (navigator && navigator.geolocation) {
          navigator.geolocation.getCurrentPosition((pos) => {
            const coords = pos.coords;
            const distance = getDistance(
              {
                latitude: coords.latitude,
                longitude: coords.longitude,
              },
              {
                latitude: doc.data().coordinates.latitude,
                longitude: doc.data().coordinates.longitude,
              },
              1,
            );
            const miles = convertDistance(distance, 'mi');
            this.setState({
              currentLocation: {
                lat: coords.latitude,
                lng: coords.longitude,
              },
              distance: `${miles.toFixed(2)}mi`,
            });
          });
        }
      });
  };

  render = () => {
    const {
      loading,
      listing,
      listingID,
      hash,
      modalShow,
      successModal,
    } = this.state;
    const authUser = this.context;
    const onPrevClick = () => {
      this.carousel.current.prev();
    };
    const onNextClick = () => {
      this.carousel.current.next();
    };

    const directionButtons = (direction) => {
      return (
        <span
          aria-hidden="true"
          className={
            direction === 'Next' ? 'button-next' : 'button-prev'
          }
        >
          {direction}
        </span>
      );
    };
    return (
      <>
        {loading && <ListingContentLoader />}
        {listing && (
          <Helmet>
            <title>{listing.postTitle} | ToGoDo</title>
          </Helmet>
        )}
        {!loading && listing && (
          <>
            {/* CAROUSEL ---------------------------------------------------------------*/}
            <div
              className={`fw blur-image ${listing.postType}`}
              style={{
                backgroundImage: `url(${listing.postThumbnails[0]})`,
              }}
            >
              <div className="blur-div-behind">
                <div className="container">
                  <div className="row">
                    <div className="col-sm-12">
                      {listing.postThumbnails[1] && (
                        <>
                          <a
                            class="carousel-control-prev"
                            role="button"
                            data-slide="prev"
                            onClick={onPrevClick}
                          ></a>
                          <a
                            class="carousel-control-next"
                            role="button"
                            data-slide="next"
                            onClick={onNextClick}
                          ></a>
                        </>
                      )}
                      <div className="row no-gutters">
                        <div className="col-md-8 col-sm-12">
                          <div
                            id="cara-listing"
                            className="carousel slide"
                            data-ride="carousel"
                          >
                            <Carousel
                              id="reactCarousel"
                              className="carousel-inner"
                              controls={false}
                              ref={this.carousel}
                            >
                              {listing.postThumbnails
                                .filter((n) => n)
                                .map((thumb) => (
                                  <Carousel.Item
                                    className="carousel-item"
                                    style={{
                                      backgroundImage: `url(${thumb})`,
                                    }}
                                  />
                                ))}
                            </Carousel>
                          </div>
                        </div>
                        <div className="col-md-4 col-sm-12">
                          <div className="cara-sidebar grad4">
                            <div className="cara-sidebar-top">
                              <h2>{listing.postTitle}</h2>
                              <h6>
                                {listing.postAddressTownCity &&
                                  listing.postAddressTownCity}
                                {listing.postAddressCounty &&
                                  `, ${listing.postAddressCounty}`}
                                {listing.postAddressPostCode &&
                                  `, ${listing.postAddressPostCode}`}
                              </h6>
                            </div>
                            <div className="fw">
                              <ul>
                                <Distance
                                  listing={listing}
                                  type="singlePage"
                                />
                                <li className="interested">
                                  {authUser && (
                                    <UserFavourites
                                      listing={listingID}
                                    />
                                  )}
                                  {listing.usersFavourited} users
                                  favourited
                                </li>
                              </ul>
                            </div>
                            <div className="fw listing-map">
                              <ListingMap
                                center={[
                                  listing.coordinates.latitude,
                                  listing.coordinates.longitude,
                                ]}
                                postType={listing.postType}
                              />
                            </div>
                            <div className="fw">
                              <a
                                href="#"
                                className="btn get-directions"
                              >
                                Get Directions
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`fw sect-pad-bot white-bg circle-bg ${listing.postType}`}
            >
              <div className="container">
                <div className="row back-sharing">
                  <div className="col-md-6 col-4 back-to-res">
                    <h6>
                      <a
                        href="#"
                        onClick={() => this.props.history.goBack()}
                      >
                        Back <span>to Results</span>
                      </a>
                    </h6>
                  </div>
                  <div className="col-md-6 col-8 text-right social-share">
                    <h6>Share on</h6>
                    <a
                      href={`https://www.facebook.com/sharer/sharer.php?u=https://togodo.co.uk/&t=${listing.postTitle}`}
                      className="facebook"
                      onClick={(e) => {
                        e.preventDefault();
                        window.open(
                          `https://www.facebook.com/sharer/sharer.php?u=https://togodo.co.uk/&t=${listing.postTitle}`,
                          '',
                          'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600',
                        );
                        return false;
                      }}
                    >
                      Facebook
                    </a>
                    <a
                      href={`https://twitter.com/share?url=https://togodo.co.uk/&text=${listing.postTitle}`}
                      className="twitter"
                      onClick={(e) => {
                        e.preventDefault();
                        window.open(
                          `https://twitter.com/share?url=https://togodo.co.uk/&text=${listing.postTitle}`,
                          '',
                          'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600',
                        );
                        return false;
                      }}
                    >
                      Twitter
                    </a>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-8 col-sm-12 first-para">
                    <div className="listing-content">
                      <h2>{listing.pageTitle}</h2>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: listing.pageDescription,
                        }}
                      ></div>
                    </div>
                    {listing.postFacebook ||
                    listing.postTwitter ||
                    listing.postInstagram ? (
                      <div class="social-links fw">
                        <h3>Find us on the following channels:</h3>
                        <ul>
                          {listing.postFacebook.length > 8 ? (
                            <li>
                              <a
                                href={listing.postFacebook}
                                target="_blank"
                                rel="nofollow"
                                class="btn fb-link"
                              >
                                Facebook
                              </a>
                            </li>
                          ) : null}
                          {listing.postTwitter.length > 8 ? (
                            <li>
                              <a
                                href={listing.postTwitter}
                                target="_blank"
                                rel="nofollow"
                                class="btn tw-link"
                              >
                                Twitter
                              </a>
                            </li>
                          ) : null}
                          {listing.postInstagram.length > 8 ? (
                            <li>
                              <a
                                href={listing.postInstagram}
                                target="_blank"
                                rel="nofollow"
                                class="btn in-link"
                              >
                                Instagram
                              </a>
                            </li>
                          ) : null}
                        </ul>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className="col-md-4 col-sm-12 spsideBarWrap">
                    <div className="stickMe">
                      <div
                        className={`fw block listing-contact ${
                          listing.postWebsite ||
                          listing.postTicketsURL ||
                          listing.postPhoneNumber
                            ? ''
                            : 'd-none'
                        }`}
                      >
                        <h3>Quick Links</h3>
                        <ul>
                          {listing.postWebsite.length > 8 ? (
                            <li>
                              <a
                                href={listing.postWebsite}
                                target="_blank"
                                rel="nofollow"
                                className="website-icon"
                              >
                                Visit Website
                              </a>
                            </li>
                          ) : null}

                          {listing.postTicketsURL.length > 8 ? (
                            <li>
                              <a
                                href={listing.postTicketsURL}
                                target="_blank"
                                rel="nofollow"
                                className="ticket-icon"
                              >
                                Buy Tickets
                              </a>
                            </li>
                          ) : null}
                          {listing.postPhoneNumber && (
                            <li>
                              <a
                                href={`tel:${listing.postPhoneNumber}`}
                                className="tel-icon"
                              >
                                {listing.postPhoneNumber}
                              </a>
                            </li>
                          )}
                        </ul>
                      </div>

                      {/* {listing.postThumbnails
                          .filter((n) => n)
                          .map((thumb) => (
                            <Carousel.Item
                              className="carousel-item"
                              style={{
                                backgroundImage: `url(${thumb})`,
                              }}
                            />
						  ))} */}
                      <PostSidebar listing={listing} />
                    </div>
                  </div>
                  <div className="col-12 back-sharing-mob">
                    <div className="row">
                      <div className="col-md-6 col-4 back-to-res">
                        <h6>
                          <a
                            href="#"
                            onClick={() =>
                              this.props.history.goBack()
                            }
                          >
                            Back <span>to Results</span>
                          </a>
                        </h6>
                      </div>
                      <div className="col-md-6 col-8 text-right social-share">
                        <h6>Share on</h6>
                        <a
                          href={`https://www.facebook.com/sharer/sharer.php?u=https://togodo.co.uk/&t=${listing.postTitle}`}
                          className="facebook"
                          onClick={(e) => {
                            e.preventDefault();
                            window.open(
                              `https://www.facebook.com/sharer/sharer.php?u=https://togodo.co.uk/&t=${listing.postTitle}`,
                              '',
                              'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600',
                            );
                            return false;
                          }}
                        >
                          Facebook
                        </a>
                        <a
                          href={`https://twitter.com/share?url=${window.location.href}/&text=${listing.title}`}
                          className="twitter"
                          onClick={(e) => {
                            e.preventDefault();
                            window.open(
                              this.href,
                              '',
                              'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600',
                            );
                            return false;
                          }}
                        >
                          Twitter
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {listing.postStatus == 'draft' && (
              <div className="sticky-draft">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-6 align-items-center d-flex">
                      <h3>Listing preview</h3>
                    </div>
                    <div className="col-6 text-right">
                      <a
                        href={`/edit-listing?edit=${listingID}`}
                        className="btn"
                      >
                        Back to draft
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {listing.ownerHash == hash && (
              <div className="sticky-draft">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-6 align-items-center d-flex">
                      <h3>Claim banner </h3>
                    </div>
                    <div className="col-6 text-right">
                      {!authUser && (
                        <>
                          <a
                            href="#"
                            className="btn setModalShow"
                            onClick={(event) => {
                              event.preventDefault();
                              this.setState({ modalShow: true });
                            }}
                          >
                            Claim listing
                          </a>
                        </>
                      )}
                      {authUser && (
                        <>
                          <a
                            href="#"
                            className="btn setModalShow"
                            onClick={(event) => {
                              //   event.preventDefault();
                              this.claimListing(authUser.uid);
                            }}
                          >
                            Claim listing
                          </a>
                        </>
                      )}
                      {/* <a
                        href={`/claim?claim=${listingID}&hash=${hash}`}
                        className="btn"
                      >
                        Claim that listing
                      </a> */}
                    </div>
                  </div>
                </div>
              </div>
            )}

            <Modal
              show={modalShow}
              id="logModal"
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Body>
                <SignUpForm claimID={listingID} />
              </Modal.Body>
            </Modal>

            <Modal
              show={successModal}
              id="logModal"
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Body id="logModal">
                <div className="userRegister">
                  <div className="container">
                    <div className="row">
                      <div className="col-sm-12">
                        <h2>Listing claimed succesfully!</h2>
                      </div>
                      <h3>Success</h3>
                      <Link to="/my-listings">
                        Click here to get to your dashboard
                      </Link>
                    </div>
                  </div>
                </div>
              </Modal.Body>
            </Modal>

            <Related
              postTitle={listing.postTitle}
              postType={listing.postType}
            />
            <SignupSection />
          </>
        )}
      </>
    );
  };
}

export default withFirebase(Attraction);
