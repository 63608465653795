import React from 'react';

import { withFirebase } from '../Firebase';

const SignOutButton = ({ firebase, classes }) => (
  <button
    type="button"
    onClick={firebase.doSignOut}
    className={classes}
  >
    Sign out
  </button>
);

export default withFirebase(SignOutButton);
