import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

import { Link } from 'react-router-dom';
import queryString from 'query-string';
import Geocode from 'react-geocode';
import * as ROUTES from '../../constants/routes';

import firebase from 'firebase';
import 'firebase/firestore';
import * as geofirestore from 'geofirestore';

import { AuthUserContext } from '../Session';
import { withFirebase } from '../Firebase';
import { formatDate } from '../../helpers/ui';
import images from '../../constants/images';
import ListingContentLoader from '../ContentLoaders/listingContentLoader';
import InnerBanner from '../Dashboard/innerBanner';
import PostEditor from '../TextEditor';

import ListingMap from '../Maps/addListingMap';
import PostImages from '../ImageCrop';
import RingCounter from '../UI/ringCounter';
import { splitDay } from '../../helpers/ui';
import { NotifyUser } from '../UI/theNotification';
import emailjs from 'emailjs-com';
import './add-listing-page.scss';

import usePlacesAutocomplete, {
  getGeocode,
  getDetails,
  getZipCode,
  getLatLng,
} from 'use-places-autocomplete';
import useOnclickOutside from 'react-cool-onclickoutside';


class EditListing extends Component {
  static contextType = AuthUserContext;
  constructor(props) {
    super(props);

    this.state = {
      text: '',
      loading: false,
      editlisting: [],
      thumbs: [],
      activePostType: 'event',
      limit: 1,
      postTitle: '',
      pageDescription: '',
      lat: '',
      lng: '',

      pageTitle: '',
      postThumbnails: '',
      postWebsite: '',
      postTicketsURL: '',
      postFacebook: '',
      postTwitter: '',
      postInstagram: '',

      postStartDate: '',
      postEndDate: '',
      postStartTime: '',
      postEndTime: '',

      addressLine: '',
      addressLine: '',
      postal_town: '',
      administrative_area_level_2: '',
      addressCounty: '',
      addressPostCode: '',
      toDay: new Date().toISOString().split('T')[0],

      //   thumb0: '',
      //   thumb1: '',
      //   thumb2: '',
      //   thumb3: '',
      //   thumb4: '',
      //   thumb5: '',
      //   thumb6: '',
      //   thumb7: '',
      //   thumb8: '',
      //   thumb9: '',

      tagCouples: false,
      tagAdults: false,
      tagTeenagers: false,
      tagFamilies: false,
      tagChildren312: false,
      tagBabiesToddlers: false,
      tagPetFriendly: false,
      tagIndoor: false,
      tagOutdoor: false,
      tagSeasonal: false,
    };

    this.addressInput = React.createRef();

    this.Twitter = React.createRef();
    this.Facebook = React.createRef();
    this.Tickets = React.createRef();
    this.Instagram = React.createRef();
    this.Website = React.createRef();

    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentDidMount() {
    const searchTerms = queryString.parse(this.props.location.search);
    this.onListenForListings(searchTerms.edit);
    this.setState({ nowEditing: searchTerms.edit });
    Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_KEY);
    Geocode.setRegion('uk');
  }

  handleInputChange(event) {
    const target = event.target;
    const value =
      target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  handleLinks = (url) => {
    return !/^https?:\/\//i.test(url) ? `https://${url}` : url;
  };

  onListenForListings = (listingtoedit) => {
    this.setState({ loading: true });
    this.unsubscribe = this.props.firebase
      .listings()
      .doc(listingtoedit)
      .get()
      .then((doc) => {
        // console.log(doc.data());
        this.setState({
          listing: doc.data(),
          loading: false,
        });
      });
  };

  onEditListing = (listing, status) => {
    this.setState({ postEdited: false });
    let postStatus = status;
    let address =
      this.state.addressLine != ''
        ? this.state.addressLine
        : listing.postAddressLine;

    let town =
      this.state.postal_town != ''
        ? this.state.postal_town
        : listing.postAddressTownCity;

    let county =
      this.state.administrative_area_level_2 != ''
        ? this.state.administrative_area_level_2
        : listing.postAddressCounty;

    let postcode =
      this.state.addressPostCode != ''
        ? this.state.addressPostCode
        : listing.postAddressPostCode;

    let lat =
      this.state.lat != ''
        ? this.state.lat
        : listing.g.geopoint.latitude;
    let lng =
      this.state.lng != ''
        ? this.state.lng
        : listing.g.geopoint.longitude;

    // let tagCouples =
    //   this.state.tagCouples != ''
    //     ? this.state.tagCouples
    //     : listing.tagCouples;

    // let tagAdults =
    //   this.state.tagAdults != ''
    //     ? this.state.tagAdults
    //     : listing.tagAdults;

    let thumbnails = [];

    for (let i = 0; i <= 9; i++) {
      const thumb = this.state[`thumb${i}`];
      const thumbnail = thumb ? thumb : listing.postThumbnails[i];
      thumbnails.push(thumbnail);
    }


    for (var i = 0; i < thumbnails.length; i++) {
      thumbnails[i] = thumbnails[i].replace('delete', '');
    }

    let pagetitle =
      this.state.pageTitle != ''
        ? this.state.pageTitle
        : listing.pageTitle;

    let pagedescription =
      this.state.pageDescription != ''
        ? this.state.pageDescription
        : listing.pageDescription;

    let posttitle =
      this.state.postTitle != ''
        ? this.state.postTitle
        : listing.postTitle;

    let everydayOpen = this.state.everydayOpen
      ? this.state.everydayOpen
      : splitDay(listing.days[0].everyday, 0);

    let everydayClose = this.state.everydayClose
      ? this.state.everydayClose
      : splitDay(listing.days[0].everyday, 1);

    let mondayOpen = this.state.mondayOpen
      ? this.state.mondayOpen
      : splitDay(listing.days[0].monday, 0);

    let mondayClose = this.state.mondayClose
      ? this.state.mondayClose
      : splitDay(listing.days[0].monday, 1);

    let tuesdayOpen = this.state.tuesdayOpen
      ? this.state.tuesdayOpen
      : splitDay(listing.days[0].tuesday, 0);

    let tuesdayClose = this.state.tuesdayClose
      ? this.state.tuesdayClose
      : splitDay(listing.days[0].tuesday, 1);

    let wednesdayOpen = this.state.wednesdayOpen
      ? this.state.wednesdayOpen
      : splitDay(listing.days[0].wednesday, 0);

    let wednesdayClose = this.state.wednesdayClose
      ? this.state.wednesdayClose
      : splitDay(listing.days[0].wednesday, 1);

    let thursdayOpen = this.state.thursdayOpen
      ? this.state.thursdayOpen
      : splitDay(listing.days[0].thursday, 0);

    let thursdayClose = this.state.thursdayClose
      ? this.state.thursdayClose
      : splitDay(listing.days[0].thursday, 1);

    let fridayOpen = this.state.fridayOpen
      ? this.state.fridayOpen
      : splitDay(listing.days[0].friday, 0);

    let fridayClose = this.state.fridayClose
      ? this.state.fridayClose
      : splitDay(listing.days[0].friday, 1);

    let saturdayOpen = this.state.saturdayOpen
      ? this.state.saturdayOpen
      : splitDay(listing.days[0].saturday, 0);

    let saturdayClose = this.state.saturdayClose
      ? this.state.saturdayClose
      : splitDay(listing.days[0].saturday, 1);

    let sundayOpen = this.state.sundayOpen
      ? this.state.sundayOpen
      : splitDay(listing.days[0].sunday, 0);

    let sundayClose = this.state.sundayClose
      ? this.state.sundayClose
      : splitDay(listing.days[0].sunday, 1);

    let startDate =
      this.state.postStartDate != ''
        ? this.state.postStartDate
        : listing.postStartDate;

    let endDate =
      this.state.postEndDate != ''
        ? this.state.postEndDate
        : listing.postEndDate;

    let startTime =
      this.state.postStartTime != ''
        ? this.state.postStartTime
        : listing.postStartTime;
    let endTime =
      this.state.postEndTime != ''
        ? this.state.postEndTime
        : listing.postEndTime;

    const firestore = firebase.firestore();
    const GeoFirestore = geofirestore.initializeApp(firestore);
    const geocollection = GeoFirestore.collection('listings').doc(
      this.state.nowEditing,
    );
    geocollection.update({
      postStatus: postStatus,
      editedAt: this.props.firebase.fieldValue.serverTimestamp(),
      coordinates: new firebase.firestore.GeoPoint(lat, lng),
      postTitle: posttitle,
      postAddressPostCode: postcode,
      postAddressLine: address,
      postAddressTownCity: town,
      postAddressCounty: county,
      postThumbnails: thumbnails,
      pageTitle: pagetitle,
      pageDescription: pagedescription,
      //tags
      tagCouples: listing.tagCouples ? listing.tagCouples : this.state.tagCouples,
      tagAdults: listing.tagAdults ? listing.tagAdults : this.state.tagAdults,
      tagTeenagers: listing.tagTeenagers ? listing.tagTeenagers : this.state.tagTeenagers,
      tagFamilies: listing.tagFamilies ? listing.tagFamilies : this.state.tagFamilies,
      tagChildren312: listing.tagChildren312 ? listing.tagChildren312 : this.state.tagChildren312,
	  tagBabiesToddlers: listing.tagBabiesToddlers ? listing.tagBabiesToddlers : this.state.tagBabiesToddlers,
      tagPetFriendly: listing.tagPetFriendly ? listing.tagPetFriendly : this.state.tagPetFriendly,
      tagIndoor: listing.tagIndoor ? listing.tagIndoor : this.state.tagIndoor,
      tagOutdoor: listing.tagOutdoor ? listing.tagOutdoor : this.state.tagOutdoor,
      tagSeasonal: listing.tagSeasonal ? listing.tagSeasonal : this.state.tagSeasonal,
      //links
      postWebsite: this.handleLinks(this.Website.current.value),
      postTicketsURL: this.handleLinks(this.Tickets.current.value),
      postFacebook: this.handleLinks(this.Facebook.current.value),
      postTwitter: this.handleLinks(this.Twitter.current.value),
      postInstagram: this.handleLinks(this.Instagram.current.value),
      postStartDate: startDate,
      postEndDate: endDate,
      postStartTime: startTime,
      postEndTime: endTime,

      days: [
        {
          everyday: everydayOpen + ' - ' + everydayClose,
          monday: mondayOpen + ' - ' + mondayClose,
          tuesday: tuesdayOpen + ' - ' + tuesdayClose,
          wednesday: wednesdayOpen + ' - ' + wednesdayClose,
          thursday: thursdayOpen + ' - ' + thursdayClose,
          friday: fridayOpen + ' - ' + fridayClose,
          saturday: saturdayOpen + ' - ' + saturdayClose,
          sunday: sundayOpen + ' - ' + sundayClose,
        },
      ],
    });
    this.setState({ postEdited: true, postStatus: postStatus });
    if (status === 'live') {
      this.setState(
        { notification: 'Your listing has been published' },
        () => {
			const authUser = this.context;
			let eMailPostTitle = this.state.postTitle ? this.state.postTitle : listing.postTitle;
			let eMailListingLink = `https://togodo.co.uk/${listing.postType}/?listing=${this.state.nowEditing}`;
          var templateParams = {
            email: authUser.email,
            name: authUser.username,
            listingTitle: eMailPostTitle,
            text: 'your listing has been published',
            listingLink: eMailListingLink,
          };
          emailjs
            .send(
              'service_smt',
              'template_uy80blu',
              templateParams,
              'user_XzyVLl9ojVvIJBtcrr1F6',
            )
            .then(
              function (response) {
                console.log(
                  'SUCCESS!',
                  response.status,
                  response.text,
                );
              },
              function (error) {
                console.log('FAILED...', error);
              },
            );
          this.props.history.push(ROUTES.MYLISTINGS);
        },
        () => {
          this.props.history.push(ROUTES.MYLISTINGS);
        },
      );
    }
  };

  onRemoveListing = (uid) => {
    this.props.firebase.listing(uid).delete();
  };

  activeToggle = (postType) => {
    this.setState({ activePostType: postType });
  };

  async geoLocation(location) {
    await Geocode.fromAddress(location).then((res) => {
      const { lat, lng } = res.results[0].geometry.location;
      this.setState({
        lat: lat,
        lng: lng,
      });
    });
  }

  onChangeAddress = (event) => {
    let location = event.target.value;
    if (location !== '' && location.length > 3) {
      this.geoLocation(
        this.state.addressLine +
          ',' +
          this.state.addressTownCity +
          ',' +
          this.state.addressCounty,
      );
    }
  };

  //   parentFunction = (imageURL, number) => {
  //     let thumb = `thumb${number}`;
  //     this.setState({ [thumb]: imageURL });
  //   };

  parentFunction = (imageURL, number, action) => {
    // console.log(imageURL, number, action);
    let thumb = `thumb${number}`;
    this.setState({ [thumb]: imageURL, number: number });
    let num = number + 1;
    let element = document.getElementById(`listing-image-${num}`);
    // if (!action) {
    //   element.classList.remove('hidden');
    // } else {
    //   element.classList.add('hidden');
    // }
    if (action === 'delete') {
      //   console.log('delete');
      //   this.setState({ [thumb]: {} });
      const firestore = firebase.firestore();
      const GeoFirestore = geofirestore.initializeApp(firestore);
      const geocollection = GeoFirestore.collection('listings').doc(
        this.state.nowEditing,
      );

			
	this.unsubscribe = this.props.firebase
        .listings()
        .doc(this.state.nowEditing)
        .get()
        .then((doc) => {
          this.setState({ [thumb]: 'delete' });
          let thumbnails = [];
          for (let i = 0; i < 10; i++) {
            const thumb = `thumb${i}`;
            const thumbnail =
              this.state[thumb] === 'delete'
                ? ''
                : doc.data().postThumbnails[i];
            thumbnails.push(thumbnail);
          }
          this.setState({ thumbnails });
        });

          geocollection.update({
            postThumbnails: thumbnails,
          });

          this.setState({ [thumb]: 'delete' });
        
    }
  };

  postEditorData = (data) => {
    this.setState({ pageDescription: data });
  };

  render = () => {
    const PlacesAutocomplete = () => {
      const {
        ready,
        value,
        suggestions: { status, data },
        setValue,
        clearSuggestions,
      } = usePlacesAutocomplete({
        requestOptions: {
          /* Define search scope here */
          componentRestrictions: { country: ['uk'] },
        },
        debounce: 300,
        cache: 24 * 60 * 60,
      });
      const ref = useOnclickOutside(() => {
        // When user clicks outside of the component, we can dismiss
        // the searched suggestions by calling this method
        clearSuggestions();
      });

      const handleInput = (e) => {
        // Update the keyword of the input element
        setValue(e.target.value);
      };

      const handleSelect =
        ({ description }) =>
        () => {
          // When user selects a place, we can replace the keyword without request data from API
          // by setting the second parameter to "false"
          setValue(description, false);
          clearSuggestions();

          // Get latitude and longitude via utility functions
          getGeocode({ address: description })
            .then((results) => getLatLng(results[0]))
            .then(({ lat, lng }) => {
              console.log('📍 Coordinates: ', { lat, lng });
              this.setState({ addressLine: description });
              this.geoLocation(description);
            })
            .catch((error) => {
              console.log('😱 Error: ', error);
            });

          // Get latitude and longitude via utility functions
          getGeocode({ address: description })
            .then(
              (results) =>
                results[0]['address_components'].map(
                  (addressPart) => {
                    // alert(addressPart['types'][0]);
                    this.setState({
                      [addressPart['types'][0]]:
                        addressPart['long_name'],
                    });
                    console.log(this.state);
                  },
                ),
              //   console.log(
              //     results,
              //     // results[0]['address_components'][0]['types'][0],
              //     // results[0]['address_components'][0]['long_name']['type'],
              //     // results[0]['address_components'][1]['long_name'],
              //     // results[0]['address_components'][2]['long_name'],
              //     // results[0]['address_components'][3]['long_name'],
              //     // results[0]['address_components'][4]['long_name'],
              //   ),
            )
            .catch((error) => {
              console.log('😱 Error: ', error);
            });

          getGeocode({ address: description })
            .then((results) => getZipCode(results[0], false))
            .then((zipCode) => {
              // console.log('ZIP Code: ', zipCode);
              this.setState({ addressPostCode: zipCode });
            })
            .catch((error) => {
              console.log('😱 Error: ', error);
            });

          getDetails({ address: description })
            .then((details) => {
              console.log('Details: ', details);
            })
            .catch((error) => {
              console.log('Error: ', error);
            });
        };

      const renderSuggestions = () =>
        data.map((suggestion) => {
          const {
            place_id,
            structured_formatting: { main_text, secondary_text },
          } = suggestion;

          return (
            <li key={place_id} onClick={handleSelect(suggestion)}>
              <strong>{main_text}</strong>{' '}
              <small>{secondary_text}</small>
            </li>
          );
        });

      return (
        <form ref={ref}>
          <input
            value={value}
            onChange={handleInput}
            name="addressLine"
            disabled={!ready}
            autocomplete="off"
            placeholder={
              this.state.addressLine
                ? this.state.addressLine
                : listing.postAddressLine
            }
            className="form-control"
          />
          {status === 'OK' && (
            <ul className="auto-complete-list">
              {renderSuggestions()}
            </ul>
          )}
        </form>
      );
    };
    const {
      text,
      listings,
      listing,
      loading,
      activePostType,
      lat,
      lng,
      postEdited,
      postStatus,
      nowEditing,
      thumb1,
      toDay,
    } = this.state;

    return (
      <>
        <Helmet>
          <title>Edit listing | ToGoDo</title>
        </Helmet>
        {listing && (
          <Helmet>
            <title>Edit {listing.postTitle} | ToGoDo</title>
          </Helmet>
        )}
        {!loading && listing && (
          <InnerBanner
            className={listing.postType}
            listing={(null, false, listing)}
          />
        )}
        {postEdited && postStatus != 'live' && (
          <NotifyUser
            title="Success"
            message="Your listing has been saved"
            status="success"
          />
        )}
        {postEdited && postStatus == 'live' && (
          <NotifyUser
            title="Success"
            message="Your listing has been published"
            status="success"
          />
        )}

        {loading && <ListingContentLoader />}
        {!loading && listing && (
          <AuthUserContext.Consumer>
            {(authUser) => (
              <div id={`editListing ${listing.postType}`}>
                {!loading && listings && (
                  <button
                    type="button"
                    onClick={this.onNextPage}
                    className="d-none"
                  >
                    More
                  </button>
                )}
                <form
                  className="d-none"
                  onSubmit={(event) =>
                    this.onCreateListing(event, authUser)
                  }
                >
                  <input
                    type="text"
                    value={text}
                    onChange={this.onChangeText}
                  />
                  <button type="submit">Send</button>
                </form>

                <div
                  className={`fw white-bg sect-pad-top sect-pad-bot step-process ${listing.postType}`}
                >
                  <div className="container listing-details-wrap">
                    <div className="row">
                      <div className="col-sm-12 col-md-8">
                        <div className="row">
                          <div className="col-sm-12">
                            <h2>Listing details</h2>
                            <h3>
                              {listing.postType} name{' '}
                              {listing.postType != 'attraction'
                                ? 'and date'
                                : ''}
                            </h3>
                          </div>
                          <div className="col-sm-12">
                            <label>{listing.postType} name</label>
                            <input
                              type="text"
                              className="form-control"
                              maxLength="50"
                              spellCheck="false"
                              name="postTitle"
                              value={
                                this.state.postTitle
                                  ? this.state.postTitle
                                  : listing.postTitle
                              }
                              onChange={this.handleInputChange}
                            />
                            {this.state.postTitle && (
                              <RingCounter
                                countThis={this.state.postTitle}
                                setMaxTo={50}
                                key={this.state.postTitle}
                              />
                            )}
                            <span className="charCounter"></span>
                            {/* Need to add a JS character counter here and limit characters on title to 50 characters*/}
                          </div>
                          {listing.postType != 'attraction' ? (
                            <>
                              <div className="col-sm-12 col-md-6">
                                <label>
                                  {listing.postType} start date
                                </label>
                                <input
                                  type="date"
                                  className="form-control"
                                  placeholder="DD/MM/YYYY"
                                  name="postStartDate"
                                  min={toDay}
                                  value={
                                    this.state.postStartDate
                                      ? this.state.postStartDate
                                      : listing.postStartDate
                                  }
                                  onChange={this.handleInputChange}
                                />
                              </div>
                              <div className="col-sm-12 col-md-6">
                                <label>
                                  {listing.postType} end date
                                </label>
                                <input
                                  type="date"
                                  className="form-control"
                                  placeholder="DD/MM/YYYY"
                                  name="postEndDate"
                                  min={
                                    this.state.postStartDate
                                      ? this.state.postStartDate
                                      : listing.postStartDate
                                  }
                                  value={
                                    this.state.postEndDate
                                      ? this.state.postEndDate
                                      : listing.postEndDate
                                  }
                                  onChange={this.handleInputChange}
                                />
                              </div>{' '}
                            </>
                          ) : (
                            ''
                          )}
                          {activePostType == 'event' &&
                            listing.postType != 'attraction' && toDay && (
                              <>
                                <div className="col-sm-12 col-md-6">
                                  <label>
                                    {activePostType} start time
                                  </label>
                                  <input
                                    type="time"
                                    className="form-control"
                                    name="postStartTime"
                                    value={
                                      this.state.postStartTime
                                        ? this.state.postStartTime
                                        : listing.postStartTime
                                    }
                                    onChange={this.handleInputChange}
                                  />
                                </div>
                                <div className="col-sm-12 col-md-6">
                                  <label>
                                    {activePostType} end time
                                  </label>
                                  <input
                                    type="time"
                                    className="form-control"
                                    name="postEndTime"
                                    value={
                                      this.state.postEndTime
                                        ? this.state.postEndTime
                                        : listing.postEndTime
                                    }
                                    onChange={this.handleInputChange}
                                  />
                                </div>
                              </>
                            )}
                        </div>
                        <div className="row d-none">
                          <div className="col-sm-12">
                            <h3>Listing start date</h3>
                            <p>
                              <em>
                                The date you want your listing to
                                commence on Togodo
                              </em>
                            </p>
                          </div>
                          <div className="col-sm-12 col-md-6">
                            <input
                              type="date"
                              className="form-control"
                              placeholder="DD/MM/YYYY"
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-12">
                            <h3>{listing.postType} location</h3>
                          </div>

                          <div className="col-sm-12">
                            <label>Address line 1</label>
                            <PlacesAutocomplete />
                            {/* <input
                              type="text"
                              className="form-control"
                              name="addressLine"
                              autocomplete="off"
                              ref={this.addressInput}
                              onBlur={this.onChangeAddress.bind(this)}
                              onChange={this.handleInputChange}
                              defaultValue={
                                this.state.postAddressLine
                                  ? this.state.postAddressLine
                                  : listing.postAddressLine
                              }
                              required
                            /> */}
                            {/* <input
                            className="form-control custom-input-fields"
                            type="text"
                            id="address"
                            name="address"
                            // placeholder="Address"
                            ref={this.addressInput}
                            //onChange={this.onChangeAddress.bind(this)}
                            onBlur={this.onChangeAddress.bind(this)}
                            required
                          /> */}
                          </div>
                          <div className="col-sm-12 col-md-6">
                            <label>Town / City</label>
                            <input
                              type="text"
                              className="form-control"
                              name="postal_town"
                              // onBlur={this.onChangeAddress.bind(
                              //   this,
                              // )}
                              placeholder={
                                this.state.postal_town
                                  ? this.state.postal_town
                                  : listing.postAddressTownCity
                              }
                              onChange={this.handleInputChange}
                            />
                          </div>
                          <div className="col-sm-12 col-md-6">
                            <label>County</label>
                            <input
                              type="text"
                              className="form-control"
                              name="administrative_area_level_2"
                              // onBlur={this.onChangeAddress.bind(
                              //   this,
                              // )}
                              placeholder={
                                this.state.administrative_area_level_2
                                  ? this.state
                                      .administrative_area_level_2
                                  : listing.postAddressCounty
                              }
                              onChange={this.handleInputChange}
                            />
                          </div>
                          <div className="col-sm-12 col-md-6">
                            <label>Postcode</label>
                            <input
                              type="text"
                              className="form-control"
                              name="addressPostCode"
                              placeholder={
                                this.state.addressPostCode
                                  ? this.state.addressPostCode
                                  : listing.postAddressPostCode
                              }
                              // onBlur={this.onChangeAddress.bind(
                              //   this,
                              // )}
                              onChange={this.handleInputChange}
                            />
                          </div>

                          {listing.postType == 'attraction' && (
                            <>
                              <div className="col-sm-12">
                                <h3>Opening Hours</h3>
                              </div>

                              <div className="col-sm-12">
                                <div className="row">
                                  <div className="col-sm-12">
                                    <h5>Everyday</h5>
                                  </div>
                                  <div className="col-sm-12 col-md-6 time-wrap">
                                    <p>
                                      <label>Open at:</label>
                                    </p>
                                    <input
                                      type="time"
                                      name="everydayOpen"
                                      defaultValue={
                                        this.state.everydayOpen
                                          ? this.state.everydayOpen
                                          : splitDay(
                                              listing.days[0]
                                                .everyday,
                                              0,
                                            )
                                      }
                                      onChange={
                                        this.handleInputChange
                                      }
                                    />
                                  </div>
                                  <div className="col-sm-12 col-md-6 time-wrap">
                                    <p>
                                      <label>Close at:</label>
                                    </p>
                                    <input
                                      type="time"
                                      name="everydayClose"
                                      defaultValue={
                                        this.state.everydayClose
                                          ? this.state.everydayClose
                                          : splitDay(
                                              listing.days[0]
                                                .everyday,
                                              1,
                                            )
                                      }
                                      onChange={
                                        this.handleInputChange
                                      }
                                    />
                                  </div>
                                </div>
                              </div>

                              {!this.state.everydayClose &&
                                listing.days[0].everyday && (
                                  <>
                                    <div className="col-sm-12 col-md-6">
                                      <div className="row">
                                        <div className="col-sm-12">
                                          <h5>Monday</h5>
                                        </div>
                                        <div className="col-sm-12 col-md-6 time-wrap">
                                          <p>
                                            <label>Open at:</label>
                                          </p>
                                          <input
                                            type="time"
                                            name="mondayOpen"
                                            defaultValue={
                                              this.state.mondayClose
                                                ? this.state
                                                    .mondayClose
                                                : splitDay(
                                                    listing.days[0]
                                                      .monday,
                                                    0,
                                                  )
                                            }
                                            onChange={
                                              this.handleInputChange
                                            }
                                          />
                                        </div>
                                        <div className="col-sm-12 col-md-6 time-wrap">
                                          <p>
                                            <label>Close at:</label>
                                          </p>
                                          <input
                                            type="time"
                                            name="mondayClose"
                                            defaultValue={
                                              this.state.mondayClose
                                                ? this.state
                                                    .mondayClose
                                                : splitDay(
                                                    listing.days[0]
                                                      .monday,
                                                    1,
                                                  )
                                            }
                                            onChange={
                                              this.handleInputChange
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-sm-12 col-md-6">
                                      <div className="row">
                                        <div className="col-sm-12">
                                          <h5>Tuesday</h5>
                                        </div>
                                        <div className="col-sm-12 col-md-6 time-wrap">
                                          <p>
                                            <label>Open at:</label>
                                          </p>
                                          <input
                                            type="time"
                                            name="tuesdayOpen"
                                            defaultValue={
                                              this.state.tuesdayClose
                                                ? this.state
                                                    .tuesdayClose
                                                : splitDay(
                                                    listing.days[0]
                                                      .tuesday,
                                                    0,
                                                  )
                                            }
                                            onChange={
                                              this.handleInputChange
                                            }
                                          />
                                        </div>
                                        <div className="col-sm-12 col-md-6 time-wrap">
                                          <p>
                                            <label>Close at:</label>
                                          </p>
                                          <input
                                            type="time"
                                            name="tuesdayClose"
                                            defaultValue={
                                              this.state.tuesdayClose
                                                ? this.state
                                                    .tuesdayClose
                                                : splitDay(
                                                    listing.days[0]
                                                      .tuesday,
                                                    1,
                                                  )
                                            }
                                            onChange={
                                              this.handleInputChange
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-sm-12 col-md-6">
                                      <div className="row">
                                        <div className="col-sm-12">
                                          <h5>Wednesday</h5>
                                        </div>
                                        <div className="col-sm-12 col-md-6 time-wrap">
                                          <p>
                                            <label>Open at:</label>
                                          </p>
                                          <input
                                            type="time"
                                            name="wednesdayOpen"
                                            defaultValue={
                                              this.state
                                                .wednesdayClose
                                                ? this.state
                                                    .wednesdayClose
                                                : splitDay(
                                                    listing.days[0]
                                                      .wednesday,
                                                    0,
                                                  )
                                            }
                                            onChange={
                                              this.handleInputChange
                                            }
                                          />
                                        </div>
                                        <div className="col-sm-12 col-md-6 time-wrap">
                                          <p>
                                            <label>Close at:</label>
                                          </p>
                                          <input
                                            type="time"
                                            name="wednesdayClose"
                                            defaultValue={
                                              this.state
                                                .wednesdayClose
                                                ? this.state
                                                    .wednesdayClose
                                                : splitDay(
                                                    listing.days[0]
                                                      .wednesday,
                                                    1,
                                                  )
                                            }
                                            onChange={
                                              this.handleInputChange
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-sm-12 col-md-6">
                                      <div className="row">
                                        <div className="col-sm-12">
                                          <h5>Thursday</h5>
                                        </div>
                                        <div className="col-sm-12 col-md-6 time-wrap">
                                          <p>
                                            <label>Open at:</label>
                                          </p>
                                          <input
                                            type="time"
                                            name="thursdayOpen"
                                            defaultValue={
                                              this.state.thursdayClose
                                                ? this.state
                                                    .thursdayClose
                                                : splitDay(
                                                    listing.days[0]
                                                      .thursday,
                                                    0,
                                                  )
                                            }
                                            onChange={
                                              this.handleInputChange
                                            }
                                          />
                                        </div>
                                        <div className="col-sm-12 col-md-6 time-wrap">
                                          <p>
                                            <label>Close at:</label>
                                          </p>
                                          <input
                                            type="time"
                                            name="thursdayClose"
                                            defaultValue={
                                              this.state.thursdayClose
                                                ? this.state
                                                    .thursdayClose
                                                : splitDay(
                                                    listing.days[0]
                                                      .thursday,
                                                    1,
                                                  )
                                            }
                                            onChange={
                                              this.handleInputChange
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-sm-12 col-md-6">
                                      <div className="row">
                                        <div className="col-sm-12">
                                          <h5>Friday</h5>
                                        </div>
                                        <div className="col-sm-12 col-md-6 time-wrap">
                                          <p>
                                            <label>Open at:</label>
                                          </p>
                                          <input
                                            type="time"
                                            name="fridayOpen"
                                            defaultValue={
                                              this.state.fridayClose
                                                ? this.state
                                                    .fridayClose
                                                : splitDay(
                                                    listing.days[0]
                                                      .friday,
                                                    0,
                                                  )
                                            }
                                            onChange={
                                              this.handleInputChange
                                            }
                                          />
                                        </div>
                                        <div className="col-sm-12 col-md-6 time-wrap">
                                          <p>
                                            <label>Close at:</label>
                                          </p>
                                          <input
                                            type="time"
                                            name="fridayClose"
                                            defaultValue={
                                              this.state.fridayClose
                                                ? this.state
                                                    .fridayClose
                                                : splitDay(
                                                    listing.days[0]
                                                      .friday,
                                                    1,
                                                  )
                                            }
                                            onChange={
                                              this.handleInputChange
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-sm-12 col-md-6">
                                      <div className="row">
                                        <div className="col-sm-12">
                                          <h5>Saturday</h5>
                                        </div>
                                        <div className="col-sm-12 col-md-6 time-wrap">
                                          <p>
                                            <label>Open at:</label>
                                          </p>
                                          <input
                                            type="time"
                                            name="saturdayOpen"
                                            defaultValue={
                                              this.state.saturdayClose
                                                ? this.state
                                                    .saturdayClose
                                                : splitDay(
                                                    listing.days[0]
                                                      .saturday,
                                                    0,
                                                  )
                                            }
                                            onChange={
                                              this.handleInputChange
                                            }
                                          />
                                        </div>
                                        <div className="col-sm-12 col-md-6 time-wrap">
                                          <p>
                                            <label>Close at:</label>
                                          </p>
                                          <input
                                            type="time"
                                            name="saturdayClose"
                                            defaultValue={
                                              this.state.saturdayClose
                                                ? this.state
                                                    .saturdayClose
                                                : splitDay(
                                                    listing.days[0]
                                                      .saturday,
                                                    1,
                                                  )
                                            }
                                            onChange={
                                              this.handleInputChange
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-sm-12 col-md-6">
                                      <div className="row">
                                        <div className="col-sm-12">
                                          <h5>Sunday</h5>
                                        </div>
                                        <div className="col-sm-12 col-md-6 time-wrap">
                                          <p>
                                            <label>Open at:</label>
                                          </p>
                                          <input
                                            type="time"
                                            name="sundayOpen"
                                            defaultValue={
                                              this.state.sundayClose
                                                ? this.state
                                                    .sundayClose
                                                : splitDay(
                                                    listing.days[0]
                                                      .sunday,
                                                    0,
                                                  )
                                            }
                                            onChange={
                                              this.handleInputChange
                                            }
                                          />
                                        </div>
                                        <div className="col-sm-12 col-md-6 time-wrap">
                                          <p>
                                            <label>Close at:</label>
                                          </p>
                                          <input
                                            type="time"
                                            name="sundayClose"
                                            defaultValue={
                                              this.state.sundayClose
                                                ? this.state
                                                    .sundayClose
                                                : splitDay(
                                                    listing.days[0]
                                                      .sunday,
                                                    1,
                                                  )
                                            }
                                            onChange={
                                              this.handleInputChange
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}
                            </>
                          )}
                          <div className="col-sm-12 listingMapContainer">
                            {lat && lng && (
                              <ListingMap center={[lat, lng]} />
                            )}
                            {!lat && !lng && (
                              <ListingMap
                                center={[
                                  listing.coordinates.latitude,
                                  listing.coordinates.longitude,
                                ]}
                              />
                            )}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-12">
                            <h3>{listing.postType} images</h3>
                            {Array.apply(null, { length: 10 }).map(
                              (e, i) => (
                                // listing.postThumbnails[i] ? (
                                <>
                                  <div
                                  // id={`listing-image-${i}`}
                                  //  className={i ? `` : `hidden`}
                                  >
                                    {i == 0 ? (
                                      <span className="mainListingImage">
                                        Main listing image:
                                      </span>
                                    ) : (
                                      ''
                                    )}
                                    <PostImages
                                      functionCallFromParent={this.parentFunction.bind(
                                        this,
                                      )}
                                      activePostType={
                                        listing.postType
                                      }
                                      currentUser={authUser.uid}
                                      currentListing={nowEditing}
                                      maxFiles={10}
                                      number={i}
                                      listingImageURL={
                                        listing.postThumbnails[i]
                                      }
                                    />
                                  </div>
                                </>
                                //   )
                                //    : (
                                //     ''
                                //   ))
                              ),
                            )}

                            {/*
                            {Array.apply(null, { length: 10 }).map(
                              (e, i) => (
                                <div
                                  id={`listing-image-${i}`}
                                  className={i === 0 ? `` : `hidden`}
                                >
                                  <PostImages
                                    functionCallFromParent={this.parentFunction.bind(
                                      this,
                                    )}
                                    activePostType={activePostType}
                                    currentUser={authUser.uid}
                                    currentListing={listing.uid}
                                    maxFiles={10}
                                    number={i}
                                    listingImageURL={
                                      listing.postThumbnails[i]
                                    }
                                  />
                                </div>
                              ),
                            )} */}
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-sm-12">
                            <h3 className="listing-details">
                              Listing details page
                            </h3>
                          </div>
                          <div className="col-sm-12">
                            <label>Listing page title</label>
                            <input
                              type="text"
                              className="form-control"
                              name="pageTitle"
                              onChange={this.handleInputChange}
                              defaultValue={
                                this.state.pageTitle
                                  ? this.state.pageTitle
                                  : listing.pageTitle
                              }
                            />
                          </div>
                          <div className="col-sm-12 mb-4">
                            <label>Listing page description</label>
                            <PostEditor
                              value={
                                this.state.pageDescription
                                  ? this.state.pageDescription
                                  : listing.pageDescription
                              }
                              functionCallFromParent={this.postEditorData.bind(
                                this,
                              )}
                            />
                            {this.state.pageDescription && (
                              <RingCounter
                                countThis={this.state.pageDescription}
                                setMaxTo={800}
                                key={this.state.pageDescription}
                              />
                            )}
                            {/* I want this to be a WYSIWYG editor */}
                            {/*
							//TODO: figure out the character counter
						  */}
                            {/* Need to add a JS character counter here and limit characters on title to 800 characters on the free account –&nbsp;Unlimited on premium*/}
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-sm-12">
                            <h3>Within the following categories:</h3>
                          </div>
                          <div className="col-sm-12 col-md-4">
                            <div className="checkbox-wrap">
                              <label htmlFor="tagCouples">
                                Couples
                              </label>
                              <input
                                type="checkbox"
                                id="tagCouples"
                                className={`visually-hidden ${
                                  this.state.tagCouples
                                    ? this.state.tagCouples
                                    : listing.tagCouples
                                }`}
                                name="tagCouples"
                                checked={
                                  this.state.tagCouples
                                    ? this.state.tagCouples
                                    : listing.tagCouples
                                }
                                onClick={(event) => {
                                  listing.tagCouples
                                    ? (listing.tagCouples = false)
                                    : '';
                                  event.stopPropagation();
                                }}
                                onChange={this.handleInputChange}
                              />
                              <div className="control-me" />
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-4">
                            <div className="checkbox-wrap">
                              <label htmlFor="tagAdults">
                                Adults
                              </label>
                              <input
                                type="checkbox"
                                id="tagAdults"
                                className={`visually-hidden ${
                                  this.state.tagAdults
                                    ? this.state.tagAdults
                                    : listing.tagAdults
                                }`}
                                name="tagAdults"
                                checked={
                                  this.state.tagAdults
                                    ? this.state.tagAdults
                                    : listing.tagAdults
                                }
                                onClick={(event) => {
                                  listing.tagAdults
                                    ? (listing.tagAdults = false)
                                    : '';
                                  event.stopPropagation();
                                }}
                                onChange={this.handleInputChange}
                              />
                              <div className="control-me" />
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-4">
                            <div className="checkbox-wrap">
                              <label htmlFor="tagTeenagers">
                                Teenagers
                              </label>
                              <input
                                type="checkbox"
                                id="tagTeenagers"
                                className={`visually-hidden ${
                                  this.state.tagTeenagers
                                    ? this.state.tagTeenagers
                                    : listing.tagTeenagers
                                }`}
                                name="tagTeenagers"
                                checked={
                                  this.state.tagTeenagers
                                    ? this.state.tagTeenagers
                                    : listing.tagTeenagers
                                }
                                onClick={(event) => {
                                  listing.tagTeenagers
                                    ? (listing.tagTeenagers = false)
                                    : '';
                                  event.stopPropagation();
                                }}
                                onChange={this.handleInputChange}
                              />
                              <div className="control-me" />
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-4">
                            <div className="checkbox-wrap">
                              <label htmlFor="tagFamilies">
                                Families
                              </label>
                              <input
                                type="checkbox"
                                id="tagFamilies"
                                className={`visually-hidden ${
                                  this.state.tagFamilies
                                    ? this.state.tagFamilies
                                    : listing.tagFamilies
                                }`}
                                name="tagFamilies"
                                checked={
                                  this.state.tagFamilies
                                    ? this.state.tagFamilies
                                    : listing.tagFamilies
                                }
                                onClick={(event) => {
                                  listing.tagFamilies
                                    ? (listing.tagFamilies = false)
                                    : '';
                                  event.stopPropagation();
                                }}
                                onChange={this.handleInputChange}
                              />
                              <div className="control-me" />
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-4">
                            <div className="checkbox-wrap">
                              <label htmlFor="tagChildren312">
                                Children (3 to 12)
                              </label>
                              <input
                                type="checkbox"
                                id="tagChildren312"
                                className={`visually-hidden ${
                                  this.state.tagChildren312
                                    ? this.state.tagChildren312
                                    : listing.tagChildren312
                                }`}
                                name="tagChildren312"
                                checked={
                                  this.state.tagChildren312
                                    ? this.state.tagChildren312
                                    : listing.tagChildren312
                                }
                                onClick={(event) => {
                                  listing.tagChildren312
                                    ? (listing.tagChildren312 = false)
                                    : '';
                                  event.stopPropagation();
                                }}
                                onChange={this.handleInputChange}
                              />
                              <div className="control-me" />
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-4">
                            <div className="checkbox-wrap">
                              <label htmlFor="tagBabiesToddlers">
                                Babies and Toddlers
                              </label>
                              <input
                                type="checkbox"
                                id="tagBabiesToddlers"
                                className={`visually-hidden ${
                                  this.state.tagBabiesToddlers
                                    ? this.state.tagBabiesToddlers
                                    : listing.tagBabiesToddlers
                                }`}
                                name="tagBabiesToddlers"
                                checked={
                                  this.state.tagBabiesToddlers
                                    ? this.state.tagBabiesToddlers
                                    : listing.tagBabiesToddlers
                                }
                                onClick={(event) => {
                                  listing.tagBabiesToddlers
                                    ? (listing.tagBabiesToddlers = false)
                                    : '';
                                  event.stopPropagation();
                                }}
                                onChange={this.handleInputChange}
                              />
                              <div className="control-me" />
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-4">
                            <div className="checkbox-wrap">
                              <label htmlFor="tagPetFriendly">
                                Dog Friendly
                              </label>
                              <input
                                type="checkbox"
                                id="tagPetFriendly"
                                className={`visually-hidden ${
                                  this.state.tagPetFriendly
                                    ? this.state.tagPetFriendly
                                    : listing.tagPetFriendly
                                }`}
                                name="tagPetFriendly"
                                checked={
                                  this.state.tagPetFriendly
                                    ? this.state.tagPetFriendly
                                    : listing.tagPetFriendly
                                }
                                onClick={(event) => {
                                  listing.tagPetFriendly
                                    ? (listing.tagPetFriendly = false)
                                    : '';
                                  event.stopPropagation();
                                }}
                                onChange={this.handleInputChange}
                              />
                              <div className="control-me" />
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-4">
                            <div className="checkbox-wrap">
                              <label htmlFor="tagIndoor">
                                Indoor
                              </label>
                              <input
                                type="checkbox"
                                id="tagIndoor"
                                className={`visually-hidden ${
                                  this.state.tagIndoor
                                    ? this.state.tagIndoor
                                    : listing.tagIndoor
                                }`}
                                name="tagIndoor"
                                checked={
                                  this.state.tagIndoor
                                    ? this.state.tagIndoor
                                    : listing.tagIndoor
                                }
                                onClick={(event) => {
                                  listing.tagIndoor
                                    ? (listing.tagIndoor = false)
                                    : '';
                                  event.stopPropagation();
                                }}
                                onChange={this.handleInputChange}
                              />
                              <div className="control-me" />
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-4">
                            <div className="checkbox-wrap">
                              <label htmlFor="tagOutdoor">
                                Outdoor
                              </label>
                              <input
                                type="checkbox"
                                id="tagOutdoor"
                                className={`visually-hidden ${
                                  this.state.tagOutdoor
                                    ? this.state.tagOutdoor
                                    : listing.tagOutdoor
                                }`}
                                name="tagOutdoor"
                                checked={
                                  this.state.tagOutdoor
                                    ? this.state.tagOutdoor
                                    : listing.tagOutdoor
                                }
                                onClick={(event) => {
                                  listing.tagOutdoor
                                    ? (listing.tagOutdoor = false)
                                    : '';
                                  event.stopPropagation();
                                }}
                                onChange={this.handleInputChange}
                              />
                              <div className="control-me" />
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-4">
                            <div className="checkbox-wrap">
                              <label htmlFor="tagSeasonal">
                                Seasonal
                              </label>
                              <input
                                type="checkbox"
                                id="tagSeasonal"
                                className={`visually-hidden ${
                                  this.state.tagSeasonal
                                    ? this.state.tagSeasonal
                                    : listing.tagSeasonal
                                }`}
                                name="tagSeasonal"
                                checked={
                                  this.state.tagSeasonal
                                    ? this.state.tagSeasonal
                                    : listing.tagSeasonal
                                }
                                onClick={(event) => {
                                  listing.tagSeasonal
                                    ? (listing.tagSeasonal = false)
                                    : '';
                                  event.stopPropagation();
                                }}
                                onChange={this.handleInputChange}
                              />
                              <div className="control-me" />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-12">
                            <h3>Website and social links</h3>
                          </div>
                          <div className="col-sm-12 col-md-6">
                            <label>Website URL</label>
                            {this.state.postWebsite}
                            <input
                              type="text"
                              className="form-control"
                              name="postWebsite"
                              value={
                                this.state.postWebsite
                                  ? this.handleLinks(
                                      this.state.postWebsite,
                                    )
                                  : this.handleLinks(
                                      listing.postWebsite,
                                    )
                              }
                              ref={this.Website}
                              onChange={this.handleInputChange}
                            />
                          </div>
                          <div className="col-sm-12 col-md-6">
                            <label>Buy tickets URL</label>
                            <input
                              type="text"
                              className="form-control"
                              name="postTicketsURL"
                              ref={this.Tickets}
                              //   defaultValue={this.handleLinks(
                              //     listing.postTicketsURL,
                              //   )}
                              value={
                                this.state.postTicketsURL
                                  ? this.handleLinks(
                                      this.state.postTicketsURL,
                                    )
                                  : this.handleLinks(
                                      listing.postTicketsURL,
                                    )
                              }
                              onChange={this.handleInputChange}
                            />
                          </div>
                          {/* The following social links are only for premium listings */}
                          <div className="col-sm-12 col-md-6">
                            <label>Facebook URL</label>
                            <input
                              type="text"
                              className="form-control"
                              name="postFacebook"
                              onChange={this.handleInputChange}
                              ref={this.Facebook}
                              defaultValue={this.handleLinks(
                                listing.postFacebook,
                              )}
                              value={
                                this.state.postFacebook
                                  ? this.handleLinks(
                                      this.state.postFacebook,
                                    )
                                  : this.handleLinks(
                                      listing.postFacebook,
                                    )
                              }
                            />
                          </div>
                          <div className="col-sm-12 col-md-6">
                            <label>Twitter URL</label>
                            <input
                              type="text"
                              className="form-control"
                              name="postTwitter"
                              ref={this.Twitter}
                              defaultValue={this.handleLinks(
                                listing.postTwitter,
                              )}
                              onChange={this.handleInputChange}
                              value={
                                this.state.postTwitter
                                  ? this.handleLinks(
                                      this.state.postTwitter,
                                    )
                                  : this.handleLinks(
                                      listing.postTwitter,
                                    )
                              }
                            />
                          </div>
                          <div className="col-sm-12 col-md-6">
                            <label>Instagram URL</label>
                            <input
                              type="text"
                              className="form-control"
                              name="postInstagram"
                              ref={this.Instagram}
                              defaultValue={this.handleLinks(
                                listing.postInstagram,
                              )}
                              onChange={this.handleInputChange}
                              value={
                                this.state.postInstagram
                                  ? this.handleLinks(
                                      this.state.postInstagram,
                                    )
                                  : this.handleLinks(
                                      listing.postInstagram,
                                    )
                              }
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            <a
                              href
                              className="btn draft-btn"
                              onClick={(e) => {
                                this.onEditListing(listing, 'draft');
                                e.preventDefault();
                              }}
                            >
                              Save as a Draft
                            </a>
                            {listing && (
                              <a
                                href={`/${listing.postType}/?listing=${this.state.nowEditing}`}
                                className="btn preview-btn"
                                onClick={(e) => {
                                  this.onEditListing(
                                    listing,
                                    'draft',
                                  );
                                  window.location.href =
                                    '/' +
                                    listing.postType +
                                    '?listing=' +
                                    this.state.nowEditing;
                                }}
                              >
                                Preview listing
                              </a>
                            )}
                            <a
                              href
                              className="btn publish-btn"
                              onClick={(e) => {
                                this.onEditListing(listing, 'live');
                                e.preventDefault();
                              }}
                            >
                              Publish Listing
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-4 eblock-wrap sideBarWrap">
                        <Link
                          to={`/${listing.postType}/?listing=${this.state.nowEditing}`}
                          className={`eblock postPreview ${listing.postType}`}
                        >
                          <div className="eblock-img">
                            <div className="distance text-center d-none">
                              {/* 2.6mi */}
                            </div>
                            {!listing.postThumbnails[0] &&
                              !this.state.thumb0 && (
                                <img
                                  src={images.placeHolder}
                                  className="resp-img"
                                />
                              )}
                            {listing.postThumbnails[0] &&
                              !this.state.thumb0 && (
                                <img
                                  src={listing.postThumbnails[0]}
                                  className="resp-img"
                                />
                              )}
                            {!listing.postThumbnails[0] &&
                              this.state.thumb0 && (
                                <img
                                  src={this.state.thumb0}
                                  className="resp-img"
                                />
                              )}
                          </div>
                          <div className="eblock-dets">
                            {listing.postType != 'attraction' && (
                              <ul>
                                <li className="date">
                                  {this.state.postStartDate
                                    ? formatDate(
                                        this.state.postStartDate,
                                      )
                                    : formatDate(
                                        listing.postStartDate,
                                      )}
                                </li>
                                <li className="time">
                                  {this.state.postStartTime
                                    ? this.state.postStartTime
                                    : listing.postStartTime}{' '}
                                  -
                                  {this.state.postEndTime
                                    ? this.state.postEndTime
                                    : listing.postEndTime}
                                </li>
                              </ul>
                            )}
                            <h3>
                              {this.state.postTitle
                                ? this.state.postTitle
                                : listing.postTitle}
                            </h3>
                            <p>
                              {this.state.addressLine != ''
                                ? this.state.addressLine
                                : listing.postAddressLine}
                            </p>
                            {/* {lat} - {lng} */}
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </AuthUserContext.Consumer>
        )}
      </>
    );
  };
}

export default withFirebase(EditListing);
