import React from 'react';
import { Link } from 'react-router-dom';

import './about.scss';

const About = () => (
  <>
    {/*About Wrap ------------------------------------------------------------*/}
    <div className="fw sect-pad-top sect-pad-bot white-bg about-block">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-6 first-para">
            <h2 className="pink">What is togodo?</h2>
            <p>Whether you’re searching for something to do at the weekend or looking to promote your event/attraction, Togodo is the place for you!</p>
            <p>But what makes us different? We promote events and attractions of all sizes, not just the big guys. So if you’re a small event organiser with a budget to match or a budding attraction looking to grow, add your listing now for free.</p>
            <Link to="/add-listing" className="btn">
              Add a listing now
            </Link>
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="about-img1" />
            <div className="about-img2" />
            <div className="pattern1" />
          </div>
        </div>
      </div>
    </div>
  </>
);

export default About;
